import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

function Tile(props) {
    const history = useHistory();

    return (
        <div
            className='tile'
            type='button'
            onClick={() => history.push(props.path)}
        >
            <Container>
                <Row className='icon'>
                    {props.icon}
                </Row>
                <Row className='title'>
                    {props.title}
                </Row>
                <Row className='summary'>
                    {props.summary}
                </Row>
            </Container>
        </div>
    )
}

export default Tile;