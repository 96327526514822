import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Tile from './Components/Common/Tile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBaseballBall, faMedal, faRunning, faUsers, faBalanceScaleRight } from '@fortawesome/free-solid-svg-icons';

function Home(props) {
    let tiles = [
        {
            title: 'Player Page',
            summary: 'See transaction and roster information for a player as well as some statistics.',
            path: '/player',
            icon: <FontAwesomeIcon icon={faRunning} size='4x' />,
        },
        {
            title: 'Leaderboards',
            summary: 'Find the top players in the league.',
            path: '/leaderboards',
            icon: <FontAwesomeIcon icon={faUsers} size='4x' />,
        },
        {
            title: 'Comparison Report',
            summary: 'Add up to 10 players to compare to each other.',
            path: '/compreport',
            icon: <FontAwesomeIcon icon={faBalanceScaleRight} size='4x' />,
        },
        {
            title: 'Query Tool',
            summary: 'Use advanced filter\'s to create a custom list of players that meet your specific criteria.',
            path: '/query',
            icon: <FontAwesomeIcon icon={faBaseballBall} size='4x' />,
        },
        {
            title: 'Rank Report',
            summary: 'See how well a player compares to the rest of the league.',
            path: '/rankreport',
            icon: <FontAwesomeIcon icon={faMedal} size='4x' />,
        },
    ]

    return (
        <div className='body home'>
            <Container>
                <Row>
                    {
                        tiles.map(tile => {
                            return (
                                <Col xs={12} sm={6} md={4}>
                                    <Tile
                                        title={tile.title}
                                        summary={tile.summary}
                                        path={tile.path}
                                        icon={tile.icon}
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}

export default Home;