import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { TeamSelector } from '../Common/TeamSelector';
import { PositionSelector } from '../Common/PositionSelector';


function LargestContractForm(props) {
    let curSeason = new Date().getMonth() < 3 ? new Date().getFullYear() - 1 : new Date().getFullYear();
    const [season, setSeason] = useState(curSeason.toString());
    const [teams, setTeams] = useState(['0']);
    const [signingTeams, setSigningTeams] = useState(['0']);
    const [pos, setPos] = useState(['0']);
    const [ageMin, setAgeMin] = useState('15');
    const [ageMax, setAgeMax] = useState('50');

    useEffect(() => {
        // Initialize options on rank report page
        let formOptions = {
            season: season,
            teams: teams,
            signingTeams: signingTeams,
            pos: pos,
            ageMin: ageMin,
            ageMax: ageMax,
        }
        props.setFormOptions(formOptions)
    }, [])

    useEffect(() => {
        let formOptions = {
            season: season,
            teams: teams,
            signingTeams: signingTeams,
            pos: pos,
            ageMin: ageMin,
            ageMax: ageMax,
        }
        props.setFormOptions(formOptions)
    }, [
        season,
        teams,
        signingTeams,
        pos,
        ageMin,
        ageMax,
    ])

    let seasonOptions = []
    for (let i = curSeason; i >= 2002; i--) {
        seasonOptions.push(
            <option key={i} value={i}>{i}</option>
        )
    }

    return (
        <React.Fragment>
            <div className='row'>
                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Season: </Form.Label>
                <Form.Control style={{ width: '100px' }} as='select'
                    defaultValue={season} onChange={(event) => setSeason(event.target.value)}>{seasonOptions}
                </Form.Control>
            </div>
            <br />
            <div className='row'>
                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Team: </Form.Label>
                <TeamSelector setTeams={setTeams} />
            </div>
            <br />
            <div className='row'>
                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Signing Team: </Form.Label>
                <TeamSelector setTeams={setSigningTeams} />
            </div>
            <br />
            <div className='row'>
                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Player Age From: </Form.Label>
                <Form.Control style={{ width: '100px' }} as='input' type='number' defaultValue={'15'} onChange={(event) => setAgeMin(event.target.value)} />
                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Player Age To: </Form.Label>
                <Form.Control style={{ width: '100px' }} as='input' type='number' defaultValue={'50'} onChange={(event) => setAgeMax(event.target.value)} />
            </div>
            <br />
            <div className='row'>
                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Primary Position: </Form.Label>
                <PositionSelector setPositions={setPos} />
            </div>
            <br />
        </React.Fragment>
    )
}

export default LargestContractForm;