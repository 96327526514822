import React, { useState } from 'react';
import { render } from 'react-dom';
import { columnManager } from '../Common/ColumnManager';
import DraggableFilter from './DraggableFilter';
import DropBlock from './DropBlock';
import LogOp from './LogOp';
import { getIndicesOfString, getValueLabelFromFormInputs } from '../Common/CommonFunctions';

const advancedFiltersFunctions = (function () {
    //#region html to text
    /**
     * Appends to the accumulatedFilterText the filters from the filterBlock.
     * Returns the accumulatedFilterText.
     * @param {any} accumulatedFilterText
     * @param {any} filterBlock
     */
    function createFilterText(accumulatedFilterText, filterBlock) {
        let curFilterBlockText = filterBlock.filterText.slice(0);
        if (['|', '&'].includes(curFilterBlockText[0])) {
            accumulatedFilterText += curFilterBlockText[0];
            curFilterBlockText = curFilterBlockText.slice(1);
            return accumulatedFilterText;
        }

        accumulatedFilterText += `(${curFilterBlockText.join('')}`;
        filterBlock.nestedBlocks.forEach(nestedBlock => {
            accumulatedFilterText = createFilterText(accumulatedFilterText, nestedBlock);
        });
        accumulatedFilterText += ')';
        return accumulatedFilterText;
    }

    /**
     * Creates an array of block objects that contain nested blocks and filter text arrays.
     * @param {any} blocks
     * @param {any} span
     * @param {any} filterType
     */
    async function createOrNestFilter(blocks, span, filterType, toStatProp) {
        let spanId = span.id
            .replace(/-logOp$/, '')
            .replace(/-filter-[0-9]*/, '')
            .replace(/criteria-[0-9]*-/, '')
            .replace(/dropZone-[0-9]*-/, '')
            .replace(/-logOp-[0-9]*/, '');
        let spanText = span.innerText
            .replaceAll('\nX', '')
        if (spanText === 'AND') spanText = '&';
        if (spanText === 'OR') spanText = '|';
        let spanStatId = span.attributes.statId?.value;
        let spanFilterAttribute = span.attributes.filter?.value;

        let retFilterIndex = blocks.findIndex(curFilter => curFilter.label === spanId);
        if (!!~retFilterIndex) {
            blocks[retFilterIndex].filterText.push(
                ['&', '|'].includes(spanText)
                    ? spanText
                    : await filterTextConversion(spanFilterAttribute, toStatProp, spanStatId)
            );
            return;
        }

        retFilterIndex = blocks.findIndex(curFilter => !['IGW', 'ISW'].includes(curFilter.label) && !spanId.indexOf(curFilter.label));
        if (!!~retFilterIndex) {
            await createOrNestFilter(blocks[retFilterIndex].nestedBlocks, span, filterType, toStatProp);
            return;
        }

        blocks.push({
            filterType: filterType,
            label: spanId,
            filterText: [
                ['&', '|'].includes(spanText)
                    ? spanText
                    : await filterTextConversion(spanFilterAttribute, toStatProp, spanStatId)
            ],
            nestedBlocks: [],
        });
        return;
    }

    /**
     * Converts the stat of the filter based on toStatProp.
     * Returns the new string of the filter.
     * @param {any} filterText
     * @param {any} toStatProp
     */
    async function filterTextConversion(filterText, toStatProp, statId) {
        if (['|', '&'].includes(filterText)) {
            return filterText;
        }
        let filterRegex = /(?<stat>[0-9]+|[A-z]+)(?<operator><>|>=|>|=|<=|<)(?<value>[^>= \n]+)/;
        let regex = filterRegex.exec(filterText);
        let stat = regex.groups.stat;
        let operator = regex.groups.operator;
        let value = regex.groups.value;

        let statColumn = await columnManager.getSingleColumnById(Number(statId));
        if (statColumn === null) {
            return filterText;
        }

        let statProp;
        let valueProp;
        switch (toStatProp) {
            case 'Id':
                statProp = statColumn.id;
                valueProp = value
                break;
            case 'Field':
                statProp = statColumn.field;
                valueProp = getValueLabelFromFormInputs(
                    statColumn.formInputs,
                    value
                );
                break;
            case 'Title':
                statProp = statColumn.title;
                valueProp = getValueLabelFromFormInputs(
                    statColumn.formInputs,
                    value
                );
                break;
            default:
                statProp = stat;
                valueProp = value
                break;
        }

        return (statProp ?? stat)
            + operator
            + (valueProp ?? value);
    }

    /**
     * Parses the filterHtml and returns a string representation of the filters based on the statProp.
     * @param {any} filterHtml
     * @param {any} statProp
     */
    async function parseFiltersFromHtml(filterHtml, statProp) {
        if (!filterHtml) return '';
        let filterBlocks = [];
        let spans = filterHtml.getElementsByTagName('span');
        let filterType = spans.length === 0
            ? ''
            : spans[0].classList.contains('ISW')
                ? 'ISW'
                : 'IGW';

        for (const span of spans) {
            await createOrNestFilter(
                filterBlocks,
                span,
                span.classList.contains('ISW')
                    ? 'ISW'
                    : 'IGW',
                statProp
            )
        }

        let filterText = '';
        filterBlocks.forEach(filterBlock => filterText = createFilterText(filterText, filterBlock));

        return filterText ? filterType + ' ' + filterText : '';
    }
    //#endregion

    //#region text to html
    /**
     * Converts the string representation of advanced filters into the html to be used when appending filters to dropzones.
     * @param {any} filterString
     * @param {any} filterType
     * @param {any} index
     */
    async function convertFilterStringToHtml(filterString, filterType, index) {
        let dropZones = [];

        if (!filterString) return dropZones;
        let matchingParens = pairParentheses(filterString);

        let filters = []
        matchingParens.forEach(matchingParen => {
            filters.push(splitFilters(matchingParen, filterString));
        })

        let blockIndex = 0;
        for (const filter of filters) {
            let filterHtml = await generateFilterHtml(filter, filterType, index, [blockIndex]);
            dropZones.push(filterHtml);
            blockIndex++;
        }

        return dropZones;
    }

    /**
     * Generates the html to be used when appending filters into each dropzone.
     * @param {any} filters
     * @param {any} filterType Either IGW or ISW. Used for styling and to provide uniqueness.
     * @param {any} statIndex Clarifies what stat in the list of stats the filters are associated with.
     * @param {any} blockDepthIndex
     */
    async function generateFilterHtml(filters, filterType, statIndex, blockDepthIndex) {
        let returnHtml = [];

        let filterEles = [];
        let blockId = ''
        blockDepthIndex.forEach(blockIndex => {
            blockId += `-block-${blockIndex}`;
        })
        let filterId = 0;
        let lastBlockId = Number(blockId.substring(blockId.lastIndexOf('-') + 1));
        blockId = blockId.substring(0, blockId.lastIndexOf('-') + 1);

        let filterIndex = 0;
        for (const filter of filters.filters) {
            let textIndex = 0;
            for (const text of filter.split(';')) {
                if (filterIndex === 0 && textIndex === 0 && ['AND', 'OR'].includes(text)) {
                    let logOpId = blockId.replace(/((filter)|(block))-$/, `logOp-${lastBlockId}`);
                    lastBlockId++;
                    returnHtml.push(
                        <LogOp
                            operator={text}
                            id={`${filterType}-dropZone-${statIndex}${logOpId}`}
                        />
                    )
                } else if (['AND', 'OR'].includes(text)) {
                    filterEles.push(
                        <LogOp
                            operator={text}
                            id={`${filterType}-criteria-${statIndex}${blockId}${lastBlockId}-logOp-${++filterId}`}
                        />
                    )
                } else if (['ISW', 'IGW'].includes(filterType)) {
                    let filterRegex = /(?<statId>[^>=< \n]+)(?<operator><>|>=|>|=|<=|<)(?<value>[^>=< \n]+)/;
                    let regex = filterRegex.exec(text);
                    let statId = regex.groups.statId;
                    let operator = regex.groups.operator;
                    let value = regex.groups.value;
                    let column = await columnManager.getSingleColumnById(Number(statId));
                    let valueLabel = getValueLabelFromFormInputs(
                        column.formInputs,
                        value
                    );
                    filterEles.push(
                        <DraggableFilter
                            className={filterType}
                            statId={statId}
                            filterText={column.title + operator + valueLabel}
                            filter={statId + operator + value}
                            id={`${filterType}-criteria-${statIndex}${blockId}${lastBlockId}-filter-${filterId}`}
                            granularity={column.formInputs.granularity}
                        />
                    )
                } else {
                    filterEles.push(
                        <DraggableFilter
                            className={filterType}
                            filterText={text}
                            id={`${filterType}-criteria-${statIndex}${blockId}${lastBlockId}-filter-${filterId}`}
                        />
                    )
                }
                textIndex++;
            }
            filterIndex++;
        }

        if (filters.nestedFilters.length > 0) {
            blockDepthIndex.push(0);
            for (let nestedFilter of filters.nestedFilters) {
                let filterEle = await generateFilterHtml(nestedFilter, filterType, statIndex, blockDepthIndex);
                filterEles.push(filterEle);
            }
        }

        returnHtml.push(
            <DropBlock
                id={`${filterType}-dropZone-${statIndex}${blockId}${lastBlockId}`}
                className={filterType}
            >
                {filterEles}
            </DropBlock>
        );

        return returnHtml;
    }

    /**
     * Matches the index of an opening parenthesis with it's closing parenthesis counterpart. Returns an object
     * with the openIndex property, closeIndex property and nestedParens array which holds and identical object
     * if there were nested parenthesis between the open and close index.
     * @param {any} filterString
     */
    function pairParentheses(filterString) {
        let openParenIndices = getIndicesOfString('(', filterString);
        let closeParenIndices = getIndicesOfString(')', filterString);

        let matchingParens = [];
        let matchedOpenParenIndices = [];
        closeParenIndices.forEach((closeParenIndex, loopIndex) => {
            let matchingParen = Math.max(...(
                openParenIndices.filter(openParenIndex => {
                    return openParenIndex < closeParenIndex
                        && !matchedOpenParenIndices.includes(openParenIndex)
                })
            ));

            matchedOpenParenIndices.push(matchingParen);
            matchingParens.push({
                openIndex: matchingParen,
                closeIndex: closeParenIndex,
                nestedParens: [],
            });
        });

        matchingParens.sort((a, b) => a.openIndex - b.openIndex)

        for (let i = matchingParens.length - 1; i > 0; i--) {
            for (let j = i - 1; j > -1; j--) {
                if (matchingParens[i].openIndex < matchingParens[j].closeIndex) {
                    matchingParens[j].nestedParens.push(matchingParens[i]);
                    matchingParens.splice(i, 1);
                }
            }
        }

        return matchingParens;
    }

    /**
     * Based on the open/close parenthesis index, pulls out the useful information of the filter in the filterString.
     * The returned object will have filters and nested filters to be used when generating the html for individual filters.
     * @param {any} parenIndices
     * @param {any} filterString
     */
    function splitFilters(parenIndices, filterString) {
        let curFilters = {
            filters: [],
            nestedFilters: [],
        };

        if (parenIndices.nestedParens.length > 0) {
            parenIndices.nestedParens.forEach(nestedParens => {
                curFilters.nestedFilters.push(splitFilters(nestedParens, filterString));
            })
        }

        let filter = filterString.slice(parenIndices.openIndex + 1, parenIndices.closeIndex)
        if (['&', '|'].includes(filterString.charAt(parenIndices.openIndex - 1))) {
            filter = filterString.charAt(parenIndices.openIndex - 1) + filter;
        }
        let nestedFilterRegex = /(([&\|]\(|(\()).+\))/;

        while (nestedFilterRegex.test(filter)) {
            filter = filter.replace(nestedFilterRegex, '');
        }

        filter = filter
            .replaceAll('&', ';AND;')
            .replaceAll('|', ';OR;')
            .trim();

        filter = filter.charAt(0) === ';' ? filter.substring(1) : filter;

        curFilters.filters.push(filter);

        return curFilters;
    }
    //#endregion

    return {
        convertFilterStringToHtml,
        parseFiltersFromHtml,
    }
}());

const dragAndDropFunctions = (function () {
    function dropElement(ev, target, prevId) {
        ev.preventDefault();
        target = target || ev.target;
        prevId = prevId || ev.dataTransfer.getData('text/id');

        // Unhighlight drop zones
        let dropZones = document.getElementsByClassName('dropZone');
        Array.prototype.forEach.call(dropZones, (d) => {
            d.classList.remove('available');
        })

        // Check if drop is allowed
        let nestedFilters = target.getElementsByTagName('span');
        let filterTypeFromCookie = localStorage.getItem('filterType');
        //localStorage.removeItem('filterType');

        let hasSameFilterType = !!~Array.prototype.findIndex.call(
            nestedFilters,
            (nestedFilter => nestedFilter.classList.contains(filterTypeFromCookie))
        )
        if ((!target.classList.contains('dropZone-all') && !target.classList.contains(filterTypeFromCookie))
            || (target.classList.contains('dropZone-all') && (nestedFilters.length > 0 && !hasSameFilterType))) {
            return;
        }

        // Create element and append it to target
        let dropZoneClass = Array.prototype.find.call(target.classList, (cl) => cl.includes('dropZone-'));
        let filterType = document.getElementById(prevId).classList.contains('ISW') ? 'ISW' : 'IGW';
        let ele = document.createDocumentFragment();

        let id = '';
        let lastEle = target.lastChild;
        let lastId = lastEle && (lastEle.className.includes('dropZone') || lastEle.className.includes('draggable'))
            ? 1 + +lastEle.id.slice(lastEle.id.lastIndexOf('-') + 1)
            : 0;
        if (prevId.includes('filter') && !prevId.includes('logOp')) { // If it's a filter
            if (target.className.includes('block')) {
                id = `${dropZoneClass.replace('dropZone', 'criteria')}-filter-${lastId}`;
                let filterText = document.getElementById(prevId).firstChild.data;
                let filterStatId = document.getElementById(prevId).attributes.statid;
                let filterRaw = document.getElementById(prevId).attributes.filter.value;
                let filterGranularity = document.getElementById(prevId).attributes.granularity;
                render(
                    <DraggableFilter
                        className={filterType}
                        statId={filterStatId?.value}
                        filterText={filterText}
                        filter={filterRaw}
                        id={id}
                        granularity={filterGranularity?.value}
                    />,
                    ele
                );
            }
            else {
                id = `${filterType}-dropZone-${dropZoneClass.split('-')[1]}-block-${lastId}`;
                render(<DropBlock id={id} className={filterType} />, ele);
                dropElement(ev, ele.firstChild);
            }
        }
        else if (prevId.includes('logOp')) {
            id = `${target.id.replace(/((DisStat)|(NumCrit))-/, '')}-logOp-${lastId}`;
            let operator = document.getElementById(prevId).innerText;
            render(<LogOp operator={operator} id={id} className={'logOp'} />, ele);
            Array.prototype.forEach.call(document.getElementById(prevId).children, htmlEle => {
                if (htmlEle.className.includes('dropZone') || htmlEle.className.includes('draggable')) {
                    dropElement(ev, ele.firstChild, htmlEle.id);
                }
            });
        }
        else { // If it's a drop block
            if (target.className.includes('block')) {
                id = `${target.id}-block-${lastId}`;
            }
            else {
                id = `${filterType}-dropZone-${dropZoneClass.split('-')[1]}-block-${lastId}`
            }
            render(<DropBlock id={id} className={filterType} />, ele);
            // Add all nested filters and dropzones to the dropBlock
            Array.prototype.forEach.call(document.getElementById(prevId).children, htmlEle => {
                if (htmlEle.className.includes('dropZone') || htmlEle.className.includes('draggable') || htmlEle.className.includes('logOp')) {
                    dropElement(ev, ele.firstChild, htmlEle.id);
                }
            });
        }
        let filters = Array.prototype.filter.call(target.children, htmlEle => {
            return htmlEle.tagName.toLowerCase() === 'span' || htmlEle.className.includes('dropZone');
        })
        let filterIndex = filters.length - 1;
        let requiresLogOp = filters.length > 0 && !prevId.includes("logOp") && !filters[filterIndex].id.includes("logOp");
        if (requiresLogOp) {
            let logOpEle = document.createDocumentFragment();
            // TODO: Update id string to replace last case of filter (or maybe block?) with logop
            // This might be happening other places
            let logOpId = id.replace(/((filter)|(block))-[0-9]+$/, `logOp-${lastId}`);
            render(<LogOp operator={'AND'} id={logOpId} className={'logOp'} />, logOpEle);
            target.appendChild(logOpEle.firstChild);
        }
        target.appendChild(ele);

        document.body.style.cursor = 'default';
        ev.stopPropagation();
        return;
    }

    function endDrag(ev) {
        document.body.style.cursor = 'default';
        let dropZones = document.getElementsByClassName('dropZone');
        Array.prototype.forEach.call(dropZones, (dropZone) => {
            dropZone.classList.remove('available');
        });
        return false;
    }

    function startDrag(ev, className) {
        function granularityComparisonAllow(filterType, filterGranularities, dropZoneGranularity) {
            if (filterType == 'ISW') {
                return filterGranularities
                    .map(granularity => parseInt(granularity))
                    .every(granularity => granularity >= parseInt(dropZoneGranularity));
            } else {
                return filterGranularities
                    .map(granularity => parseInt(granularity))
                    .every(granularity => granularity <= 2);
            }
        }

        function hasMatchingUniqueFilter(uniqueFiltersAllowed, filterId) {
            return uniqueFiltersAllowed.split(',').includes(filterId);
        }

        localStorage.setItem('filterType', className);
        let targetId = ev.target.id;
        let filterGranularities = [];
        let filterId;
        if (targetId.includes('filter')) {
            let filterEle = document.getElementById(ev.target.id);
            filterGranularities.push(filterEle.getAttribute('granularity'));
            filterId = filterEle.getAttribute('statid');
        } else {
            document.getElementById(ev.target.id)?.querySelectorAll(`span[draggable='true']`).forEach(filter => {
                filterGranularities.push(filter.getAttribute('granularity'));
            })
        }

        document.body.style.cursor = 'grabbing';
        let notDropZones = document.getElementsByTagName('input');
        Array.prototype.forEach.call(notDropZones, (nd) => {
            nd.ondragover = (ev) => {
                ev.preventDefault();
                ev.dataTransfer.dropEffect = 'none';
            }
        })

        let dropZones = document.getElementsByClassName('dropZone');
        Array.prototype.forEach.call(dropZones, (dropZone) => {
            let nestedFilters = dropZone.getElementsByTagName('span');
            let hasSameFilterType = !!~Array.prototype.findIndex.call(
                nestedFilters,
                (nestedFilter => nestedFilter.classList.contains(className))
            )
            let dropZoneGranularity = dropZone.getAttribute('granularity') ?? -1;
            let dropZoneAllowableFilters = dropZone.getAttribute('allowableFilters') ?? '';
            //let isWhereBlock = Array.prototype.some.call(
            //    dropZone.classList,
            //    (className => {
            //        let whereBlockRegex = /dropZone-([0-9]+|all)$/;
            //        return whereBlockRegex.test(className);
            //    })
            //);
            if (
                (
                    hasMatchingUniqueFilter(dropZoneAllowableFilters, filterId)
                    || granularityComparisonAllow(className, filterGranularities, dropZoneGranularity)
                )
                && (
                    dropZone.classList.contains(className)
                    || (
                        dropZone.classList.contains('dropZone-all')
                        && (nestedFilters.length === 0 || hasSameFilterType)
                    )
                )
            ) {
                dropZone.classList.add('available');
                dropZone.ondragover = (ev) => {
                    ev.preventDefault();
                    ev.dataTransfer.dropEffect = 'copy';
                }
            } else {
                dropZone.ondragover = (ev) => {
                    ev.preventDefault();
                    ev.dataTransfer.dropEffect = 'none';
                    ev.dataTransfer.effectAllowed = 'none';
                }
            }
        })
        ev.dataTransfer.setData('text/id', ev.target.id);
        ev.dataTransfer.effectAllowed = 'move';
    }

    return {
        dropElement,
        endDrag,
        startDrag,
    }
}());

export { advancedFiltersFunctions, dragAndDropFunctions };