import React, { useState, useEffect } from 'react';
import { Button, Navbar, Nav } from 'react-bootstrap';
import PlayerSearch from '../Common/PlayerSearch';
import { GetPlayers, GetRankReportsPitchers, GetRankReportsBatters, GetRankReportsFielders, GetQualifierThresholds, GetRankReport } from '../../Services/ApiSvc';
import { columnManager } from '../Common/ColumnManager';
import ColumnSelector from '../Common/ColumnSelector';
import TabulatorTable from '../Common/TabulatorTable';
import PDFExporter from '../Common/PDFExporter';
import ExcelExporter from '../Common/ExcelExporter';
import RankReportForm from './RankReportForm';

function RankReport() {
    const [allPlayers, setAllPlayers] = useState([]);
    const [columns, setColumns] = useState([]);
    const [defRankColumns, setDefRankColumns] = useState([]);
    const [groups, setGroups] = useState([]);
    const [category, setCategory] = useState('batting');
    const [selectedPlayers, setSelectedPlayers] = useState([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    const [playerCount, setPlayerCount] = useState(0);
    const [groupId, setGroupId] = useState(29);
    const [selectedCols, setSelectedCols] = useState([]);
    const [formOptions, setFormOptions] = useState({});
    const [qualifierThresholds, setQualifierThresholds] = useState([]);
    const rankFormatter = function (cell, formatterParams, onRendered) {
        let val = cell.getValue();
        if (val === null) {
            return '--';
        }
        if (Number.isInteger(val) && cell.getColumn().getField() !== 'Season') {
            val = val.toString();
            if (val === '0') {
                return '0th';
            }
            else if (val.length > 1 && val.substring(val.length - 2, val.length - 1) == '1') {
                return val + 'th';
            }
            else {
                switch (val.substring(val.length - 1)) {
                    case '1':
                        return val + 'st';
                    case '2':
                        return val + 'nd';
                    case '3':
                        return val + 'rd';
                    default:
                        return val + 'th';
                }
            }
            return val;
        }
        return val;
    };

    useEffect(() => {

        (async function Initialize() {
            let allQualifierThresholds = await GetQualifierThresholds()
            setQualifierThresholds(allQualifierThresholds);
            let allPlayersFromAPI = await GetPlayers();
            let allColumns = await columnManager.getAllColumns();
            let defaultRankReportCols = await columnManager.getColumnsByGroup(28);
            let selectedColumns = await columnManager.getColumnsByGroup(groupId > 0 ? groupId : 29);
            let rankReportGroups = (await columnManager.getAllGroupsBySection('Leaderboards'))
                .concat(await columnManager.getAllGroupsBySection('RankReport'));

            setAllPlayers(allPlayersFromAPI);
            setColumns(allColumns);
            setDefRankColumns(defaultRankReportCols);
            setSelectedCols(selectedColumns);
            setGroups(rankReportGroups);
        })()
    }, [])

    useEffect(() => {
        setSelectedPlayers([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
        switch (category) {
            case 'batting':
                setGroupId(29);
                break;
            case 'pitching':
                setGroupId(30);
                break;
            case 'relief':
                setGroupId(31);
                break;
            default:
                break;
        }
    }, [category]);

    useEffect(() => {
        if (groupId > 0) {
            if (groups.length < 1) {
                return;
            }
            let tempCols = groups.find(group => group.id === groupId).columns.slice();
            updateSelectedCols(tempCols);
        }
    }, [groupId])

    useEffect(() => {
        if (selectedPlayers.findIndex(player => player.playerId > 0) > -1)
            getData();
    }, [selectedCols])

    const setPlayer = (index, playerId) => {
        let playerIds = selectedPlayers.slice(0);
        playerIds[index] = { playerId: playerId };
        setSelectedPlayers(playerIds);
    }

    const updateSelectedCols = (updatedCols) => {
        let finalColumns = updatedCols.slice();
        let finalColIds = finalColumns.map(finalCol => finalCol.id);
        finalColumns.unshift(
            ...defRankColumns.filter(defRankColumn => !finalColIds.includes(defRankColumn.id))
        );
        
        finalColumns.forEach(col => {
            col.formatter = rankFormatter;
        })
        setSelectedCols(finalColumns);
    }

    const getData = async () => {
        formOptions.category = category;
        let tempPlayers = selectedPlayers.slice(0);
        let playerLookup = tempPlayers.reduce((acc, cur) => {
            if (cur.playerId > 0) {
                acc[cur.playerId.toString()] = cur;
                acc[cur.playerId.toString()].data = [];
            }
            return acc;
        }, {});
        setSelectedPlayers([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]);

        let ranksDataTasks = [];
        if (formOptions.mode === 'separate' && formOptions.endSeason !== formOptions.startSeason) {
            ranksDataTasks = new Array(Number(formOptions.endSeason) - Number(formOptions.startSeason) + 1)
                .fill(Number(formOptions.startSeason))
                .map((season, index) => {
                    return GetRankReport(
                        Object.assign({}, formOptions, { season: season + index, mode: 'single' }),
                        selectedCols,
                    )
                });

        } else {
            ranksDataTasks.push(GetRankReport(formOptions, selectedCols))
        }

        let ranksData = await Promise.all(ranksDataTasks);

        for (const rankData of ranksData) {
            let teamLookup = columns
                .find(col => col.id === 3).formInputs.selectOptions
                .reduce(
                    (acc, cur) => {
                        acc[cur.label] = cur;
                        return acc;
                    },
                    {},
                );
            let leagueLookup = columns
                .find(col => col.id === 499).formInputs.selectOptions
                .reduce(
                    (acc, cur) => {
                        acc[cur.label] = cur;
                        return acc;
                    },
                    {},
                );

            let selectedPlayersRows = rankData.filter(row => tempPlayers.map(tempPlayer => tempPlayer.playerId).includes(row.playerId));
            let teamsRankingsTasks = {}, leaguesRankingsTasks = {};
            selectedPlayersRows.forEach(row => {
                if (row.teamId === 0 && row.leagueId === 0) {
                    row.PlayerCount = `out of ${rankData.filter(rankRow => rankRow.teamId === 0).length}`;
                    row.UniversePool = 'MLB';
                    playerLookup[row.playerId.toString()].data.push(row);
                }

                if (Object.keys(leagueLookup).includes(row.League)) {
                    if (!Object.hasOwn(leaguesRankingsTasks, row.League)) {
                        leaguesRankingsTasks[row.League] = GetRankReport(
                            Object.assign({ leagues: leagueLookup[row.League].value }, formOptions, formOptions.mode === 'separate' ? { season: row.Season, mode: 'single' } : {}),
                            selectedCols,
                        );
                    }

                    if (!Object.hasOwn(leagueLookup[row.League], 'playerIds')) {
                        leagueLookup[row.League].playerIds = new Set([ row.playerId]);
                    } else {
                        leagueLookup[row.League].playerIds.add(row.playerId);
                    }
                }

                if (Object.keys(teamLookup).includes(row.Team)) {
                    if (!Object.hasOwn(teamsRankingsTasks, row.Team)) {
                        teamsRankingsTasks[row.Team] = GetRankReport(
                            Object.assign({ teams: teamLookup[row.Team].value }, formOptions, formOptions.mode === 'separate' ? { season: row.Season, mode: 'single' } : {}),
                            selectedCols,
                        );
                    }

                    if (!Object.hasOwn(teamLookup[row.Team], 'playerIds')) {
                        teamLookup[row.Team].playerIds = new Set([row.playerId]);
                    } else {
                        teamLookup[row.Team].playerIds.add(row.playerId);
                    }
                }
            });

            await Promise.all(Object.values(leaguesRankingsTasks))
                .then(leagueRankValues => {
                    leagueRankValues.forEach(leagueRanks => {
                        const leagueName = leagueRanks.find(leagueRankRow => leagueRankRow.League !== null).League;
                        for (const playerIdEntry of leagueLookup[leagueName].playerIds.entries()) {
                            const playerId = playerIdEntry[0];
                            let playerRankIndex = leagueRanks.findIndex(rank => rank.playerId === playerId && rank.teamId === 0);
                            if (playerRankIndex > -1) {
                                let playerRankInLeague = Object.assign({}, leagueRanks[playerRankIndex]);
                                playerRankInLeague.PlayerCount = `out of ${leagueRanks.filter(leagueRankRow => leagueRankRow.teamId === 0).length}`;
                                playerRankInLeague.UniversePool = playerRankInLeague.League;
                                playerLookup[playerId.toString()].data.push(playerRankInLeague);
                            }
                        }
                    })
                });
            await Promise.all(Object.values(teamsRankingsTasks))
                .then(teamRankValues => {
                    teamRankValues.forEach(teamRanks => {
                        const teamName = teamRanks.find(leagueRankRow => leagueRankRow.Team !== null).Team;
                        for (const playerIdEntry of teamLookup[teamName].playerIds.entries()) {
                            const playerId = playerIdEntry[0];
                            let playerRankIndex = teamRanks.findIndex(rank => rank.playerId === playerId);
                            if (playerRankIndex > -1) {
                                let playerRankOnTeam = Object.assign({}, teamRanks[playerRankIndex]);
                                playerRankOnTeam.PlayerCount = `out of ${teamRanks.filter(teamRankRow => teamRankRow.teamId === 0).length}`;
                                playerRankOnTeam.UniversePool = playerRankOnTeam.Team;
                                playerLookup[playerId.toString()].data.push(playerRankOnTeam);
                            }
                        }
                    })
                });
        }

        setSelectedPlayers(tempPlayers);
    }

    return (
        <div className='body rank-report'>
            <Navbar bg='light' variant='light' className='leaderboardsNav'>
                <Nav>
                    <Nav.Link className={category === 'batting' ? 'selectedTab' : null} onClick={() => setCategory('batting')}>Batting</Nav.Link>
                    <Nav.Link className={category === 'pitching' ? 'selectedTab' : null} onClick={() => setCategory('pitching')}>Starting Pitcher</Nav.Link>
                    <Nav.Link className={category === 'relief' ? 'selectedTab' : null} onClick={() => setCategory('relief')}>Relief Pitcher</Nav.Link>
                </Nav>
            </Navbar>
            <br />
            {
                selectedPlayers.slice(0, playerCount + 1).map((player, index) => 
                    <div className='row playerSearch'>
                        <PlayerSearch
                            key={index}
                            players={allPlayers}
                            label={'Player Search'}
                            onSelect={(playerId, platformSeason, posFlag) => setPlayer(index, playerId)}
                        />
                    </div>
                )
            }
            <br />
            <Button variant='info' onClick={() => {
                if (playerCount < 9) {
                    setPlayerCount(playerCount + 1);
                }
            }}>Add Player</Button>
            <Button variant='info' onClick={() => {
                if (playerCount > 0) {
                    setPlayerCount(playerCount - 1);
                    let tempPlayers = selectedPlayers.slice(0);
                    tempPlayers[playerCount] = {};
                    setSelectedPlayers(tempPlayers);
                }
            }}>Remove Player</Button>
            <br />
            <br />

            <RankReportForm
                category={category}
                setFormOptions={setFormOptions}
                qualifierThresholds={qualifierThresholds}
            />

            <Button variant='success' onClick={() => updateSelectedCols(selectedCols)}>Submit</Button>
            <br />
            <br />

            {
                selectedPlayers[0].data &&
                <React.Fragment>
                    <ExcelExporter reportName={'RankReport'} players={selectedPlayers} columns={selectedCols} />
                    <PDFExporter columns={selectedCols} fileName={'RankReport'} />
                    <br />
                    <br />
                </React.Fragment>
            }
            <div className='pdfSection' style={{
                width: selectedPlayers[0].data
                    ? (selectedCols
                        .filter(col => ![1,3,499].includes(col.id))
                        .reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                    : '100%'
            }}>
                {
                    formOptions.mode === 'date' &&
                    <p className='dateRange'>
                        {formOptions.startDate.getMonth() + 1}/{formOptions.startDate.getDate()}/{formOptions.startDate.getFullYear()}
                        -
                        {formOptions.endDate.getMonth() + 1}/{formOptions.endDate.getDate()}/{formOptions.endDate.getFullYear()}
                    </p>
                }
                {
                    selectedPlayers.filter(player => player.data && player.data.length > 0).map(player =>
                        <div className='pdfChunk'>
                            <TabulatorTable
                                cols={selectedCols.filter(col => ![1, 3, 499].includes(col.id))}
                                data={player.data}
                                header={player.data[0].Player}
                            />
                        </div>
                    )
                }
            </div>

            <ColumnSelector
                columns={columns}
                selectedColumns={selectedCols}
                groups={groups}
                setColumns={(columns) => {
                    updateSelectedCols(columns);
                    //getData();
                }}
            />
            <br />
        </div>
    )
}

export default RankReport;