import React, { useState, useEffect } from 'react';
import { Form, Badge } from 'react-bootstrap';

export function MonthSelector(props) {
    const [months, setMonths] = useState(['0']);

    useEffect(() => {
        props.setMonth(months);
    }, [months])

    function addMonth(month) {
        if (!months.includes(month)) {
            let curMonths = months.slice();
            if (month !== '0') {
                curMonths.push(month);
                if (curMonths.length > 1 && curMonths.includes('0')) {
                    curMonths.splice(curMonths.indexOf('0'), 1);
                }
                setMonths(curMonths);
            }
            else {
                setMonths(['0']);
            }
        }
    };

    function remMonth(month) {
        let curMonths = months.slice();
        if (curMonths.includes(month)) {
            curMonths.splice(curMonths.indexOf(month), 1);
        }
        setMonths(curMonths);
    }

    let selectedMonths = [];
    for (let i = 0; i < months.length; i++) {
        if (months[i] !== '0') {
            selectedMonths.push(
                <Badge key={months[i]} variant='secondary'>{monthValues[months[i]]}<span type='button' onClick={() => remMonth(months[i])}>X</span></Badge>
            )
        }
        else {
            selectedMonths.push(
                <Badge key={months[i]} variant='secondary'>{monthValues[months[i]]}</Badge>
            )

        }
    };

    return (
        <React.Fragment>
            <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Month: </Form.Label>
            <Form.Control style={{ width: '136px' }} as='select' onChange={(event) => addMonth(event.target.value)}>
                <option key='0' value='0'>All</option>
                <option key='3' value='3'>March</option>
                <option key='4' value='4'>April</option>
                <option key='5' value='5'>May</option>
                <option key='6' value='6'>June</option>
                <option key='7' value='7'>July</option>
                <option key='8' value='8'>August</option>
                <option key='9' value='9'>September</option>
                <option key='10' value='10'>October</option>
                <option key='11' value='11'>November</option>
            </Form.Control>
            <div>
                {
                    selectedMonths.length > 0 &&
                    selectedMonths
                }
            </div>
        </React.Fragment>
    )
}

let monthValues = [
    'All',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]