import React from 'react';

function GameLogs(props) {
    

    return(
        <React.Fragment>
            Game Log
        </React.Fragment>
    );
}

export default GameLogs;