import React, { useEffect, useState } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import { GetPlayerPageAwards, GetPlayerPageAwardsLeaderboards } from '../../Services/ApiSvc';
import ExcelExporter from '../Common/ExcelExporter';
import PDFExporter from '../Common/PDFExporter';
import TabulatorTable from '../Common/TabulatorTable';
import { GetPositionByAbbreviation } from '../Common/CommonFunctions';

function PlayerAwards(props) {
    const [award, setAward] = useState(null);
    const [awardLeaderboards, setAwardLeaderboards] = useState(null);
    const [awardOptions, setAwardOptions] = useState(null);
    const [columnGroups, setColumnGroups] = useState(null);
    const [data, setData] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [loading, setLoading] = useState(true);
    const [omitColumns, setOmitColumns] = useState([78, 88, 60, 64, 69, 51]);
    const [showSeasonAwardModal, setShowSeasonAwardModal] = useState(false);

    useEffect(() => {
        if (props.groups.length < 1 || props.player === null) {
            return;
        }

        let colGroups = {
            playerAwards: [],
            awardLeaderboard: [],
        };

        (async () => {
            setLoading(true);
            setData(null);

            let res = await GetPlayerPageAwards(props.player);
            if (res.exceptionMsg) {
                setErrorMsg(res.exceptionMsg);
            } else if (res.data?.length === 0) {
                setErrorMsg('No Data');
            } else {
                setErrorMsg('');

                colGroups.playerAwards = props.groups.find(group => group.label === 'PlayerAwards').columns
                    .slice();
                colGroups.awardLeaderboard = props.groups.find(group => group.label === 'PlayerAwardsLeaderboards').columns
                    .slice();
                let dataColMap = {};
                let awardCols = colGroups.awardLeaderboard.find(col => col.title === 'Award').formInputs.selectOptions
                    .reduce((acc, cur) => {
                        let retObj = Object.assign({}, acc);

                        const positionMapping = {
                            'Gold Glove': ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'OF', 'DH', 'SP', 'RP', 'UTIL'],
                            'Silver Slugger': ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'OF', 'DH', 'SP', 'RP', 'UTIL'],
                            'ASE': ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'OF', 'DH', 'SP', 'RP', 'UTIL'],
                            'All-MLB TM': ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'OF', 'DH', 'SP', 'RP', 'UTIL'],
                            'FBA': ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'CF', 'RF', 'OF', 'DH', 'SP', 'RP', 'UTIL'],
                        };

                        let suffix = '';

                        if (cur.label in positionMapping) {
                            suffix = ' and Award Position=';
                            positionMapping[cur.label].forEach(position => {
                                retObj[`${cur.label}${suffix}${position}`] = { id: cur.value };
                            });
                        } else {
                            retObj[cur.label] = { id: cur.value };
                        }

                        return retObj;

                    }, {});

                const awardNameRegex = /Platform Year AwardPlace where \(Award=(?<awardName>.+)\)/;
                const awardPosRegex = /Position=(?<awardPos>.+)/;
                res.columns.forEach(col => {
                    dataColMap[col.displayName] = col.displayNumber;
                    let awardNameMatch = col.displayName.match(awardNameRegex);
                    if (awardNameMatch) {
                        let awardName = awardNameMatch?.groups?.awardName;
                        let awardPosMatch = awardName.match(awardPosRegex);
                        let awardPos = awardPosMatch?.groups?.awardPos;

                        awardCols[awardName].displayNumber = col.displayNumber;
                        awardCols[awardName].seasons = res.data.filter(row => row[col.displayNumber] > 0)
                            .map(row => row.PlatformSeason);
                        awardCols[awardName].position = GetPositionByAbbreviation(awardPos)?.num ?? -1;
                    }
                })

                colGroups.playerAwards.forEach(col => {
                    col.field = dataColMap[col.formInputs.defaultTimePeriod + ' ' + col.field]?.toString() ?? col.field;
                    if (col.title === 'Awards') {
                        col.title = 'Awards (Click cell to show award details)';
                        col.width = 500;
                        col.cellClick= function(e, cell) {
                            setAwardOptions({
                                season: cell.getRow().getData().PlatformSeason,
                                awards: awardsBySeason[cell.getRow().getData().PlatformSeason],
                            });
                            setShowSeasonAwardModal(true);
                        }
                        col.formatterParams = {
                            clickStyling: true,
                        }
                    }
                })
                setColumnGroups(colGroups);

                let awardsBySeason = {};
                let leaderboards = {};
                Object.keys(awardCols).forEach(awardName => {
                    awardCols[awardName].seasons.forEach(season => {
                        if (!awardsBySeason[season])
                            awardsBySeason[season] = [];

                        awardsBySeason[season].push({
                            label: awardName,
                            value: awardCols[awardName].id,
                            awardPos: awardCols[awardName].position,
                        });
                        let leaderboardsKey = awardCols[awardName].position !== -1
                            ? season + '-' + awardCols[awardName].id + '-' + awardCols[awardName].position
                            : season + '-' + awardCols[awardName].id;
                        leaderboards[leaderboardsKey] = null;
                    });
                });

                setData(res.data);
                setLoading(false);

                leaderboards = await getLeaderboards(awardsBySeason, colGroups, leaderboards);
                setAwardLeaderboards(leaderboards);
            }
        })();
    }, [props.player])

    const statIdsWithCustomTimePeriod = [
        5
    ]

    function convertColumnToColumnHeader(column) {
        let header = column.field;
        switch (column.statPosType) {
            case 'b':
                header += ' as Batter';
                break;
            case 'p':
                header += ' as Pitcher';
                break;
            case 'f':
                header += ' as Fielder';
                break;
            default:
                break;
        }
        return header;
    }

    async function getLeaderboards(awardsBySeason, colGroups, leaderboards) {
        let leaderboardsRes = await GetPlayerPageAwardsLeaderboards(awardsBySeason, colGroups.awardLeaderboard);
        let leaderboardsData = [];

        leaderboardsRes.forEach(leaderboard => {
            const awardNameRegex = /where \(Award=(?<awardName>.+)[\)( and)]/;
            const awardPosRegex = /Position=(?<awardPos>.+)/;
            const awardNamePosRegex = /(?<awardName>.+) and Award Position=(?<awardPos>.+)/;

            let season = leaderboard.data[0].PlatformSeason;
            let columnMap = leaderboard.columns.reduce((acc, cur) => {
                let retObj = Object.assign({}, acc);
                retObj[cur.displayNumber] = cur.displayName;
                return retObj;
            }, {});
            let playerData = {};
            let data = leaderboard.data.map(row => {
                let retObj = {};
                Object.keys(columnMap).forEach(displayNumber => {
                    retObj[columnMap[displayNumber]] = row[displayNumber];
                })
                retObj['Platform Year Award'] = null;
                if (row.PlayerId === props.player.id)
                    playerData = Object.assign({}, retObj);
                return retObj;
            });

            let columnsByAwardMap = {};
            let nonAwardStats = [];
            leaderboard.columns.forEach(leaderboardColumn => {
                if (!leaderboardColumn.displayName.includes('(Award=')) {
                    nonAwardStats.push({
                        displayNumber: leaderboardColumn.displayNumber,
                        displayName: leaderboardColumn.displayName,
                    });
                }

                if (leaderboardColumn.displayName.includes('(Award=')) {
                    let awardNameMatch = leaderboardColumn.displayName.match(awardNameRegex);
                    let awardName = awardNameMatch?.groups?.awardName;
                    let awardPosMatch = leaderboardColumn.displayName.match(awardPosRegex);
                    let awardPos = awardPosMatch?.groups?.awardPos || 'All';

                    if (!columnsByAwardMap[`${awardName}-${awardPos}`])
                        columnsByAwardMap[`${awardName}-${awardPos}`] = [];

                    columnsByAwardMap[`${awardName}-${awardPos}`].push({
                        displayNumber: leaderboardColumn.displayNumber,
                        displayName: leaderboardColumn.displayName.split(' where')[0],
                    })
                }
            });

            Object.keys(columnsByAwardMap).forEach(key => {
                columnsByAwardMap[key] = columnsByAwardMap[key].concat(nonAwardStats)
            })

            let displayNumberToNameMap = {};
            leaderboard.columns.forEach(column => {
                displayNumberToNameMap[column.displayNumber] = column.displayName;
            });

            let columnDisplayNameMap = {};
            colGroups.awardLeaderboard
                .filter(column => column.id < 10000)
                .map(column => {
                    let columnDisplayName = statIdsWithCustomTimePeriod.includes(column.id)
                        ? `${column.formInputs.defaultTimePeriod} ${convertColumnToColumnHeader(column)}`
                        : `Platform Year ${convertColumnToColumnHeader(column)}`;
                    columnDisplayNameMap[columnDisplayName] = column;
                });

            Object.keys(columnsByAwardMap).forEach(key => {
                leaderboardsData = leaderboardsData.concat(leaderboard.data.map(row => mapColumnNameToNumber(row, columnsByAwardMap[key], columnDisplayNameMap)));
            })

            awardsBySeason[season].forEach(seasonAward => {
                let seasonAwardLeaderboard = {};
                let tableHeaderColumns = [2, 591, 769, 770, 771];
                let seasonAwardLeagueCol = `Platform Year Award League where (Award=${seasonAward.label})`;
                let awardNamePosMatch = seasonAward.label.match(awardNamePosRegex);
                let awardName = awardNamePosMatch?.groups?.awardName;
                let awardPos = awardNamePosMatch?.groups?.awardPos;
                let tempName;

                if (awardName) {
                    switch (awardName) {
                        case 'Gold Glove':
                            tempName = 'GG';
                            break;
                        case 'Silver Slugger':
                            tempName = 'SS';
                            break;
                        default:
                            tempName = awardName;
                            break;
                    }
                } else {
                    switch (seasonAward.label) {
                        case 'Cy Young':
                            tempName = 'CY';
                            break;
                        case 'LCS MVP':
                            tempName = 'LCSMVP';
                            break;
                        case 'WS MVP':
                            tempName = 'WSMVP';
                            break;
                        case 'AS MVP':
                            tempName = 'ASMVP';
                            break;
                        case 'Hoffman/Rivera':
                            if (playerData[seasonAwardLeagueCol] === 'AL') {
                                tempName = 'Rivera';
                            }
                            else {
                                tempName = 'Hoffman';
                            }
                            break;
                        default:
                            tempName = seasonAward.label;
                            break;
                    }
                }

                if (awardNamePosMatch) {
                    if (awardName === 'Gold Glove' || awardName === 'FBA') {
                        seasonAwardLeaderboard.data = leaderboardsData.flat()
                            .filter(row => row.Award === tempName && row['Award Position'] === awardPos && row.Season === season.toString() && row['Award League'] === playerData[seasonAwardLeagueCol]);
                        seasonAwardLeaderboard.columns = colGroups.awardLeaderboard.filter(col => !tableHeaderColumns.includes(col.id));
                        seasonAwardLeaderboard.header = `${season} ${playerData[seasonAwardLeagueCol]} ${awardName} (${awardPos})`;
                    } else {
                        seasonAwardLeaderboard.data = leaderboardsData.flat()
                            .filter(row => row.Award === tempName && row.Season === season.toString() && row['Award League'] === playerData[seasonAwardLeagueCol]);
                        seasonAwardLeaderboard.columns = colGroups.awardLeaderboard.filter(col => {
                            return !tableHeaderColumns.includes(col.id)
                                || (col.id === 770 && ['All-MLB TM', 'ASE'].includes(awardName))
                        })
                        seasonAwardLeaderboard.header = `${season} ${playerData[seasonAwardLeagueCol]} ${awardName}`;
                    }
                } else {
                    seasonAwardLeaderboard.data = leaderboardsData.flat()
                        .filter(row => row.Award === tempName && row.Season === season.toString() && row['Award League'] === playerData[seasonAwardLeagueCol]);
                    seasonAwardLeaderboard.columns = colGroups.awardLeaderboard.filter(col => !tableHeaderColumns.includes(col.id));
                    seasonAwardLeaderboard.header = `${season} ${playerData[seasonAwardLeagueCol]} ${seasonAward.label}`;
                }

                if (seasonAward.awardPos !== -1) {
                    leaderboards[`${season}-${seasonAward.value}-${seasonAward.awardPos}`] = seasonAwardLeaderboard;
                }
                else {
                    leaderboards[`${season}-${seasonAward.value}`] = seasonAwardLeaderboard;
                }
            })

            function mapColumnNameToNumber(row, columns, columnDisplayNameMap) {
                let retRow = {};
                columns.forEach(column => {
                    let columnName = columnDisplayNameMap[column.displayName].field;
                    retRow[columnName] = row[column.displayNumber];
                });
                if (row._children)
                    retRow._children = row._children
                        .map(childRow => mapColumnNameToNumber(childRow, columns, columnDisplayNameMap));
                if (row.PlayerId === -1)
                    retRow.Player = 'Average';
                return retRow;
            }
        })
        return leaderboards;
    }

    function getSorterFunc(leaderboardTable) {
        return function (a, b) {
            return a.AwardPlace - b.AwardPlace;
        };
    }

    function getTabulatorInitSort(leaderboardTable) {
        let award = leaderboardTable.data[0]['Platform Year Award'];
        let sortArr = [
            {
                column: `Platform Year AwardPlace where (Award=${award})`,
                dir: 'asc',
            },
        ]
        if (['FBA', 'GG'].includes(award))
            sortArr.push({
                column: 'Platform Year Primary Position',
                dir: 'asc',
            });
        return sortArr;
    }

    function updateAwardSelection(season, awardId, awardPos) {
        if (awardPos !== -1){
            setAward(`${season}-${awardId}-${awardPos}`);
        }
        else {
            setAward(`${season}-${awardId}`);
        }
        setShowSeasonAwardModal(false);

        let columnsToOmit = [78, 88, 60, 64, 69, 51];
        switch (awardId) {
            case '3':
            case '30':
                columnsToOmit = [88, 60, 64, 69, 51, 619, 810, 811, 812, 813, 814, 815, 816, 817, 818];
                break;
            case '4':
                columnsToOmit = [78, 64, 69, 51];
                break;
            case '20':
            case '21':
                columnsToOmit = [78, 88, 60, 51];
                break;
            case '2':
                columnsToOmit = [78, 88, 60, 64, 69];
                break;
            default:
                columnsToOmit = [78, 88, 60, 64, 69, 51];
                break;
        }
        setOmitColumns(columnsToOmit);
    }

    return(
        <React.Fragment>
            <Row className='pdfChunk'>
                <h3>Awards</h3>
                {
                    data &&
                    <div
                        className='pdfIgnore'
                        style={{ margin: '0px 0px 0px auto' }}
                    >
                        <ExcelExporter
                            reportName={'PlayerAwards'}
                            tables={[
                                {
                                    columns: columnGroups?.playerAwards,
                                    data: data,
                                },
                                {
                                    columns: awardLeaderboards
                                        ? awardLeaderboards[award]?.columns.filter(column => !omitColumns.includes(column.id))
                                        : null,
                                    data: awardLeaderboards ? awardLeaderboards[award]?.data : null,
                                    sortFunc: awardLeaderboards && awardLeaderboards[award]?.data
                                        ? awardLeaderboards[award]?.data?.sort(getSorterFunc(awardLeaderboards[award]))
                                        : null,
                                    tableHeader: awardLeaderboards && awardLeaderboards[award]?.data
                                        ? awardLeaderboards[award]?.header
                                        : null,
                                },
                            ]}
                        />
                        <PDFExporter fileName='PlayerAwards' />
                    </div>
                }
            </Row>
            {
                data && columnGroups &&
                <>
                    <div
                        className='pdfChunk'
                        style={{
                            marginLeft: '30px',
                            width: data
                                ? (columnGroups.playerAwards.reduce((acc, cur) => acc + ((cur.width) > 20
                                    ? (cur.width)
                                    : 20), 0)) + 4
                                : '100%'
                        }}
                    >
                        <TabulatorTable
                            cols={columnGroups.playerAwards}
                            data={data}
                            selectable={false}
                        />
                    </div>
                    <AwardSelectionModal
                        awardOptions={awardOptions}
                        show={showSeasonAwardModal}
                        setShow={setShowSeasonAwardModal}
                        setAward={updateAwardSelection}
                    />
                    <br />
                </>
            }
            {
                loading &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }
            {
                errorMsg &&
                <React.Fragment>
                    <p style={{ color: 'rgb(220, 53, 69)', textAlign: 'center' }}>{errorMsg}</p>
                    <br />
                    <br />
                </React.Fragment>
            }
            {
                award && awardLeaderboards &&
                <>
                    <div
                        className='pdfChunk'
                        style={{
                            marginLeft: '30px',
                            width: data
                                ? (
                                    awardLeaderboards[award].columns
                                        .filter(column => !omitColumns.includes(column.id))
                                        .reduce(
                                            (acc, cur) => acc + ((cur.width) > 20
                                                ? (cur.width)
                                                : 20
                                            ),
                                            0
                                        )
                                ) + 4
                                : '100%'
                        }}
                    >
                        <TabulatorTable
                            cols={awardLeaderboards[award].columns.filter(column => !omitColumns.includes(column.id))}
                            data={awardLeaderboards[award].data}
                            header={awardLeaderboards[award].header}
                            initSort={getTabulatorInitSort(awardLeaderboards[award])}
                        />
                    </div>
                </>
            }
            {
                award && !awardLeaderboards &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }
        </React.Fragment>
    );
}

export default PlayerAwards;


function AwardSelectionModal(props) {
    const [awardButtons, setAwardButtons] = useState(null);
    const handleClose = () => props.setShow(false);
    

    useEffect(() => {
        if (props.awardOptions?.awards) {
            const buttons = props.awardOptions?.awards.map(award =>
                <Row style={{ paddingTop: '10px' }}>
                    <Button
                        variant="info"
                        onClick={() => props.setAward(props.awardOptions.season, award.value, award.awardPos)}
                    >
                        {updateAwardName(award.label)}
                    </Button>
                </Row>
            )
            setAwardButtons(buttons);
        } else {
            setAwardButtons(null);
        }
    }, [props.awardOptions])

    return (
        <>
            <Modal show={props.awardOptions?.awards && props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Award Selection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>Select an award for the {props.awardOptions?.season} season:</Row>
                    {awardButtons}
                </Modal.Body>
            </Modal>
        </>
    );
}

function updateAwardName(awardName) {
    const awardPosRegex = /Position=(?<awardPos>.+)/;
    let awardPosMatch = awardName.match(awardPosRegex);
    let awardPos = awardPosMatch?.groups?.awardPos;
    let awardNameNoPos;
    let updatedAwardName;

    if (awardName.includes(' and Award Position')) {
        awardNameNoPos = awardName.split(' and Award Position')[0].trim();
        updatedAwardName = awardNameNoPos + ' (' + awardPos + ')';
    }
    else {
        awardNameNoPos = awardName;
        updatedAwardName = awardNameNoPos;
    }
    return updatedAwardName;
}