import React, { useState, useEffect, useRef } from 'react';
import PlayerSearch from './Common/PlayerSearch';
import { GetPlayers, GetCompReportData } from '../Services/ApiSvc';
import { columnManager } from './Common/ColumnManager';
import { Button, Form, Row, Col, Badge } from 'react-bootstrap';
import ColumnSelector from './Common/ColumnSelector';
import { GetPosNumFromAbbreviation, GetPosAbbreviationFromNum, GetPositionsByPosType } from './Common/CommonFunctions';
import SaveLoadButtons from './Common/SaveLoadButtons';

function CompReport(props) {
    const [allPlayers, setAllPlayers] = useState([]);
    const [reportType, setReportType] = useState('default');
    const [position, setPosition] = useState(0);
    const [groupId, setGroupId] = useState(1);
    const [groups, setGroups] = useState([]);
    const [columnsAvailable, setColumnsAvailable] = useState([]);
    const [selectedCols, setSelectedCols] = useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState([{}]);
    
    const componentIsMounted = useRef(true);

    useEffect(() => {
        (async function getColumns() {
            let allPlayersFromAPI = await GetPlayers();
            let columnsFromApi = await columnManager.getAllColumns();
            let selectedColumns = await columnManager.getColumnsByGroup(groupId > 0 ? groupId : 1);
            let compReportGroups = await columnManager.getAllGroupsBySection('CompReport');
            setAllPlayers(allPlayersFromAPI);
            setColumnsAvailable(columnsFromApi);
            setSelectedCols(selectedColumns);
            setGroups(compReportGroups);
        })();

        return () => {
            componentIsMounted.current = false;
        }
    }, [])

    useEffect(() => {
        if (groupId > 0) {
            refreshData(() => {
                setPosition(0);
                (async function getColumns() {
                    let tempCols = await columnManager.getColumnsByGroup(groupId);
                    setSelectedCols(tempCols);
                })();
            })
        }
    }, [groupId])

    async function getData(columns) {
        props.dataDispatchFunction({ type: 'loadData' });
        props.dataDispatchFunction(Object.assign({ type: 'setData' }, await GetCompReportData(selectedPlayers, columns, position)));
    }

    function refreshData(setSelectedColsFn) {
        props.dataDispatchFunction({
            type: 'setData',
            columns: [],
            playersData: [],
            platformYearData: [],
            platformYearMinusOneData: [],
            platformYearMinusTwoData: [],
            lastTwoYearsData: [],
            lastThreeYearsData: [],
            careerToDateData: [],
            exceptionMsg: '',
        });

        if (setSelectedColsFn) {
            setSelectedColsFn();
        }
    }

    function setPlayer(index, playerId, platformYear, posFlag) {
        let curSelectedPlayers = selectedPlayers.slice(0);
        curSelectedPlayers[index] = { playerId: playerId, platformYear: platformYear, posFlag: posFlag };
        setSelectedPlayers(curSelectedPlayers);
    }

    function removeSelectedPlayer(index) {
        let curSelectedPlayers = selectedPlayers.slice(0);
        curSelectedPlayers.splice(index, 1);
        setSelectedPlayers(curSelectedPlayers);
    }

    function reset() {
        refreshData(() => {
            (async function getColumns() {
                let columns = await columnManager.getColumnsById([1, 2, 3, 10004, 5, 10006]);
                setSelectedCols(columns);
                getData(columns);
            })();
        })
        let reportButtons = document.getElementById('reportForm').getElementsByClassName('custom-control-input');
        for (let i = 0; i < reportButtons.length; i++) {
            if (reportButtons[i].checked) {
                reportButtons[i].checked = false;
            }
        }
        let rbn = document.getElementById('custom-inline-radio-generic');
        rbn.checked = true;
        setReportType('custom');
        refreshData(() => {
            setGroupId(0);
        });
    }

    function getGroupType() {
        if ([3, 4, 6, 9].includes(groupId))
            return 'Pitcher';
        else
            return 'Batter';
    }

    let posSelectHtml = [];
    GetPositionsByPosType(getGroupType()).forEach(pos => {
        if (pos.num === position)
            posSelectHtml.push(<option key={pos.num} selected>{pos.abbreviation}</option>)
        else
            posSelectHtml.push(<option key={pos.num}>{pos.abbreviation}</option>)
    })

    return (
        <React.Fragment>
            <SaveLoadButtons
                header={<h1>Comparison Report</h1>}
                page={'CompReport'}
                resetFunction={reset}
                getReportFunction={() => JSON.stringify(selectedCols.map(selCol => selCol.id))}
                loadReportFunction={(report) => {
                    setReportType('custom');
                    refreshData(() => {
                        (async function loadColumnsFromReport() {
                            let jsonReport = JSON.parse(report);
                            let columns = await columnManager.getColumnsById(jsonReport);
                            setSelectedCols(columns);
                            getData(columns);
                        })();
                    })
                }}
            />
            {
                allPlayers && allPlayers.length > 0 &&
                selectedPlayers.map((selectedPlayer, index) => {
                    return (
                        <Row>
                            <PlayerSearch
                                key={index}
                                players={allPlayers}
                                label={'Player'}
                                selectedPlayer={selectedPlayer}
                                onSelect={
                                    (playerId, platformYear, posFlag) => setPlayer(index, playerId, platformYear, posFlag)
                                }
                            />
                            <Badge
                                style={{ cursor: 'pointer' }}
                                tabIndex='0'
                                variant='danger'
                                onClick={() => removeSelectedPlayer(index)}
                            >
                                X
                            </Badge>
                        </Row>
                    )
                })
            }

            <br />
            <Button variant='info' onClick={() => {
                let curSelectedPlayers = selectedPlayers.slice(0).map(player => {
                    return {
                        playerId: player.playerId,
                        platformYear: player.platformYear,
                        posFlag: player.posFlag,
                    }
                });
                curSelectedPlayers.push({ playerId: 0, platformYear: 0 });
                setSelectedPlayers(curSelectedPlayers);
            }}>Add Player</Button>
            <br />
            <br />
            <div className='row'>
                <b className='label'>Report Type: </b>
                <Form>
                    <div key={'custom-inline-radio'}>
                        {
                            ['Default', 'Custom'].map(lbl => {
                                if (lbl.toLowerCase() === reportType) {
                                    return (
                                        <Form.Check
                                            custom
                                            inline
                                            name='report'
                                            label={lbl}
                                            type='radio'
                                            key={lbl}
                                            id={`custom-inline-radio-${lbl.toLowerCase()}`}
                                            checked
                                            onChange={(event) => {
                                                if (event.target.value) {
                                                    setReportType(lbl.toLowerCase())
                                                }
                                            }}
                                        />
                                    )
                                }
                                else {
                                    return (
                                        <Form.Check
                                            custom
                                            inline
                                            name='report'
                                            label={lbl}
                                            type='radio'
                                            key={lbl}
                                            id={`custom-inline-radio-${lbl.toLowerCase()}`}
                                            onChange={(event) => {
                                                if (event.target.value) {
                                                    setReportType(lbl.toLowerCase())
                                                }
                                            }}
                                        />
                                    )
                                }
                            })
                        }
                    </div>
                </Form>
            </div>

            <br />
            <div className='row'>
                <b className='label'>Choose Default: </b>
            </div>
            <Form id='reportForm' onChange={(event) => {
                if (event.target.value) {
                    // Strip down checked radio button to report name
                    let reportType = event.target.getAttribute('id');
                    reportType = reportType.substring(reportType.lastIndexOf('-') + 1);

                    setReportType('default');
                    switch (reportType) {
                        case 'batterStand':
                            setGroupId(1);
                            break;
                        case 'catcherStand':
                            setGroupId(2);
                            break;
                        case 'startPitcherStand':
                            setGroupId(3);
                            break;
                        case 'relPitcherStand':
                            setGroupId(4);
                            break;
                        case 'batterSC':
                            setGroupId(5);
                            break;
                        case 'pitcherSC':
                            setGroupId(6);
                            break;
                        case 'catcherSC':
                            setGroupId(7);
                            break;
                        case 'batterComb':
                            setGroupId(8);
                            break;
                        case 'pitcherComb':
                            setGroupId(9);
                            break;
                    }
                }
            }}>
                <br />
                <div className='row'>
                    <div style={{ marginRight: '40px' }}>Standard</div>
                    <div key={'custom-inline-radio'}>
                        <Form.Check
                            custom
                            inline
                            name='statType'
                            label='Generic'
                            type='radio'
                            style={{ display: 'none' }}
                            id='custom-inline-radio-generic'
                        />
                        <Form.Check
                            custom
                            inline
                            name='statType'
                            label='Batter'
                            type='radio'
                            defaultChecked
                            id='custom-inline-radio-batterStand'
                        />
                        <Form.Check
                            custom
                            inline
                            name='statType'
                            label='Catcher'
                            type='radio'
                            id='custom-inline-radio-catcherStand'
                        />
                        <Form.Check
                            custom
                            inline
                            name='statType'
                            label='Starting Pitcher'
                            type='radio'
                            id='custom-inline-radio-startPitcherStand'
                        />
                        <Form.Check
                            custom
                            inline
                            name='statType'
                            label='Relief Pitcher'
                            type='radio'
                            id='custom-inline-radio-relPitcherStand'
                        />
                    </div>
                </div>
                <br />
                <div className='row'>
                    <div style={{ marginRight: '48px' }}>Statcast</div>
                    <div key={'custom-inline-radio'}>
                        <Form.Check
                            custom
                            inline
                            name='statType'
                            label='Batter'
                            type='radio'
                            id='custom-inline-radio-batterSC'
                        />
                        <Form.Check
                            custom
                            inline
                            name='statType'
                            label='Pitcher'
                            type='radio'
                            id='custom-inline-radio-pitcherSC'
                        />
                        <Form.Check
                            custom
                            inline
                            name='statType'
                            label='Catcher'
                            type='radio'
                            id='custom-inline-radio-catcherSC'
                        />
                    </div>
                </div>
                <br />
                <div className='row'>
                    <div style={{ marginRight: '29px' }}>Combined</div>
                    <div key={'custom-inline-radio'}>
                        <Form.Check
                            custom
                            inline
                            name='statType'
                            label='Batter'
                            type='radio'
                            id='custom-inline-radio-batterComb'
                        />
                        <Form.Check
                            custom
                            inline
                            name='statType'
                            label='Pitcher'
                            type='radio'
                            id='custom-inline-radio-pitcherComb'
                        />
                    </div>
                </div>
            </Form>
            <br />

            <div className='row'>
                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Show stats at the following position only: </Form.Label>
                <Form.Control style={{ width: '100px' }} as='select' onChange={(event) => setPosition(GetPosNumFromAbbreviation(event.target.value))}>
                    {posSelectHtml}
                </Form.Control>
            </div>

            <Button variant='success' onClick={() => getData(selectedCols)}>Submit</Button>
            <br />
            <br />
            
            {props.children}
            <ColumnSelector
                columns={columnsAvailable}
                selectedColumns={selectedCols}
                groups={groups}
                setColumns={(columns) => {
                    setReportType('custom');
                    refreshData(() => {
                        (async function getColumns() {
                            setSelectedCols(columns);
                            getData(columns);
                        })();
                    })
                }}
            />
            <br />
        </React.Fragment>
    )
}

export default CompReport;