import React from 'react';
import { Navbar, Nav, NavDropdown, Alert } from 'react-bootstrap';

function Header(props) {
    let errors = [];

    let selectedTab = props.match.params.tab;


    let user = null;
    try {
        user = JSON.parse(localStorage.getItem('user'));
    }
    catch (ex) {
        errors.push(
            <Alert style={{ width: '75%', margin: 'auto', }} variant='danger'>
                There was an issue getting user info.
            </Alert>
        )
    }

    return (
        <React.Fragment>
            <div className='header'>
                <img
                    width='535'
                    height='150'
                    src='/images/model3.jpg'
                    alt='logo'
                    style={{ float: 'left' }}
                />
                <a href='/home'>
                    <img
                        width='143'
                        height='150'
                        src='/images/mlbpalogo.png'
                        alt='logo'
                        style={{ float: 'right' }}
                    />
                </a>
            </div>
            <br />
            <br />
            <Navbar variant='dark'>
                <Nav className='mr-auto' style={{ width: '100%' }}>
                    <Nav.Link style={selectedTab === 'home' ? { color: 'white' } : null} href='/home'>Home</Nav.Link>
                    {
                        user &&
                        <React.Fragment>
                            <Nav.Link style={selectedTab === 'player' ? { color: 'white' } : null} href='/player'>Player Page</Nav.Link>
                            <Nav.Link style={selectedTab === 'leaderboards' ? { color: 'white' } : null} href='/leaderboards'>Leaderboards</Nav.Link>
                            <Nav.Link style={selectedTab === 'compreport' ? { color: 'white' } : null} href='/compreport'>Comparison Report</Nav.Link>
                            <Nav.Link style={selectedTab === 'query' ? { color: 'white' } : null} href='/query'>Query Tool</Nav.Link>
                            <NavDropdown title='Reports'>
                                <NavDropdown.Item href='/rankreport'>Rank Report</NavDropdown.Item>
                                <NavDropdown.Item href='/largestcontractsreport'>Largest Contracts Report</NavDropdown.Item>
                                <NavDropdown.Item href='/awardsreport'>Awards Report</NavDropdown.Item>
                                <NavDropdown.Item href='/recentsignings'>Recent Signings</NavDropdown.Item>
                                <NavDropdown.Item href='/arbitration'>Arbitration Report</NavDropdown.Item>
                                <NavDropdown.Item href='/splitsreport'>Splits Report</NavDropdown.Item>
                                <NavDropdown.Item href='/reliefpitcher'>Relief Pitcher Report</NavDropdown.Item>
                                <NavDropdown.Item href='/transactions'>Transactions Report</NavDropdown.Item>
                                <NavDropdown.Item href='/postseasonreport'>Postseason Report</NavDropdown.Item>
                            </NavDropdown>
                            {/*
                            <Nav.Link style={selectedTab === 'cbt' ? { color: 'white' } : null} href='/cbt'>CBT</Nav.Link>
                            <Nav.Link style={selectedTab === 'lrp' ? { color: 'white' } : null} href='/lrp'>LRP</Nav.Link>
                            */}
                            <Nav.Link style={{ margin: 'auto 0 auto auto' }} href='/login'>Logout</Nav.Link>
                        </React.Fragment>
                    }
                    {
                        !user &&
                        <Nav.Link style={selectedTab === 'login' ? { color: 'white', margin: 'auto 0 auto auto' } : { margin: 'auto 0 auto auto' }} href='/login'>Login</Nav.Link>
                    }
                </Nav>
            </Navbar>
            {errors}
        </React.Fragment>
    )
}

export default Header;