import React, { useState, useEffect, useRef, useReducer } from 'react';
import PlayerSearch from './Common/PlayerSearch';
import { GetPlayers, GetCompReportPitcherData, GetCompReportBatterData } from '../Services/ApiSvc';
import { columnManager } from './Common/ColumnManager';
import { Button, Form, Row, Col, Badge } from 'react-bootstrap';
import TabulatorTable from './Common/TabulatorTable';
import CompReport from './CompReport';
import ExcelExporter from './Common/ExcelExporter';
import PDFExporter from './Common/PDFExporter';
import { GetPosNumFromString, GetPosStringFromNum } from './Common/CommonFunctions';
import SaveLoadButtons from './Common/SaveLoadButtons';

function CompReportWrapper() {
    const [data, dataDispatch] = useReducer(
        (state, action) => {
            switch (action.type) {
                case 'setData':
                    return {
                        columns: action.columns,
                        playersData: action.playersData,
                        platformYearData: action.platformYearData,
                        platformYearMinusOneData: action.platformYearMinusOneData,
                        platformYearMinusTwoData: action.platformYearMinusTwoData,
                        lastTwoYearsData: action.lastTwoYearsData,
                        lastThreeYearsData: action.lastThreeYearsData,
                        careerToDateData: action.careerToDateData,
                        exceptionMsg: action.exceptionMsg,
                        loading: false,
                    };
                case 'loadData':
                    return {
                        columns: state.columns,
                        playersData: state.playersData,
                        platformYearData: state.platformYearData,
                        platformYearMinusOneData: state.platformYearMinusOneData,
                        platformYearMinusTwoData: state.platformYearMinusTwoData,
                        lastTwoYearsData: state.lastTwoYearsData,
                        lastThreeYearsData: state.lastThreeYearsData,
                        careerToDateData: state.careerToDateData,
                        exceptionMsg: state.exceptionMsg,
                        loading: true,
                    };
                default:
                    throw new Error();
            }
        },
        {
            columns: [],
            playersData: [],
            platformYearData: [],
            platformYearMinusOneData: [],
            platformYearMinusTwoData: [],
            lastTwoYearsData: [],
            lastThreeYearsData: [],
            careerToDateData: [],
            exceptionMsg: '',
            loading: false,
        }
    )

    function dispatch(newState) {
        dataDispatch(newState);
    }

    return (
        <div className='body comp-report'>
            <CompReport dataDispatchFunction={dispatch}>
                {
                    data.playersData && data.playersData.length > 0 && /*daplayerData[0].data &&*/                    <React.Fragment>
                        <ExcelExporter
                            reportName={'CompReport'}
                            players={data.playersData}
                            platformYearData={data.platformYearData}
                            platformYearMinusOneData={data.platformYearMinusOneData}
                            platformYearMinusTwoData={data.platformYearMinusTwoData}
                            lastTwoYearsData={data.lastTwoYearsData}
                            lastThreeYearsData={data.lastThreeYearsData}
                            careerToDateData={data.careerToDateData}
                            columns={data.columns}
                        />
                        <PDFExporter columns={data.columns} fileName={'CompReport'} />
                        <span style={{ backgroundColor: '#FED8B1' }}>Click + sign on 2020 and Career rows to show 2020 statistics on a 162 game basis</span>
                    </React.Fragment>
                }
                {
                    data.exceptionMsg &&
                    <React.Fragment>
                        <p style={{ color: 'rgb(220, 53, 69)', textAlign: 'center' }}>{data.exceptionMsg}</p>
                        <br />
                        <br />
                    </React.Fragment>
                }
                <div
                    className='pdfSection'
                    style={{
                        width: data.playersData && data.playersData.length > 0 /*&& data.playerData[0].data*/
                            ? (data.columns.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                            : '100%'
                    }}
                >
                    {
                        data.loading &&
                        <div>
                            <br />
                            <br />
                            <i className='fa fa-spinner fa-spin loading-icon'></i>
                            <br />
                        </div>
                    }
                    {
                        data.playersData?.map((playerData, index) => {
                            if (playerData && playerData.length > 0) {
                                return (
                                    <div className='pdfChunk'>
                                        <TabulatorTable
                                            key={index}
                                            cols={data.columns}
                                            data={playerData}
                                            header={playerData[0].Player}
                                            initSort={[{ column: 'Season', dir: 'asc' }]}
                                            dataTreeElementColumn='Team'
                                        />
                                    </div>
                                )
                            }
                            return <React.Fragment />
                        })
                    }
                    {
                        data.platformYearData && data.platformYearData.length > 0 &&
                        <React.Fragment>
                            <div className='pdfChunk'>
                                <TabulatorTable
                                    key={data.playersData.length}
                                    cols={data.columns}
                                    data={data.platformYearData}
                                    header={'INDIVIDUAL SEASONS'}
                                    label={'PLATFORM'}
                                    dataTreeElementColumn='Team'
                                />
                            </div>
                            <div className='pdfChunk'>
                                <TabulatorTable
                                    key={data.playersData.length + 1}
                                    cols={data.columns}
                                    data={data.platformYearMinusOneData}
                                    label={'PLATFORM-1'}
                                    dataTreeElementColumn='Team'
                                />
                            </div>
                            <div className='pdfChunk'>
                                <TabulatorTable
                                    key={data.playersData.length + 2}
                                    cols={data.columns}
                                    data={data.platformYearMinusTwoData}
                                    label={'PLATFORM-2'}
                                    dataTreeElementColumn='Team'
                                />
                            </div>
                            <div className='pdfChunk'>
                                <TabulatorTable
                                    key={data.playersData.length + 3}
                                    cols={data.columns}
                                    data={data.lastTwoYearsData}
                                    header={'AGGREGATE SEASONS'}
                                    label={'TWO-YEAR'}
                                    dataTreeElementColumn='Team'
                                />
                            </div>
                            <div className='pdfChunk'>
                                <TabulatorTable
                                    key={data.playersData.length + 4}
                                    cols={data.columns}
                                    data={data.lastThreeYearsData}
                                    label={'THREE-YEAR'}
                                    dataTreeElementColumn='Team'
                                />
                            </div>
                            <div className='pdfChunk'>
                                <TabulatorTable
                                    key={data.playersData.length + 5}
                                    cols={data.columns}
                                    data={data.careerToDateData}
                                    label={'CAREER TO PY'}
                                    dataTreeElementColumn='Team'
                                />
                            </div>
                        </React.Fragment>
                    }
                </div>
            </CompReport>
        </div>
    )
}

export default CompReportWrapper;