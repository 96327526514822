import React, { useState, useEffect, useRef } from 'react';
import { Popover, Overlay } from 'react-bootstrap';

function DatePicker(props) {
    const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const [viewDate, setViewDate] = useState(props.startDate ? props.startDate : props.endDate ? props.endDate : new Date());
    const [dateString, setDateString] = useState(viewDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }));
    const [monthLength, setMonthLength] = useState(daysInMonth[viewDate.getMonth()]);
    const [showOverlay, setShowOverlay] = useState(false);
    const [typedDate, setTypedDate] = useState(dateString);
    const [dateIsSet, setDateIsSet] = useState(true);
    const ref = useRef(null);

    useEffect(() => {
        let month = viewDate.getMonth();
        let year = viewDate.getFullYear();

        if (month === 1 && ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0)) {
            setMonthLength(29);
        }
        else {
            setMonthLength(daysInMonth[month]);
        }
        setDateString(viewDate.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }));
    }, [viewDate]);

    useEffect(() => {
        let tDate = new Date(typedDate);
        if (Object.prototype.toString.call(tDate) === '[object Date]' && !isNaN(tDate.getTime())) {
            selectDay(tDate);
        }
        else {
            setDateIsSet(false);
        }
    }, [typedDate]);

    useEffect(() => {
        setViewDate(props.startDate ? props.startDate : props.endDate ? props.endDate : new Date());
    }, [props.startDate, props.endDate])

    const selectDay = (date) => {
        setShowOverlay(false);
        setDateIsSet(true);
        setDateString(date.toLocaleString('en-US', { day: 'numeric', month: 'numeric', year: 'numeric' }));
        props.setDate(new Date(date));
        setViewDate(new Date(date));
    }

    let calendarHtml = [];
    let day = 1;
    let calDate = new Date(viewDate.getFullYear(), viewDate.getMonth(), day);
    let selectedDate = new Date(dateString);
    for (var i = 0; i < 7; i++) {
        let calendarWeekHtml = [];
        if (day <= monthLength) {
            for (var j = 0; j <= 6; j++) {
                calDate.setDate(day);
                let divClass = 'col calendar-day';
                if (selectedDate.getFullYear() === calDate.getFullYear() && selectedDate.getMonth() === calDate.getMonth() && selectedDate.getDate() === day) {
                    divClass += ' selected-day';
                }
                if (day <= monthLength && calDate.getDay() === j) {
                    if ((!props.minDate || props.minDate <= calDate) && (!props.maxDate || props.maxDate >= calDate)) {
                        let dateSelect = new Date(calDate.getFullYear(), calDate.getMonth(), day);
                        calendarWeekHtml.push(
                            <div key={j} className={divClass} onClick={() => selectDay(dateSelect) }>{day}</div>
                        )
                    }
                    else {
                        calendarWeekHtml.push(
                            <div key={j} className='col calendar-day' style={{ color: 'white' }}>.</div>
                        )
                    }
                    day++;
                }
                else {
                    calendarWeekHtml.push(
                        <div key={j} className='col calendar-day' style={{ color: 'white' }}>.</div>
                    )
                }
            }
            calendarHtml.push(
                <div key={i} className='row'>
                    {calendarWeekHtml}
                </div>
            )
        }
    }

    let inputValidityCss = dateIsSet ? (props.endDate && props.endDate >= props.minDate) || (props.startDate && props.startDate <= props.maxDate) ? 'solid 1px green' : 'solid 1px red' : 'solid 1px black';

    return (
        <React.Fragment>
            <div className='row'>
                <input style={{ width: '100px', border: inputValidityCss }} id='date-input' placeholder={dateString}
                    onKeyDown={(e) => { if (e.keyCode === 13) { e.target.blur() } }}
                    onBlur={(e) => { setTypedDate(e.target.value); e.target.value = ''; }}
                    onChange={() => setDateIsSet(false)} />
                {/*<input style={{ width: '100px', border: inputValidityCss }} placeholder={dateString} onChange={(event) => textSubmitday(event.target.value)} />*/}
                <img
                    width='42'
                    height='30'
                    ref={ref}
                    src={'./images/Calendar.png'}
                    alt=''
                    type='button'
                    onClick={() => setShowOverlay(!showOverlay)}
                />
            </div>
            <Overlay show={showOverlay} target={ref.current} placement='bottom'>
                <Popover id='calendar'>
                    <Popover.Title>
                        <div className='row' style={{ flexWrap: 'nowrap' }}>
                            <p style={{ margin: 'auto auto auto 0' }} type='button' onClick={() => {
                                let newDate = new Date(viewDate);
                                if (viewDate.getMonth() === 0) {
                                    newDate.setFullYear(newDate.getFullYear() - 1);
                                    newDate.setMonth(11);
                                    setViewDate(newDate);
                                }
                                else {
                                    newDate.setMonth(newDate.getMonth() - 1)
                                    setViewDate(newDate);
                                }
                            }}>&#8592;</p>
                            <h4 style={{ margin: 'auto' }}>
                                {viewDate.toLocaleString('en-US', { month: 'long' })}
                            </h4>
                            <h4 style={{ margin: 'auto' }}>
                                {viewDate.toLocaleString('en-US', { year: 'numeric' })}
                            </h4>
                            <p style={{ margin: 'auto 0 auto auto' }} type='button' onClick={() => {
                                let newDate = new Date(viewDate);
                                if (viewDate.getMonth() === 11) {
                                    newDate.setFullYear(newDate.getFullYear() + 1);
                                    newDate.setMonth(0);
                                    setViewDate(newDate);
                                }
                                else {
                                    newDate.setMonth(newDate.getMonth() + 1)
                                    setViewDate(newDate);
                                }
                            }}>&#8594;</p>
                        </div>
                    </Popover.Title>
                    <Popover.Content>
                        <div className='container'>
                            <div className='row'>
                                <div className='col calendar-header'>
                                    Sun
                                </div>
                                <div className='col calendar-header'>
                                    Mon
                                </div>
                                <div className='col calendar-header'>
                                    Tue
                                </div>
                                <div className='col calendar-header'>
                                    Wed
                                </div>
                                <div className='col calendar-header'>
                                    Thu
                                </div>
                                <div className='col calendar-header'>
                                    Fri
                                </div>
                                <div className='col calendar-header'>
                                    Sat
                                </div>
                            </div>
                            {calendarHtml}
                        </div>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </React.Fragment>
        
    )
}

export default DatePicker;