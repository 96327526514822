import React, { useState, useEffect } from 'react';
import { Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { advancedFiltersFunctions, dragAndDropFunctions } from './Functions';
import StatSelectionForm from '../QueryTool/StatSelectionForm';
import { getValueLabelFromFormInputs } from '../Common/CommonFunctions';
import DraggableFilter from './DraggableFilter';
import { columnManager } from '../Common/ColumnManager';

function AdvancedFilters(props) {
    const [hidden, setHidden] = useState(true);
    const [message, setMessage] = useState('');
    function toggleFilterDisplay() {
        setHidden(!hidden);
    }

    useEffect(() => {
        props.setHideFilters(hidden);
    }, [hidden]);

    let sectionWidth = `-${document.getElementById('floating-filters')?.clientWidth}px`;

    function applyToAll(section) {
        let dropBlocks = Array.prototype.filter.call(document.getElementsByClassName('dropZone dropZone-all')[0].childNodes, (child) => {
            return child;
        });
        if (dropBlocks.length === 0) return;
        let filterGranularities = [];
        Array.prototype.forEach.call(dropBlocks, (dropBlock) => {
            dropBlock.querySelectorAll(`span[draggable='true']`).forEach(filter => {
                filterGranularities.push(filter.getAttribute('granularity'));
            })
        })

        function granularityComparisonAllow(filterType, filterGranularities, dropZoneGranularity) {
            if (filterType == 'ISW') {
                return filterGranularities
                    .map(granularity => parseInt(granularity))
                    .every(granularity => granularity >= parseInt(dropZoneGranularity));
            } else {
                return filterGranularities
                    .map(granularity => parseInt(granularity))
                    .every(granularity => granularity <= 2);
            }
        }

        let filterType = dropBlocks[0].classList.contains('ISW') ? 'ISW' : 'IGW';
        let dropZones = Array.prototype.filter.call(document.getElementById(section).getElementsByClassName('dropZone'), (dz) => {
            let dropZoneGranularity = dz.getAttribute('granularity') ?? -1;
            return granularityComparisonAllow(filterType, filterGranularities, dropZoneGranularity)
                && !dz.className.includes('-all')
                && !dz.className.includes('block')
                && dz.className.includes(filterType);
        })
        if (dropZones.length === 0) {
            setMessage(`One or more of the filters can not be applied to any 
                ${section == 'DisStat' ? 'Display Stat' : 'Numerical Criteria'}!`);
            setTimeout(() => { setMessage('') }, 5000);
        }

        Array.prototype.forEach.call(dropBlocks, (dropBlock) => {
            Array.prototype.forEach.call(dropZones, dz => {
                let nullEv = {
                    preventDefault: () => { },
                    stopPropagation: () => { },
                };
                dragAndDropFunctions.dropElement(nullEv, dz, dropBlock.id)
            });
        });
    }

    return (
        <div style={{ left: hidden ? sectionWidth : '0px' }} id='floating-filters'>
            <FilterCreation
                selectableStatsByGroup={props.selectableStatsByGroup}
                colGroups={
                    props.selectableStatGroups.filter(group => {
                        return !['consecutive', 'throughX', 'dateRange'].includes(props.tabName)
                            || group.id !== 43;
                    })
                }
                tabName={props.tabName}
                timePeriodNotSelectable={true}
                hideFilters={true}
            />
            <Row style={{ minHeight: '36px' }}>
                <Col xs={3} xl={1}>
                    <Button variant='info' onClick={() => applyToAll('NumCrit')}>
                        Apply to All Numerical Criteria
                    </Button>
                </Col>
                <Col xs={3} xl={1}>
                    <Button variant='info' onClick={() => applyToAll('DisStat')}>
                        Apply to All Display Stats
                    </Button>
                </Col>
                <Col xs={9} lg={6} style={{ minHeight: '36px' }}>
                    <div
                        className='dropZone dropZone-all'
                        onDragOver={(ev) => {
                            ev.preventDefault();
                            ev.dataTransfer.dropEffect = 'move';
                        }}
                        onDrop={(ev) => dragAndDropFunctions.dropElement(ev)}
                    >
                    </div>
                </Col>
            </Row>
            <Row className='justify-content-md-center'>
                {
                    message &&
                    <Col xs={6} style={{ border: 'solid 2px red', borderRadius: '5px', color: 'red', background: '#d2d2d2' }}>
                        {message}
                    </Col>
                }
            </Row>
            <span className='span-button' onClick={toggleFilterDisplay} type='button'>{hidden ? '>' : '<'}</span>
            <span className='span-filter-label'>ADVANCED FILTERS</span>
        </div>
    )
}

export default AdvancedFilters;

function FilterCreation(props) {
    const [iswFilter, setIswFilter] = useState({
        column: null,
        index: 1,
        stat: null,
        operator: '>',
        value: '0',
        timePeriod: props.timePeriodNotSelectable ? 'Career To Date' : 'Platform Year',
    });
    const [igwFilter, setIgwFilter] = useState({
        column: null,
        index: 1,
        stat: null,
        operator: '>',
        value: '0',
        timePeriod: props.timePeriodNotSelectable ? 'Career To Date' : 'Platform Year',
    });
    const [filters, setFilters] = useState([]);

    async function changeFilterByType(type, field, value) {
        let tempFilter = {};
        if (type === 'isw') tempFilter = Object.assign({}, iswFilter);
        else if (type === 'igw') tempFilter = Object.assign({}, igwFilter);

        tempFilter = await changeFilter(tempFilter, field, value);

        if (type === 'isw') setIswFilter(tempFilter);
        else if (type === 'igw') setIgwFilter(tempFilter);
    }

    async function changeFilter(tempFilter, field, value) {
        tempFilter[field] = value;

        if (field === 'stat') {
            let column = await columnManager.getSingleColumnById(tempFilter.stat);
            tempFilter.timePeriod =
                props.timePeriodNotSelectable
                    ? 'Career To Date'
                    : column.formInputs.defaultTimePeriod;

            tempFilter.column = column;
            switch (column.formInputs.inputType) {
                case 'Date':
                    tempFilter.value = new Date();
                    break;
                case 'DateNoYear':
                    tempFilter.operator = '=';
                    tempFilter.value = new Date();
                    break;
                case 'Select':
                    tempFilter.operator = '=';
                    tempFilter.value = column.formInputs.selectOptions[0]?.value ?? '0';
                    tempFilter.valueLabel = column.formInputs.selectOptions[0]?.label ?? '0';
                    break;
                case 'MultiSelect':
                    tempFilter.operator = '=';
                    tempFilter.value = null;
                    tempFilter.valueLabel = null;
                    break;
                case 'Number':
                    tempFilter.operator = '=';
                    tempFilter.value = 0;
                    tempFilter.valueLabel = null;
                    break;
                default:
                    tempFilter.operator = '=';
                    tempFilter.value = null;
                    tempFilter.valueLabel = null;
                    break;
            }
        }
        if (tempFilter.stat) {
            let column = await columnManager.getSingleColumnById(tempFilter.stat);
            tempFilter.valueLabel = getValueLabelFromFormInputs(
                column.formInputs,
                tempFilter.value
            )
        }
        return tempFilter
    }

    /**
     * Adds the filter to the list of draggable html elements.
     * @param {string} filterType Can either be "sit"(situational) or "igw"(in games where)
     */
    const createFilter = async (filterType) => {
        let tempFilters = filters.slice(0);
        switch (filterType) {
            case 'isw':
                if (iswFilter && iswFilter.stat && iswFilter.stat !== 'Select a Split') {
                    let filter = Object.assign({}, iswFilter);
                    let column = await columnManager.getSingleColumnById(filter.stat);
                    filter.split = props.selectableStatsByGroup.flat().find(c => c.key == filter.stat).title;
                    filter.statId = filter.stat;
                    filter.filterType = 'ISW';
                    filter.granularity = column?.formInputs?.granularity;
                    tempFilters.push(filter);
                }
                break;
            case 'igw':
                if (igwFilter && igwFilter.stat && igwFilter.stat !== 'Select a Split') {
                    let filter = Object.assign({}, igwFilter);
                    let column = await columnManager.getSingleColumnById(filter.stat);
                    filter.split = props.selectableStatsByGroup.flat().find(c => c.key == filter.stat).title;
                    filter.statId = filter.stat;
                    filter.filterType = 'IGW';
                    filter.granularity = column?.formInputs?.granularity;
                    tempFilters.push(filter);
                }
                break;
            default:
                break;
        }
        setFilters(tempFilters);
    }


    let filterHtml = [];
    filters.forEach((filter, index) => {
        if (!filter.operator) {
            filter.operator = '=';
        }
        let badge =
            <DraggableFilter
                className={filter.filterType}
                filterText={filter.split + filter.operator + (filter.valueLabel ?? filter.value)}
                filter={filter.statId + filter.operator + filter.value}
                id={'filter-' + index}
                statId={filter.statId}
                granularity={filter.granularity}
            />
        if (index === 0) {
            filterHtml.push(
                <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip>
                            Drag Me!
                        </Tooltip>
                    }
                >
                    {badge}
                </OverlayTrigger>
            )
        } else {
            filterHtml.push(
                badge
            )
        }
    })

    return (
        <React.Fragment>
            <Row>
                <Col xs={9}>
                    <Row key='0'>
                        <Col className='d-none d-lg-block' lg={3} xl={2}>
                            Split
                        </Col>
                        <Col className='d-none d-lg-block' lg={2}>
                            Operator
                        </Col>
                        <Col className='d-none d-lg-block' lg={2}>
                            Value
                        </Col>
                        <Col className='d-none d-lg-block' lg={1}></Col>
                    </Row>
                </Col>
                <Col xs={3} lg={2} />
            </Row>
            <Row>
                <Col xs={9}>
                    <StatSelectionForm
                        selectableStatsByGroup={props.selectableStatsByGroup.map(group => {
                            return group.filter(stat => stat.formInputs.isISWFilter);
                        })}
                        colGroups={props.colGroups.filter(group => group.groupId === 47)}
                        section={'Filter'}
                        tabName={props.tabName}
                        timePeriodNotSelectable={props.timePeriodNotSelectable}
                        hideFilters={props.hideFilters}
                        stats={[]}
                        curStat={iswFilter}
                        index={1}
                        setStat={async (index, field, value) => changeFilterByType('isw', field, value)}
                        placeholder='In Situations Where'
                    />
                </Col>
                <Col xs={3} lg={2}>
                    <Button variant='info' onClick={() => createFilter('isw')}>Create In Situations Where (ISW) Filter</Button>
                </Col>
            </Row>
            <Row>
                <Col xs={9}>
                    <StatSelectionForm
                        selectableStatsByGroup={props.selectableStatsByGroup.map(group => {
                            return group.filter(stat => stat.formInputs.isIGWFilter);
                        })}
                        colGroups={props.colGroups}
                        section={'Filter'}
                        tabName={props.tabName}
                        timePeriodNotSelectable={props.timePeriodNotSelectable}
                        hideFilters={props.hideFilters}
                        stats={[]}
                        curStat={igwFilter}
                        index={1}
                        setStat={async (index, field, value) => changeFilterByType('igw', field, value)}
                        placeholder='In Games Where'
                    />
                </Col>
                <Col xs={3} lg={2}>
                    <Button variant='info' onClick={() => createFilter('igw')}>Create In Games Where (IGW) Filter</Button>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {filterHtml}
                </Col>
            </Row>
        </React.Fragment>
    )
}