import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import CalendarDay from './CalendarDay';

function CalendarMonth(props) {
    const [displayMonth, setDisplayMonth] = useState(true);

    let firstDayOfMonth = new Date(props.year, props.month, 1);

    let weeks = { 0: [] };
    let curWeek = 0;
    let day = new Date(props.year, props.month, 1);
    while (day.getMonth() === props.month) {
        if (day.getDate() === 1)
            weeks[curWeek] = (new Array(firstDayOfMonth.getDay())).fill({ date: null });
        weeks[curWeek].push({ date: new Date(day) });

        day = new Date(day.getFullYear(), day.getMonth(), day.getDate() + 1);

        if (day.getMonth() !== props.month) {
            let remainingEmptyCalendarDays = (new Array(day.getDay() > 0 ? 7 - day.getDay() : 0))
                .fill({ date: null });

            weeks[curWeek] = weeks[curWeek].concat(remainingEmptyCalendarDays);
        }
        else if (day.getDay() === 0) {
            curWeek++;
            weeks[curWeek] = [];
        }
    }

    function inDateRange(date) {
        if (!props.dateRange || !Object.hasOwn(props.dateRange, 'startDate') || !Object.hasOwn(props.dateRange, 'endDate'))
            return true;

        return date >= props.dateRange.startDate && date <= props.dateRange.endDate;
    }

    return (
        <div style={{ height: displayMonth ? '700px' : '100px', width: '525px' }}>
            <h5 style={{ textAlign: 'center' }}>
                {monthNames[props.month]}
                <FontAwesomeIcon
                    icon={displayMonth ? faEye : faEyeSlash}
                    onClick={() => setDisplayMonth(!displayMonth)}
                    style={{ marginLeft: '5px' }}
                />
            </h5>
            {
                displayMonth &&
                Object.keys(weeks).map(weekNumber => {
                    return <Row key={weekNumber}>
                        {
                            weeks[weekNumber].map((day, dayIndex) => {
                                return <CalendarDay
                                    date={day.date}
                                    dayIndex={dayIndex}
                                    games={props.games}
                                    inDateRange={inDateRange(day.date)}
                                    key={dayIndex}
                                    updateDateRange={props.updateDateRange}
                                    week={weekNumber}
                                    statsToDisplay={props.statsToDisplay}
                                />
                            })
                        }
                    </Row>
                })
            }
        </div>
    )
}

const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

export default CalendarMonth;