import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { GetLargestContractsData } from '../../Services/ApiSvc';
import { columnManager } from '../Common/ColumnManager';
import LargestContractForm from './LargestContractForm';
import ColumnSelector from '../Common/ColumnSelector';
import TabulatorTable from '../Common/TabulatorTable';
import ExcelExporter from '../Common/ExcelExporter';
import PDFExporter from '../Common/PDFExporter';

function LargestContracts() {
    const [data, setData] = useState([]);
    const [formOptions, setFormOptions] = useState({});
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [selectedCols, setSelectedCols] = useState([]);
    const [groups, setGroups] = useState([]);
    const [groupId, setGroupId] = useState(50);
    const [defRankColumns, setDefRankColumns] = useState([]);

    useEffect(() => {
        (async function Initialize() {
            let allColumns = await columnManager.getAllColumns();
            let defaultLargestContractsColumns = await columnManager.getColumnsByGroup(50);
            let selectedColumns = await columnManager.getColumnsByGroup(groupId > 0 ? groupId : 50);
            let largestContractsGroups = await columnManager.getAllGroupsBySection('LargestContracts');

            setColumns(allColumns);
            setDefRankColumns(defaultLargestContractsColumns);
            setSelectedCols(selectedColumns);
            setGroups(largestContractsGroups);
        })()
    }, [])

    useEffect(() => {
        if (selectedCols.length > 0) {
            getData();
        }
    }, [selectedCols])

    function getData() {
        setLoading(true);
        setData([]);
        GetLargestContractsData(formOptions, selectedCols)
            .then((value) => {
                setData(value);
                setLoading(false);
            });
    }

    return (
        <div className='body largestcontracts'>
            <LargestContractForm
                setFormOptions={setFormOptions}
            />
            <Button style={{ margin: 'auto 0px auto auto' }} variant='success'
                onClick={() => getData()}
            >
                Submit
            </Button>
            <br />
            <br />

            {
                data && data.length > 0 &&
                <React.Fragment>
                        <ExcelExporter reportName={'LargestContracts'} data={data} columns={selectedCols} />
                        <PDFExporter fileName='LargestContracts' />
                    <br />
                    <br />
                </React.Fragment>
            }

            {
                loading &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }

            {
                data.exceptionMsg &&
                <React.Fragment>
                    <p style={{ color: 'rgb(220, 53, 69)', textAlign: 'center' }}>{data.exceptionMsg}</p>
                    <br />
                    <br />
                </React.Fragment>
            }

            <div className='pdfSection' style={{ width: data ? (selectedCols.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4 : '100%' }}>
                {
                    data && data.length > 0 &&
                    <div className='pdfChunk'>
                        <TabulatorTable
                            cols={selectedCols}
                            data={data}
                            paginationSize={25}
                            initSort={[{ column: 'TotalVal', dir: 'desc' }]}
                            dataTreeElementColumn='Team'
                        />
                    </div>
                }

                <ColumnSelector
                    columns={columns}
                    selectedColumns={selectedCols}
                    groups={groups}
                    setColumns={(columns) => {
                        setSelectedCols(columns);
                    }}
                />
                <br />
            </div>
        </div>
    )
}

export default LargestContracts;