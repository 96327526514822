import React from 'react';

function Blank() {
    return (
        <React.Fragment>

        </React.Fragment>
    )
}

export default Blank;