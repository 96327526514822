import React from 'react';
import { Form } from 'react-bootstrap';
import StatSelection from './StatSelection';

function NumericalCriteria(props) {
    let hasBlankCriteria = props.numericalCriteria.some(crit => crit.stat === '' || crit.stat == null)

    return (
        <React.Fragment>
            <h4>Select Numerical Criteria</h4>
            {
                props.displayScaleUpOptions &&
                <Form.Check
                    name='scaleUp'
                    label='Scale Up 2020'
                    type='checkbox'
                    onChange={(event) => {
                        props.setScaleUp(event.target.checked);
                    }}
                />
            }
            <div
                id='NumCrit'
                style={{
                    width: '100%',
                    backgroundColor: 'lightgrey',
                    border: hasBlankCriteria ? 'solid 2px red' : 'solid 1px black',
                    padding: '10px'
                }}>
                <StatSelection
                    tabName={props.tabName}
                    selectableStatsByGroup={props.selectableStatsByGroup}
                    colGroups={
                        props.selectableStatGroups.filter(group => {
                            return !['consecutive', 'throughX', 'dateRange'].includes(props.tabName)
                                || group.id !== 43;
                        })
                    }
                    section={'Criteria'}
                    stats={props.numericalCriteria}
                    setStats={props.setNumericalCriteria}
                    hideFilters={props.hideFilters}
                    timePeriodNotSelectable={props.timePeriodNotSelectable}
                />
            </div>
        </React.Fragment>
    )
}

export default NumericalCriteria;

