import React, { useState, useEffect } from 'react';
import PlayerSearch from '../Common/PlayerSearch';
import { GetPlayers } from '../../Services/ApiSvc';
import { columnManager } from '../Common/ColumnManager';
import ReliefPitcherReport from './ReliefPitcherReport';

function PitcherSelector() {
    const [allPlayers, setAllPlayers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [playerId, setPlayerId] = useState(0);
    const [player, setPlayer] = useState({});
    const [showReliefPitcherReport, setShowReliefPitcherReport] = useState(false);

    useEffect(() => {
        (async function Initialize() {
            let allPlayersFromAPI = await GetPlayers();
            let filteredPlayers = allPlayersFromAPI.filter(player => {
                return player.posFlag === 'P';
            });
            let rankReportGroups = await columnManager.getAllGroupsBySection('PlayerPage');

            setAllPlayers(filteredPlayers);
            setGroups(rankReportGroups);
        })()
    }, []);

    useEffect(() => {
        if (playerId > 0) {
            let player = allPlayers.find((p) => p.id === playerId);
            setPlayer(player);
            setShowReliefPitcherReport(true); // Set the flag to true when player is selected
        } else {
            setShowReliefPitcherReport(false); // Set the flag to false when no player is selected
        }
    }, [playerId]);

    return (
        <div className='body relief-pitcher-report'>
            <div className='row'>
                <div style={{ margin: '0px 0px 0px auto' }}>
                    <PlayerSearch
                        key={0}
                        players={allPlayers}
                        label={'Player Search'}
                        onSelect={(selectedPlayerId, platformSeason, posFlag) => setPlayerId(selectedPlayerId)}
                    />
                </div>
            </div>
            <br />
            {showReliefPitcherReport && playerId > 0 && (
                <div className='pdfSection'>
                    <ReliefPitcherReport
                        groups={groups}
                        player={player}
                    />
                    <br />
                </div>
            )}
        </div>
    )
}

export default PitcherSelector;