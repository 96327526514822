import React from 'react';
import { Form, Col } from 'react-bootstrap';


function OperatorDropdown(props) {
    let operators = ['>', '>=', '=', '<=', '<', '<>'];
    let operatorHtml = []
    operatorHtml.push(
        <React.Fragment>
            <Col xs={3} className='d-lg-none'>Operator: </Col>
            <Col xs={3} lg={2}>
                <Form.Control
                    as='select'
                    onChange={(event) => props.setOperator(props.curStatIndex, 'operator', event.target.value)}
                >
                    {
                        operators.map((curOp, curIndex) =>
                            <option
                                selected={props.curOperator === curOp ? 'selected' : false}
                                key={curIndex}
                                value={curOp}
                            >
                                {curOp}
                            </option>
                        )
                    }
                </Form.Control>
            </Col>
        </React.Fragment>
    )

    return (operatorHtml)
}

export default OperatorDropdown;