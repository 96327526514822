import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { GetAwardsData, GetPlayers } from '../../Services/ApiSvc';
import { columnManager } from '../Common/ColumnManager';
import AwardsForm from './AwardsForm';
import ColumnSelector from '../Common/ColumnSelector';
import TabulatorTable from '../Common/TabulatorTable';
import ExcelExporter from '../Common/ExcelExporter';
import PDFExporter from '../Common/PDFExporter';
import StatFilter, { addFilterAsParameter, addFilterForQueryTool, createFilter } from '../Common/StatFilter';

function Awards() {
    let curSeason = new Date().getMonth() < 11 ? new Date().getFullYear() - 1 : new Date().getFullYear();
    const [allPlayers, setAllPlayers] = useState(null);
    const [awardIds, setAwardIds] = useState(null);
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState([]);
    const [selectedCols, setSelectedCols] = useState([]);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        (async function Initialize() {
            let awardsFiltersGroupId = 79;
            let defaultAwardColumnsGroupId = 60;
            let allColumns = await columnManager.getAllColumns();
            let defaultAwardsColumns = await columnManager.getColumnsByGroup(defaultAwardColumnsGroupId);
            let allAwardIds = defaultAwardsColumns.find(col => col.id === 769)?.formInputs.selectOptions.map(option => option.value);
            let awardsGroups = (await columnManager.getAllGroupsBySection('Leaderboards'))
                .concat(await columnManager.getAllGroupsBySection('AwardsReport'))
                .filter(group => group.id !== awardsFiltersGroupId);

            let awardsFilters = (await columnManager.getColumnsByGroup(awardsFiltersGroupId))
                .map((column, filterIndex) => {
                    let filter = Object.assign({}, createFilter(filterIndex, column));
                    if (column.id === 2)
                        filter.value = {
                            lowerBound: curSeason.toString(),
                            upperBound: curSeason.toString(),
                        };
                    return filter;
                });

            let allPlayersFromAPI = await GetPlayers();

            setAllPlayers(allPlayersFromAPI);
            setAwardIds(allAwardIds);
            setColumns(allColumns);
            setFilters(awardsFilters);
            setGroups(awardsGroups);
            setSelectedCols(defaultAwardsColumns);
        })()
    }, [])

    useEffect(() => {
        if (selectedCols.length > 0) {
            getData();
        }
    }, [selectedCols])

    function selectedAwardIds() {
        return filters.find(filter => filter.statId === 769).value;
    }
    function shouldUseFilter(filterId) {
        let positionFilterableAwardIds = ['3', '4', '19', '22', '30'];
        return (filterId !== 770 || (selectedAwardIds()?.split(',') ?? []).every(awardId => positionFilterableAwardIds.includes(awardId)));
    }

    function getData() {
        setLoading(true);
        setData([]);
        let filterObj = filters.reduce(addFilterAsParameter, {});
        let filterStrings = filters
            .filter(filter => ![2, 395, 769, 770].includes(filter.statId) && shouldUseFilter(filter.statId))
            .reduce(addFilterForQueryTool, []);
        GetAwardsData(filterObj, selectedCols, awardIds, filterStrings)
            .then((value) => {
                setData(value);
                setLoading(false);
            });
    }

    function setFilter(filterIndex, type, value) {
        let newFilters = filters.slice();
        let filterToUpdate = newFilters.find(filter => filter.index === filterIndex)
        filterToUpdate[type] = value;
        setFilters(newFilters);
    }

    return (
        <div className='body awards'>
            <div className='advanced-filter'>
                {
                    filters?.filter(filter => shouldUseFilter(filter.statId))
                        .map((filter, filterIndex) =>
                            <StatFilter
                                filter={filter}
                                key={filterIndex}
                                people={allPlayers}
                                setFilter={setFilter}
                            />
                        )
                }
            </div>
            <Button style={{ margin: 'auto 0px auto auto' }} variant='success'
                onClick={() => getData()}
            >
                Submit
            </Button>
            <br />
            <br />

            {
                data && data.length > 0 &&
                <React.Fragment>
                    <ExcelExporter reportName={'Awards'} data={data} columns={selectedCols} />
                    <PDFExporter fileName='Awards' />
                    <br />
                    <br />
                </React.Fragment>
            }

            {
                loading &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }

            {
                data.exceptionMsg &&
                <React.Fragment>
                    <p style={{ color: 'rgb(220, 53, 69)', textAlign: 'center' }}>{data.exceptionMsg}</p>
                    <br />
                    <br />
                </React.Fragment>
            }

            <div className='pdfSection' style={{ width: data ? (selectedCols.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4 : '100%' }}>
                {
                    data && data.length > 0 &&
                    <div className='pdfChunk'>
                        <TabulatorTable
                            cols={selectedCols}
                            data={data}
                            paginationSize={25}
                            initSort={[{ column: 'Player', dir: 'asc' }]}
                            dataTreeElementColumn='Team'
                        />
                    </div>
                }
            </div>

            <ColumnSelector
                columns={columns}
                selectedColumns={selectedCols}
                groups={groups}
                setColumns={(columns) => {
                    setSelectedCols(columns);
                }}
            />
            <br />
        </div>
    )
}

export default Awards