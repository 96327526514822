import React, { useState, useEffect } from 'react';
import ColumnList from './ColumnList';
import { Button } from 'react-bootstrap';

function ColumnSelector(props) {
    let columns = [];
    for (let index = 0; index < props.columns.length; index++) {
        let tempCol = Object.assign({}, props.columns[index]);
        tempCol.index = index;
        columns.push(tempCol);
    }
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [highlightedCols, setHighlightedCols] = useState([]);

    useEffect(() => {
        let allSelectedColumns = [];
        props.selectedColumns.forEach(propsSelCol => {
            if (!propsSelCol.columns) {
                allSelectedColumns.push(propsSelCol);
                return;
            }
            propsSelCol.columns.forEach(propsNestedCol => {
                allSelectedColumns.push(propsNestedCol);
            });
        })
        setSelectedColumns(allSelectedColumns);
    }, [props.selectedColumns]);

    function clickColumn(col, event) {
        let tempHighlightedCols = highlightedCols.slice(0);
        if (tempHighlightedCols.some(hCol => hCol.inChosenList !== col.inChosenList)) {
            tempHighlightedCols = [];
        }
        if (event.ctrlKey) {
            let colIndex = tempHighlightedCols.findIndex(hCol => hCol.index === col.index);
            if (colIndex > -1) {
                tempHighlightedCols.splice(colIndex, 1);
            }
            else {
                tempHighlightedCols.push({
                    index: col.index,
                    colId: col.colId,
                    groupId: col.groupId,
                    inChosenList: col.inChosenList,
                });
            }
        }
        else if (event.shiftKey) {
            let initCol = tempHighlightedCols.slice(tempHighlightedCols.length - 1)[0];
            tempHighlightedCols = [];
            if (initCol === undefined) {
                tempHighlightedCols = [{
                    index: col.index,
                    colId: col.colId,
                    groupId: col.groupId,
                    inChosenList: col.inChosenList,
                }]
            }
            else if (initCol.inChosenList) {
                let lowerIndex = col.index < initCol.index ? col.index : initCol.index;
                let upperIndex = col.index > initCol.index ? col.index : initCol.index;
                for (let i = lowerIndex; i <= upperIndex; i++) {
                    tempHighlightedCols.push({
                        index: i,
                        colId: selectedColumns[i].id,
                        groupId: -1,
                        inChosenList: col.inChosenList,
                    });
                }
            }
            else if (initCol.groupId === col.groupId) {
                let lowerIndex = col.index < initCol.index ? col.index : initCol.index;
                let upperIndex = col.index > initCol.index ? col.index : initCol.index;
                for (let i = lowerIndex; i <= upperIndex; i++) {
                    tempHighlightedCols.push({
                        index: i,
                        colId: props.groups[col.groupId - 1].columns[i].id,
                        groupId: col.groupId,
                        inChosenList: col.inChosenList,
                    });
                }
            }
        }
        else {
            tempHighlightedCols = [{
                index: col.index,
                colId: col.colId,
                groupId: col.groupId,
                inChosenList: col.inChosenList,
            }]
        }
        setHighlightedCols(tempHighlightedCols);
    }

    function moveColumns(dir) {
        let cols = selectedColumns.slice(0);
        let tempHighlightedCols = highlightedCols.slice(0)
        let inChosenList = tempHighlightedCols.every(hCol => hCol.inChosenList === true);
        let notInChosenList = tempHighlightedCols.every(hCol => hCol.inChosenList === false);
        if (inChosenList) {
            switch (dir) {
                case 'up':
                    tempHighlightedCols = tempHighlightedCols.sort(function (a, b) {
                        return a.index - b.index;
                    });
                    if (tempHighlightedCols.every(c => c.index > 0)) {
                        tempHighlightedCols.forEach(hCol => {
                            let curColumn = cols[hCol.index];
                            cols[hCol.index] = cols[hCol.index - 1];
                            cols[hCol.index - 1] = curColumn;
                            hCol.index--;
                        })
                    }
                    break;
                case 'down':
                    tempHighlightedCols = tempHighlightedCols.sort(function (a, b) {
                        return b.index - a.index;
                    });
                    if (tempHighlightedCols.every(c => c.index < cols.length - 1)) {
                        tempHighlightedCols.forEach(hCol => {
                            let curColumn = cols[hCol.index];
                            cols[hCol.index] = cols[hCol.index + 1];
                            cols[hCol.index + 1] = curColumn;
                            hCol.index++;
                        })
                    }
                    break;
                case 'left':
                    tempHighlightedCols = tempHighlightedCols.sort(function (a, b) {
                        return b.index - a.index;
                    });
                    tempHighlightedCols.forEach(hCol => {
                        cols.splice(hCol.index, 1);
                    })
                    break;
                case 'break':
                    tempHighlightedCols.forEach(hCol => {
                        if (cols[hCol.index].cssClass.includes('break')) {
                            cols[hCol.index] = cols[hCol.index].toggleBreak(false);
                        }
                        else {
                            cols[hCol.index] = cols[hCol.index].toggleBreak(true);
                        }
                    })
                    break;
                default:
                    // Handle?
                    break;
            }
            if (dir === 'left') {
                tempHighlightedCols = [];
            }
        }
        else if (notInChosenList) {
            tempHighlightedCols = tempHighlightedCols.sort(function (a, b) {
                return a.index - b.index;
            });
            tempHighlightedCols.forEach(hCol => {
                if (dir === 'right') {
                    cols.push(columns.find(col => col.id === hCol.colId))
                }
            });
        }

        setSelectedColumns(cols);
        setHighlightedCols(tempHighlightedCols);
    }

    return (
        <React.Fragment>
            <h4>Select Stats and Order</h4>
            <div className='row'>
                <ColumnList
                    columns={columns}
                    highlightedCols={highlightedCols}
                    groups={props.groups}
                    inChosenList={false}
                    clickColumn={(index, colId, groupId, event) =>
                        clickColumn({ index: index, colId: colId, inChosenList: false, groupId: groupId }, event)
                    }
                />
                <div className='col-md-2'>
                    <img
                        width='49'
                        height='38'
                        type='button'
                        alt=''
                        className='col-selector-control'
                        src={'./images/AddStat.png'}
                        onClick={() => moveColumns('right')}>
                    </img>
                    <img
                        width='49'
                        height='38'
                        type='button'
                        alt=''
                        className='col-selector-control'
                        src={'./images/RemoveStat.png'}
                        onClick={() => moveColumns('left')}>
                    </img>
                </div>
                <ColumnList
                    columns={selectedColumns}
                    highlightedCols={highlightedCols}
                    groups={[{ id: -1, label: '', columns: selectedColumns.map(col => col) }]}
                    inChosenList={true}
                    clickColumn={(index, colId, groupId, event) =>
                        clickColumn({ index: index, colId: colId, groupId: groupId, inChosenList: true }, event)
                    }
                />
                <div className='col-md-2'>
                    {/* <img className='col-selector-control' src={'./images/TopArrow.png'}></img> */}
                    <img
                        width='49'
                        height='38'
                        type='button'
                        alt=''
                        className='col-selector-control'
                        src={'./images/StatUp.png'}
                        onClick={() => moveColumns('up')}>
                    </img>
                    <img
                        width='49'
                        height='38'
                        type='button'
                        alt=''
                        className='col-selector-control'
                        src={'./images/StatDown.png'}
                        onClick={() => moveColumns('down')}>
                    </img>
                    {/* <img className='col-selector-control' src={'./images/BottomArrow.png'}></img> */}
                    <img
                        width='49'
                        height='38'
                        type='button'
                        alt=''
                        className='col-selector-control'
                        src={'./images/BreakButton.png'}
                        onClick={() => moveColumns('break')}>
                    </img>
                </div>
            </div>
            <Button variant="success" onClick={() => props.setColumns(selectedColumns)}>Show</Button>
            <br />
        </React.Fragment>
    );
}

export default ColumnSelector;