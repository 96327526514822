import React from 'react';
import { Form } from 'react-bootstrap';

function RadioButton(props) {
    let optionalProps = {};

    if (props.disabled)
        optionalProps.disabled = true;
    if (props.checked)
        optionalProps.checked = true;
    if (props.inline)
        optionalProps.inline = true;

    return (
        <Form.Check
            key={props.key}
            type='radio'
            name={props.name}
            id={props.id}
            value={props.value}
            label={props.label}
            onClick={props.onClick}
            onChange={props.onChange}
            {...optionalProps}
        />
    )
}

export default RadioButton;