import React, { useState, useEffect } from 'react';
import TabulatorTable from '../Common/TabulatorTable';
import ExcelExporter from '../Common/ExcelExporter';
import {
    GetInjuries,
    GetTransactions,
    GetSeasonRosterData,
    GetRosterStatusSequence,
} from '../../Services/ApiSvc';
import { OverlayTrigger, Tooltip, Button, Row, Container, Col } from 'react-bootstrap';
import PDFExporter from '../Common/PDFExporter';
import SeasonDropDown from '../Common/SeasonDropDown';
let moment = require('moment');

function Transactions(props) {
    const [transactionCols, setTransactionCols] = useState([]);
    const [rosterCols, setRosterCols] = useState([]);
    const [injuryCols, setInjuryCols] = useState([]);
    const [data, setData] = useState({});
    const [activeSeasons, setActiveSeasons] = useState([]);
    const [platformSeason, setPlatformSeason] = useState(null);
    const [minSeason, setMinSeason] = useState(null);
    const [maxSeason, setMaxSeason] = useState(null);
    const [allInjuries, setAllInjuries] = useState([]);
    const [allTransactions, setAllTransactions] = useState([]);
    const [allSeasonRosterData, setAllSeasonRosterData] = useState([]);
    const [allRosterStatusSequence, setAllRosterStatusSequence] = useState([]);

    useEffect(() => {
        if (props.groups.length < 1) {
            return;
        }
        let transactionGroupCols = props.groups.find(group => group.id === 23).columns.slice();
        let rosterGroupCols = props.groups.find(group => group.id === 24).columns.slice();
        let injuryGroupCols = props.groups.find(group => group.id === 25).columns.slice();

        setTransactionCols(transactionGroupCols);
        setRosterCols(rosterGroupCols);
        setInjuryCols(injuryGroupCols);
    }, [])

    useEffect(() => {
        if (props.playerId > 0) {
            setMinSeason(null);
            setMaxSeason(null);

            const injuriesTask = GetInjuries(props.playerId);
            const transactionsTask = GetTransactions(props.playerId);
            const seasonsTask = GetSeasonRosterData(props.playerId);
            const rosterStatusSequencesTask = GetRosterStatusSequence(props.playerId);

            Promise.all([injuriesTask, transactionsTask, seasonsTask, rosterStatusSequencesTask])
                .then((values) => {
                    let rosterStatusSequences = values[3];

                    setAllInjuries(values[0]);
                    setAllTransactions(values[1]);
                    setAllSeasonRosterData(values[2].sort(function (a, b) { return a.Season - b.Season }));
                    setAllRosterStatusSequence(values[3]);

                    /**
                    * If a player is retired/unknown for an entire season or if they have a status of
                    * Not on 40-man for at least the three most recent years we don't want to display those years.
                    * Effectively not treating them as active players.
                    */
                    function GetActiveSeasons() {
                        let allSeasons = {};
                        rosterStatusSequences.forEach(rosterStatusSequence => {
                            if (!allSeasons[rosterStatusSequence.season]) {
                                allSeasons[rosterStatusSequence.season] = {
                                    inactive: false,
                                    isRetired: false,
                                    season: rosterStatusSequence.season,
                                };
                            }
                        });

                        Object.values(allSeasons).forEach(season => {
                            let rosterStatusSequencesBySeason = rosterStatusSequences
                                .filter(rosterStatusSequence => rosterStatusSequence.season === season.season);

                            if (rosterStatusSequencesBySeason.every(sequence => {
                                return ['Retired', 'Unknown'].includes(sequence.rosterStatusSpecific);
                            }))
                                season.isRetired = true;

                            if (rosterStatusSequencesBySeason.length < 1
                                || rosterStatusSequencesBySeason.every(sequence => {
                                    return ['Not on 40-man', 'Unknown'].includes(sequence.rosterStatusGeneral);
                                }))
                                season.inactive = true;
                        });

                        let seasonsInfo = Object.values(allSeasons)
                            .sort((a, b) => b.season - a.season);

                        let inactiveSeasons = seasonsInfo
                            .filter(seasonInfo => seasonInfo.isRetired)
                            .map(seasonInfo => seasonInfo.season);

                        if (inactiveSeasons.length < 1
                            || seasonsInfo.slice(0, 2).every(seasonInfo => seasonInfo.inactive)) {
                            inactiveSeasons = seasonsInfo
                                .slice(0, seasonsInfo.findIndex(seasonInfo => !seasonInfo.inactive))
                                .map(seasonInfo => seasonInfo.season)
                                ?? [];
                        }

                        return seasonsInfo
                            .filter(seasonInfo => !inactiveSeasons.includes(seasonInfo.season))
                            .map(seasonInfo => seasonInfo.season);
                    }
                    let activeSeasons = GetActiveSeasons();
                    setActiveSeasons(activeSeasons);
                    setMinSeason(Math.min(...activeSeasons));
                    setMaxSeason(Math.max(...activeSeasons));
                    setPlatformSeason(Math.max(...activeSeasons));
                });
        }
    }, [props.playerId])

    useEffect(() => {
        if (props.playerId > 0 && platformSeason !== null) {
            setData({});
            let dataObj = {
                injuryData: [],
                transactionData: [],
                rosterStatusData: [],
                rosterSummaryData: [],
            }

            let injuries = allInjuries.slice();
            let transactions = allTransactions.slice();
            let seasons = allSeasonRosterData.slice();
            let rosterStatusSequences = allRosterStatusSequence.slice();

            // Nest injuries with identical tickIds
            const nestedInjuries = injuries.reduce((acc, curInjury) => {
                const curInjuryDate = new Date(curInjury.DateInjured);
                const curInjuryYear = curInjuryDate.getFullYear();

                if (curInjuryYear <= platformSeason) {
                    if (!acc[curInjury.TickId])
                        acc[curInjury.TickId] = Object.assign({}, curInjury);
                    else if (!acc[curInjury.TickId]['_children'])
                        acc[curInjury.TickId]['_children'] = [curInjury];
                    else
                        acc[curInjury.TickId]['_children'].push(curInjury);
                }

                return acc;
            }, {});            
            
            dataObj.injuryData = Object.values(nestedInjuries);               

            // Nest transactions by calendar year
            let transactionDataBySeason = {};
            activeSeasons.forEach(activeSeason => {
                transactionDataBySeason[activeSeason] = {
                    Season: activeSeason,
                    _children: [],
                }
            })
            transactions.forEach(curTransaction => {
                curTransaction.Season = (new Date(curTransaction.TransactionDate)).getFullYear();

                if (!transactionDataBySeason[curTransaction.Season])
                    transactionDataBySeason[curTransaction.Season] = {
                        Season: curTransaction.Season,
                        _children: [],
                    };

                transactionDataBySeason[curTransaction.Season]._children.push(curTransaction);
            });

            dataObj.transactionData = Object.values(transactionDataBySeason)
                .filter(transactionSeason => transactionSeason.Season <= platformSeason)
                .map(transactionSeason => {
                    if (transactionSeason._children.length < 1)
                        delete transactionSeason._children
                    return transactionSeason;
                })
                .sort((a, b) => { return b.Season - a.Season });

            // Cumulate roster summary table data
            let rosterSummaryData = {};
            seasons
                .filter(season => activeSeasons.includes(season.Season) && season.Season <= platformSeason)
                .forEach(season => {
                rosterSummaryData[season.Season] = {
                    Season: season.Season,
                    OpenDay: season.RosterStatus,
                    EndOfSeason: season.EndOfSeasonRosterStatus ?? 'Unknown',
                    Days40: 0,
                    SeasonMLS: season.MLS,
                    CTDMLS: season.CTDMLS,
                    DaysAct: 0,
                    DaysIL: 0,
                    DaysOpt: 0,
                    Days: 0,
                    Burned: season.BurnedOption,
                    TimesSentDown: 0
                }
            })

            rosterStatusSequences.forEach(rosterStatusSequence => {
                if (!rosterSummaryData[rosterStatusSequence.season])
                    return;

                rosterSummaryData[rosterStatusSequence.season].Days40 += rosterStatusSequence.fortyMan;
                rosterSummaryData[rosterStatusSequence.season].DaysAct += rosterStatusSequence.activeRoster;
                rosterSummaryData[rosterStatusSequence.season].DaysIL += rosterStatusSequence.ilDay;
                rosterSummaryData[rosterStatusSequence.season].DaysOpt += rosterStatusSequence.optionDay;
                rosterSummaryData[rosterStatusSequence.season].Days += rosterStatusSequence.days;
                rosterSummaryData[rosterStatusSequence.season].TimesSentDown += rosterStatusSequence.timesSentDown;
            });
            dataObj.rosterSummaryData = Object.values(rosterSummaryData)
                .sort((a, b) => { return b.Season - a.Season });

            // Cumulate roster graph data
            let rosterGraphData = {};
            rosterStatusSequences
                .filter(rosterStatusSequence => activeSeasons.includes(rosterStatusSequence.season) && rosterStatusSequence.season <= platformSeason)
                .forEach(rosterStatusSequence => {
                    if (!rosterGraphData[rosterStatusSequence.season])
                        rosterGraphData[rosterStatusSequence.season] = {
                            season: rosterStatusSequence.season,
                            days: 0,
                            rosterStatuses: [],
                        };

                    rosterGraphData[rosterStatusSequence.season].days += rosterStatusSequence.days;

                    if (RosterStatusGeneralHasNotChanged()) {
                        let curRosterStatus = rosterGraphData[rosterStatusSequence.season]
                            .rosterStatuses[rosterGraphData[rosterStatusSequence.season].rosterStatuses.length - 1];
                        curRosterStatus.days += rosterStatusSequence.days;
                        curRosterStatus.teamGames += rosterStatusSequence.teamGames;
                        curRosterStatus.toolTip.endDate = rosterStatusSequence.endDate;
                    } else {
                        rosterGraphData[rosterStatusSequence.season].rosterStatuses.push({
                            rosterStatus: rosterStatusSequence.rosterStatusGeneral,
                            days: rosterStatusSequence.days,
                            teamGames: rosterStatusSequence.teamGames,
                            toolTip: {
                                startDate: rosterStatusSequence.startDate,
                                endDate: rosterStatusSequence.endDate,
                            }
                        })
                    }

                    function RosterStatusGeneralHasNotChanged() {
                        return rosterGraphData[rosterStatusSequence.season].rosterStatuses.length > 0
                            && rosterGraphData[rosterStatusSequence.season]
                            .rosterStatuses[rosterGraphData[rosterStatusSequence.season].rosterStatuses.length - 1]
                                .rosterStatus === rosterStatusSequence.rosterStatusGeneral;
                    }
                });
            dataObj.rosterStatusData = Object.values(rosterGraphData)
                .map(rosterSeason => {
                    if (rosterSeason.season === 2020) {
                        rosterSeason.rosterStatuses.unshift({ rosterStatus: 'No Games', days: 119 });
                        rosterSeason.days += 119;
                    }
                    return rosterSeason;
                })
                .sort((a, b) => b.season - a.season);

            setData(dataObj);
        }
    }, [platformSeason, allInjuries, allTransactions, allSeasonRosterData, allRosterStatusSequence, minSeason, maxSeason]);

    return (
        <React.Fragment>
            <div>
                <div className='row pdfChunk pdfIgnore'>
                    <div style={{ padding: '0px 0px 0px 0px' }} />
                    {
                        props.playerId > 0 && minSeason !== null && maxSeason !== null &&
                        <SeasonDropDown
                            label={'Show Transaction/Roster Data Through'}
                            minSeason={minSeason}
                            maxSeason={maxSeason}
                            selectedSeason={maxSeason}
                            setSeason={setPlatformSeason}
                        />
                    }
                    <div style={{ margin: '0px 0px 0px auto' }}>
                        <PDFExporter fileName='Transactions' />
                    </div>
                </div>
                {
                    data.transactionData !== undefined && data.transactionData.length > 0 &&
                    <div className='pdfChunk'>
                        <Row>
                            <h3>Transactions</h3>
                            <ExcelExporter
                                reportName={'Transactions'}
                                data={data.transactionData}
                                columns={transactionCols}
                                style={{
                                    height: '50px',
                                    width: '50px',
                                    border: '1px solid grey',
                                    borderRadius: '5px',
                                    margin: '10px'
                                }}
                            />
                        </Row>
                        <div
                            style={{
                                width: data.transactionData
                                    ? (transactionCols.reduce((acc, cur) => acc + ((cur.width) > 20
                                        ? (cur.width)
                                        : 20), 0)) + 4
                                    : '100%'
                            }}>
                            <TabulatorTable
                                cols={transactionCols}
                                data={data.transactionData}
                                dataTreeElementColumn='Season'
                                initSort={[{ column: 'Season', dir: 'desc' }]}
                            />
                        </div>
                        <br />
                    </div>
                }
                {
                    data.rosterSummaryData !== undefined && data.rosterSummaryData.length > 0 &&
                    <div className='pdfChunk'>
                        <Row>
                            <h3>Roster Summary</h3>
                            <ExcelExporter
                                reportName={'RosterSummary'}
                                data={data.rosterSummaryData}
                                columns={rosterCols}
                                style={{
                                    height: '50px',
                                    width: '50px',
                                    border: '1px solid grey',
                                    borderRadius: '5px',
                                    margin: '10px'
                                }}
                            />
                        </Row>
                        <div
                            style={{
                                width: data.rosterSummaryData
                                    ? (rosterCols.reduce((acc, cur) => acc + ((cur.width) > 20
                                        ? (cur.width)
                                        : 20), 0)) + 4
                                    : '100%'
                            }}>
                            <TabulatorTable cols={rosterCols} data={data.rosterSummaryData} />
                            {
                                // TOP 12/10/2020 - Only show in certain cases which will be determined by Bob/Michael
                                false && <p>Note: Current year values are unofficial</p>
                            }
                        </div>
                        <br />
                    </div>
                }
                {
                    data.rosterStatusData !== undefined && data.rosterStatusData.length > 0 &&
                    <div className='pdfChunk'>
                        <div className='transaction-graph'>
                            <TransactionGraph data={data.rosterStatusData} />
                        </div>
                        <br />
                    </div>
                }
                {
                    data.injuryData !== undefined && data.injuryData.length > 0 &&
                    <div className='pdfChunk'>
                        <Row>
                            <h3>Injury Details</h3>
                            <ExcelExporter
                                reportName={'Injuries'}
                                data={data.injuryData}
                                columns={injuryCols}
                                style={{
                                    height: '50px',
                                    width: '50px',
                                    border: '1px solid grey',
                                    borderRadius: '5px',
                                    margin: '10px'
                                }}
                            />
                        </Row>
                        <div style={{
                            width: data.injuryData
                                ? (injuryCols.reduce((acc, cur) => acc + ((cur.width) > 20
                                    ? (cur.width)
                                    : 20), 0)) + 4
                                : '100%'
                        }}>
                            <TabulatorTable
                                cols={injuryCols}
                                data={data.injuryData}
                                paginationSize={25}
                                initSort={[{ column: 'DateInjured', dir: 'desc' }]}
                                dataTreeElementColumn='DateInjured'
                            />
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}

export default Transactions;

function TransactionGraph(props) {
    let seasons = props.data;
    const [graphSeasons, setGraphSeasons] = useState(3);

    seasons.sort(function (a, b) {
        return a.Season - b.Season;
    });

    let transactionBars = [];

    seasons.forEach(s => {
        let bar = [];
        s.rosterStatuses.forEach(rs => {
            if (s.rosterStatuses.length === 1) {
                bar.push(
                    <TransactionSlice
                        curStatus={rs.rosterStatus}
                        statusDays={rs.days}
                        statusPercent={100}
                        teamGames={rs.teamGames}
                        toolTip={rs.toolTip}
                        only={true}
                        border={true}
                        season={s.season}
                    />
                );
            }
            else if (rs === s.rosterStatuses[0]) {
                bar.push(
                    <TransactionSlice
                        curStatus={rs.rosterStatus}
                        statusDays={rs.days}
                        statusPercent={100 * rs.days / s.days}
                        teamGames={rs.teamGames}
                        toolTip={rs.toolTip}
                        first={true}
                        border={true}
                        season={s.season}
                    />
                );
            }
            else if (rs === s.rosterStatuses[s.rosterStatuses.length - 1]) {
                bar.push(
                    <TransactionSlice
                        curStatus={rs.rosterStatus}
                        statusDays={rs.days}
                        statusPercent={100 * rs.days / s.days}
                        teamGames={rs.teamGames}
                        toolTip={rs.toolTip}
                        last={true}
                        season={s.season}
                    />
                );
            }
            else {
                bar.push(
                    <TransactionSlice
                        curStatus={rs.rosterStatus}
                        statusDays={rs.days}
                        statusPercent={100 * rs.days / s.days}
                        teamGames={rs.teamGames}
                        toolTip={rs.toolTip}
                        border={true}
                        season={s.season}
                    />
                );
            }
        })

        transactionBars.push(
            <React.Fragment key={s.season}>
                <div className='row'>
                    <div className='col-md-1'>
                        {s.season}
                    </div>
                    <div className='col-md-11 transaction-bar-container'>
                        <Container>
                            <Row className='transaction-bar-row'>
                                <Col className='transaction-bar-col text-right'>40</Col>
                                <Col className='transaction-bar-col text-right'>81</Col>
                                <Col className='transaction-bar-col text-right'>120</Col>
                                <Col className='transaction-bar-col text-right'></Col>
                            </Row>
                        </Container>
                        <div className='transaction-bar'>
                            {bar}
                        </div>
                    </div>
                </div>
                <br />
            </React.Fragment>
        )
    })

    let width = 0;
    if (window.document.getElementsByClassName('transaction-bar').length > 0) {
        width = window.document.getElementsByClassName('transaction-bar')[0].clientWidth;
    }
    if (width > 0) {

    }

    let transactionBarsHtml = [];
    for (var i = 0; i < graphSeasons; i++) {
        transactionBarsHtml.push(transactionBars[i]);
    }

    return (
        <React.Fragment>
            <div className='row'>
                <div className='col-md-1'></div>
                <div className='col-md-11'>
                    <div className='row month-bar'>
                        <div className='col'>March/April</div>
                        <div className='col'>May</div>
                        <div className='col'>June</div>
                        <div className='col'>July</div>
                        <div className='col'>August</div>
                        <div className='col'>Sept/Oct</div>
                    </div>
                </div>
            </div>
            <br />
            {
                transactionBars.length > 0 &&
                transactionBarsHtml
            }
            <div className='row justify-content-md-center'>
                {
                    transactionBars.length > graphSeasons &&
                    <Button
                        type='button'
                        variant='success'
                        onClick={() => setGraphSeasons(graphSeasons + 1)}
                    >
                        Add Season
                    </Button>
                }
                {
                    graphSeasons > 3 &&
                    <Button
                        type='button'
                        variant='danger'
                        onClick={() => setGraphSeasons(graphSeasons - 1)}
                    >
                        Remove Season
                    </Button>
                }
            </div>
        </React.Fragment>
    );
}

function TransactionSlice(props) {
    let className = `${props.curStatus.toLowerCase().replace(/\s/g, '-')}-bar-status bar-status`;
    if (props.only) {
        className += ' bar-status-only';
    }
    if (props.border) {
        className += ' bar-status-border';
    }
    if (props.first) {
        className += ' bar-status-first';
    }
    else if (props.last) {
        className += ' bar-status-last';
    }

    return (
        <OverlayTrigger overlay={
            <Tooltip>
                {
                    props.toolTip &&
                    <React.Fragment>
                        <p>
                            Start Date: {moment(props.toolTip.startDate).format('MMMM D, YYYY')}
                        </p>
                        <p>
                            End Date: {moment(props.toolTip.endDate).format('MMMM D, YYYY')}
                        </p>
                    </React.Fragment>
                }
                {
                    props.statusPercent < 4 &&
                    <React.Fragment>
                        <p>{props.curStatus}</p>
                        <p>{props.statusDays} day(s)</p>
                        {props.season >= 2018 && props.teamGames != null && props.teamGames !== undefined && <p>{props.teamGames} Tm Gm(s)</p>}
                    </React.Fragment>
                }
            </Tooltip>
        }>
            <div className={className} style={{ width: `${props.statusPercent}%` }}>
                <div>
                    {
                        props.statusPercent < 4 &&
                        <p>...</p>
                    }
                    {
                        props.statusPercent >= 4 &&
                        <React.Fragment>
                            <p>{props.curStatus}</p>
                            <p>{props.statusDays} days</p>
                            {props.season >= 2018 && props.teamGames != null && props.teamGames !== undefined && <p>{props.teamGames} Tm Gms</p>}
                        </React.Fragment>
                    }
                </div>
            </div>
        </OverlayTrigger>
    )
}