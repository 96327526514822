import React, { useState, useEffect } from 'react';
import ColumnGroup from './ColumnGroup';

function ColumnList(props) {
    const highlightedCols = props.highlightedCols;
    const [searchString, setSearchString] = useState('');
    const [list, setList] = useState([]);

    useEffect(() => {
        // Create items for each column in each group. The item holds the html.
        let totalIndex = 0;
        let groups = props.groups.map((group) => {
            group.items = []
            if (group.columns.length < 1) {
                return group;
            }
            group.columns.forEach((curColumn, index) => {
                let column = Object.assign({}, curColumn);
                let liClass = group.id > -1 ? 'col-item col-group-item' : 'col-item';
                if (highlightedCols && highlightedCols
                    .some(hCol => hCol.index === index && hCol.groupId === group.id)) {
                    liClass = liClass + ' sel-col'
                }
                if (column.cssClass && column.cssClass.includes('break') && group.id === -1) {
                    group.items.push({
                        key: totalIndex + index,
                        title: column.title,
                        html:
                            <li
                                key={totalIndex + index}
                                className={liClass}
                                onClick={
                                    (event) => {
                                        clickColumn(index, column.id, group.id, event)
                                    }
                                }
                            >
                                <p>{column.title}<br /><i>Line Break</i></p>
                            </li>
                    });
                }
                else {
                    group.items.push({
                        key: totalIndex + index,
                        title: column.title,
                        html:
                            <li
                                key={totalIndex + index}
                                className={liClass}
                                onClick={
                                    (event) => {
                                        clickColumn(index, column.id, group.id, event)
                                    }
                                }
                            >
                                <p>{column.title}</p>
                            </li>
                    });
                }
            });
            totalIndex += group.columns.length;
            return group;
        });

        // Display groups based on whether a column exists in the group that contains the search string.
        let list = groups.map((group, index) => {
            if (group.items.length > 0
                && !!~group.items.findIndex(item => item.title.toLowerCase().includes(searchString.toLowerCase()))) {
                return (
                    <ColumnGroup
                        label={group.label}
                        items={group.items
                            .filter(item => item.title.toLowerCase().includes(searchString.toLowerCase()))
                            .map(item => { return item.html })
                        }
                        key={index}
                        showItems={searchString !== '' || group.id === -1}
                    />
                )
            }
        })
        setList(list);
    }, [searchString, props.groups, props.highlightedCols]);

    function clickColumn(index, colId, groupId, event) {
        props.clickColumn(index, colId, groupId, event);
    };

    return (
        <div className='col-md-3'>
            <ul className='list-table'>
                {
                    !props.inChosenList &&
                    <li style={{ paddingTop: '5px' }} key='-1'>
                        <input style={{ width: '100%', borderRadius: '30px', paddingLeft: '10px' }} placeholder='Search...' value={searchString} onChange={(event) => setSearchString(event.target.value)} />
                    </li>
                }
                {list}
            </ul>
        </div>
    );
}

export default ColumnList;