import React, { useState } from 'react';

function ColumnGroup(props) {
    const [showItems, setShowItems] = useState(false);

    return (
        <React.Fragment>
            {
                props.label &&
                <li className='col-group' tabIndex='0' onClick={() => setShowItems(!showItems)}>
                    {
                        !showItems &&
                        <img width='15' height='15' src={'./images/Plus.png'} alt='' />
                    }
                    {
                        showItems &&
                        <img width='15' height='15' src={'./images/Minus.png'} alt='' />
                    }
                    {props.label}
                </li>
            }
            {
                (showItems || props.showItems) &&
                props.items
            }
        </React.Fragment>
    )
}

export default ColumnGroup;