import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';
import CloseButton from './CloseButton';
import { dragAndDropFunctions } from './Functions';

function DraggableFilter(props) {
    return (
        <Badge
            className={`draggable ${props.className}`}
            id={props.id}
            statid={props.statId}
            granularity={props.granularity}
            style={{ margin: '5px' }}
            variant='secondary'
            draggable='true'
            onDragStart={(ev) => dragAndDropFunctions.startDrag(ev, props.className)}
            onDragEnd={(ev) => dragAndDropFunctions.endDrag(ev)}
            filter={props.filter}
        >
            {props.filterText}
            <CloseButton />
        </Badge>
    )
}

export default DraggableFilter;