import React, { useState, useEffect } from 'react';
import { Form, Badge } from 'react-bootstrap';

export function TeamSelector(props) {
    const [teams, setTeams] = useState(['0']);

    useEffect(() => {
        props.setTeams(teams);
    }, [teams])

    function addTeam(team) {
        if (!teams.includes(team)) {
            let curTeams = teams.slice();
            if (team !== '0') {
                curTeams.push(team);
                if (curTeams.length > 1 && curTeams.includes('0')) {
                    curTeams.splice(curTeams.indexOf('0'), 1);
                }
                setTeams(curTeams);
            }
            else {
                setTeams(['0']);
            }
        }
    };

    function remTeam(team) {
        let curTeams = teams.slice();
        if (curTeams.includes(team)) {
            curTeams.splice(curTeams.indexOf(team), 1);
        }
        setTeams(curTeams);
    }

    let selectedTeams = [];
    for (let i = 0; i < teams.length; i++) {
        if (teams[i] !== '0') {
            selectedTeams.push(
                <Badge key={teams[i]} variant='secondary'>{teamAbbr[teams[i]]}<span type='button' onClick={() => remTeam(teams[i])}>X</span></Badge>
            )
        }
        else {
            selectedTeams.push(
                <Badge key={teams[i]} variant='secondary'>{teamAbbr[teams[i]]}</Badge>
            )

        }
    };

    return (
        <React.Fragment>
            <Form.Control style={{ width: '100px' }} as='select' onChange={(event) => addTeam(event.target.value)}>
                <option key='0' value='0'>All</option>
                <option key='15' value='15'>ARI</option>
                <option key='16' value='16'>ATL</option>
                <option key='2' value='2'>BAL</option>
                <option key='3' value='3'>BOS</option>
                <option key='4' value='4'>CHA</option>
                <option key='17' value='17'>CHN</option>
                <option key='18' value='18'>CIN</option>
                <option key='19' value='19'>COL</option>
                <option key='5' value='5'>CLE</option>
                <option key='6' value='6'>DET</option>
                <option key='21' value='21'>HOU</option>
                <option key='7' value='7'>KC</option>
                <option key='1' value='1'>LAA</option>
                <option key='22' value='22'>LAN</option>
                <option key='20' value='20'>MIA</option>
                <option key='23' value='23'>MIL</option>
                <option key='8' value='8'>MIN</option>
                <option key='9' value='9'>NYA</option>
                <option key='25' value='25'>NYN</option>
                <option key='10' value='10'>OAK</option>
                <option key='26' value='26'>PHI</option>
                <option key='27' value='27'>PIT</option>
                <option key='29' value='29'>SD</option>
                <option key='11' value='11'>SEA</option>
                <option key='30' value='30'>SF</option>
                <option key='28' value='28'>STL</option>
                <option key='12' value='12'>TB</option>
                <option key='13' value='13'>TEX</option>
                <option key='14' value='14'>TOR</option>
                <option key='24' value='24'>WAS</option>
            </Form.Control>
            <div>
                {
                    selectedTeams.length > 0 &&
                    selectedTeams
                }
            </div>
        </React.Fragment>
    )
}

let teamAbbr = [
    'All',
    'LAA',
    'BAL',
    'BOS',
    'CHA',
    'CLE',
    'DET',
    'KC',
    'MIN',
    'NYA',
    'OAK',
    'SEA',
    'TB',
    'TEX',
    'TOR',
    'ARI',
    'ATL',
    'CHN',
    'CIN',
    'COL',
    'MIA',
    'HOU',
    'LAN',
    'MIL',
    'WAS',
    'NYN',
    'PHI',
    'PIT',
    'STL',
    'SD',
    'SF',
]