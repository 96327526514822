import React, { useState, useEffect } from 'react';
import { Button, Row } from 'react-bootstrap';
import DatePicker from '../Common/DatePicker';
import { columnManager } from '../Common/ColumnManager';
import StatFilter, { convertFilterToNote, createFilter } from '../Common/StatFilter';
import { GetRecentSigningsReport } from '../../Services/ApiSvc';
import TabulatorTable from '../Common/TabulatorTable';
import ExcelExporter from '../Common/ExcelExporter';
import PDFExporter from '../Common/PDFExporter';
import { customDateString } from '../Common/CommonFunctions';

function RecentSignings() {
    const [columnGroups, setColumnGroups] = useState(null);
    const [data, setData] = useState({
        columns: null,
        exceptionMsg: null,
        loading: false,
        rows: null,
    });
    const [excelNotes, setExcelNotes] = useState([]);

    useEffect(() => {
        (async function Initialize() {
            let groupObj = {
                displayStats: [],
                filters: [],
            };

            let colGroups = await columnManager.getAllGroupsBySection('RecentSignings');

            groupObj.displayStats = colGroups.find(group => group.label === 'RecentSigningsReport').columns;
            groupObj.filters = colGroups.find(group => group.label === 'RecentSigningsReportFilters').columns;

            setColumnGroups(groupObj);
        })()
    }, []);

    async function getData(formOptions) {
        formOptions.displayStats = columnGroups.displayStats.map(stat => stat.id);
        setData({
            columns: null,
            exceptionMsg: null,
            loading: true,
            rows: null,
        });
        updateNotes(formOptions);
        let response = await GetRecentSigningsReport(formOptions);
        setData({
            columns: columnGroups.displayStats.map(stat => {
                let column = Object.assign({}, stat);
                column.field = response.columnMap[stat.field].toString();
                return column;
            }),
            exceptionMsg: response.exceptionMsg,
            loading: false,
            rows: response.rows,
        });
    }

    function updateNotes(formOptions) {
        let notes = {
            formNotes: [],
        };

        if (formOptions) {
            notes.formNotes.push(`From: ${customDateString(formOptions.startDate)}`);
            notes.formNotes.push(`To: ${customDateString(formOptions.endDate)}`);

            formOptions.filters.forEach(filter => {
                if (filter.value) {
                    let filterNote = convertFilterToNote(filter);
                    if (filterNote)
                        notes.formNotes.push(filterNote);
                }
            });
        }

        setExcelNotes(notes);
    }

    return (
        <div className='body recent-signings pdfSection'>
            <div className='pdfChunk'>
                <h2>Recent Signings</h2>
                <br />
                <RecentSigningsForm
                    filters={columnGroups?.filters}
                    submit={getData}
                    exporters={
                        data.rows &&
                        <React.Fragment>
                            <ExcelExporter
                                columns={data.columns}
                                data={data.rows}
                                notes={excelNotes}
                                reportName={'RecentSignings'}
                            />
                            <PDFExporter fileName='RecentSigningsReport' />
                        </React.Fragment>
                    }
                />
                <br />
            </div>
            {
                data?.loading &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }
            {
                data?.rows &&
                <div
                    className='pdfChunk'
                    style={{
                        width: (data.columns.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                    }}
                >
                    <TabulatorTable
                        cols={data.columns}
                        data={data.rows}
                        initSort={[{
                            column: data.columns?.find(col => col.title === 'Signing Date').field,
                            dir: 'desc'
                        }]}
                    />
                    <br />
                </div>
            }
            {
                data?.exceptionMsg &&
                <React.Fragment>
                    <p style={{ color: 'rgb(220, 53, 69)', textAlign: 'center' }}>{data.exceptionMsg}</p>
                    <br />
                    <br />
                </React.Fragment>
            }
        </div>
    );
}

export default RecentSignings;


function RecentSigningsForm(props) {
    let today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 30);
    const [startDate, setStartDate] = useState(yesterday);
    const [endDate, setEndDate] = useState(today);
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        if (props.filters == null)
            return;

        let myFilters = [];

        props.filters
            .forEach((column, filterIndex) => {
                let filter = Object.assign({}, createFilter(filterIndex, column));
                myFilters.push(filter);
            });

        setFilters(myFilters);
    }, [props.filters]);

    function setFilter(filterIndex, type, value) {
        let newFilters = filters.slice();
        let filterToUpdate = newFilters.find(filter => filter.index === filterIndex)
        filterToUpdate[type] = value;
        setFilters(newFilters);
    }

    function submitForm() {
        let formOptions = {
            endDate: endDate,
            filters: filters,
            startDate: startDate,
        };

        props.submit(formOptions);
    }

    return (
        <React.Fragment>
            <Row>
                <b className='label' style={{ paddingRight: '25px' }}>Signing Date From: </b>
                <DatePicker
                    maxDate={endDate}
                    setDate={(date) => setStartDate(date)}
                    startDate={startDate}
                />
                <b classname='label' style={{ padding: '0px 25px' }}>Signing Date To: </b>
                <DatePicker
                    endDate={endDate}
                    maxDate={today}
                    minDate={startDate}
                    setDate={(date) => setEndDate(date)}
                />
            </Row>

            <br />
            <h5>Advanced Filters</h5>
            <div className='advanced-filter'>
                {
                    filters.map(filter =>
                        <StatFilter
                            filter={filter}
                            setFilter={setFilter}
                        />
                    )
                }
            </div>

            <br />
            <Row className='pdfIgnore'>
                <Button variant='success'
                    onClick={submitForm}
                >
                    Submit
                </Button>
                <div style={{ margin: '0px 0px 0px auto' }}>
                    {props.exporters}
                </div>
            </Row>
        </React.Fragment>
    );
}