import React, { useState, useEffect } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import ColumnGroup from './ColumnGroup';

/**
 * Pass in items and groups(optional) to get a filterable list of items
 * dependent on the search string.
 * @param {object} props.items list of objects with:
 *      a unique key property which will be the value returned by clicking the item
 *      a label property used to display in html describing the item
 *      (optional) a groupId to associate this item with a group
 * @param {object} props.groups list of objects with a unique id property and label
 */
function AutoComplete(props) {
    let groups = props.groups?.length > 1 ? props.groups : [{ id: -1, label: '' }];
    const [showList, setshowList] = useState('none');
    const [searchString, setSearchString] = useState('');
    const [list, setList] = useState(null);

    useEffect(() => {
        if (props.value !== undefined && searchString !== props.value) {
            setSearchString(props.value);
        }
    }, [props.value])

    useEffect(() => {
        groups = groups.map((group) => {
            group.items = props.items
                .flat()
                .filter(item => item.groupId === group.id || group.id === -1)
                .map(item => {
                    return {
                        key: item.key,
                        title: item.title,
                        html:
                            <li key={item.key} className='autocomplete-item' tabIndex='0' onClick=
                                {
                                    e => {
                                        setSearchString(props.clearAfterSelection ? '' : item.title);
                                        props.onSelect(item.key);
                                        setshowList('none');
                                    }
                                } >
                                {item.title}
                                {
                                    item.posType &&
                                    <span style={{ color: 'grey', marginLeft: '5px' }}>
                                        {item.posType}
                                    </span>
                                }
                            </li>
                    }
                })
            if (props.sortItemsAlphabetical) {
                group.items.sort((a, b) => {
                    if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
                    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                    return 0;
                })
            }
            return group;
        });

        let list = groups.map((group, index) => {
            if (!!~group.items.findIndex(item => item.title.toLowerCase().includes(searchString.toLowerCase()))) {
                return (
                    <ColumnGroup
                        label={group.label}
                        items={group.items
                            .filter(item => item.title.toLowerCase().includes(searchString.toLowerCase()))
                            .slice(0, 250)
                            .map(item => { return item.html })
                        }
                        key={index}
                        showItems={searchString !== '' || group.id === -1}
                    />
                )
            }
        })
        setList(list);
    }, [searchString, props.items, props.groups]);

    //if (props.dropUp) {
    //    let autoCompLists = document.getElementsByClassName('autocomplete-list')
    //    if (autoCompLists.length > 0) {
    //        [].forEach.call(autoCompLists, function (acl) {
    //            // if acl is closer to bottom of screen
    //            if (window.innerHeight - acl.parentElement.getBoundingClientRect().bottom < window.innerHeight / 2) {
    //                //acl.style.maxHeight = `${acl.parentElement.getBoundingClientRect().top - 50}px`;
    //                acl.style.bottom = '14px';
    //            }
    //            else {
    //                acl.style.bottom = '';
    //                //acl.style.maxHeight = `${window.innerHeight - acl.parentElement.getBoundingClientRect().bottom - 50}px`;
    //            }
    //        })
    //    }
    //}

    return (
        <React.Fragment>
            {
                props.label &&
                <div style={{ fontWeight: '700' }} className='label'>{props.label}: </div>
            }
            <div onFocus={() => setshowList('block')} onBlur=
                {
                    event => {
                        if (event.relatedTarget) {
                            let att = event.relatedTarget.getAttribute('class') ?? '';
                            let parAtt = event.relatedTarget.parentElement.getAttribute('class') ?? '';
                            // If the item clicked is not part of the autocomplete list then hide the list
                            if (!((att.includes('col-group') || att.includes('autocomplete')) && parAtt.includes('autocomplete'))) {
                                setshowList('none');
                            }
                        }
                        else {
                            setshowList('none');
                        }
                    }
                }>
                <InputGroup>
                    <FormControl
                        value={searchString}
                        onChange={(event) => setSearchString(event.target.value)}
                        className='autocomplete'
                        aria-describedby='basic-addon1'
                        placeholder={props.placeholder}
                    />
                    {
                        props.addOn &&
                        <InputGroup.Text id='basic-addon1'>{String(props.addOn)}</InputGroup.Text>
                    }
                </InputGroup>
                <ul
                    className='autocomplete-list autocomplete'
                    style={{ display: showList }}
                >
                    {list}
                </ul>
            </div>
        </React.Fragment>
    );
}

export default AutoComplete;