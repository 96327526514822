import React, { useState, useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import DatePicker from './DatePicker';
import { MonthSelector } from '../Common/MonthSelector';
import RadioButton from './RadioButton';
import SeasonDropDown from './SeasonDropDown';
import { customDateString } from './CommonFunctions';

function TimeFilter(props) {

    function canScaleUp() {
        if (props.disallowScaleUp)
            return false;

        switch (props.filter.mode) {
            case 'single':
                return props.filter.season === '2020';
            case 'cumulative':
            case 'separate':
                return props.filter.startSeason <= 2020 && props.filter.endSeason >= 2020;
            case 'date':
                return props.filter.startDate <= new Date('7/23/2020') && props.filter.endDate >= new Date('9/27/2020');
            default:
                return false;
        }
    }

    function isPartialScaledUpSeason() {
        return props.filter.mode === 'date' && (
            (props.filter.startDate >= new Date('7/23/2020') && props.filter.startDate <= new Date('9/27/2020')) ||
            (props.filter.endDate >= new Date('7/23/2020') && props.filter.endDate <= new Date('9/27/2020'))
        );
    }

    const monthSelector = <MonthSelector
        setMonth={(value) => {
            let filterState = Object.assign({}, props.filter);
            filterState.month = value;
            props.setFilter(filterState);
        }}
    />

    const prePostAllStartSelector = <>
        <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>1st Half/2nd Half: </Form.Label>
        <Form.Control
            as='select'
            defaultValue={props.filter.half}
            onChange={(event) => {
                let filterState = Object.assign({}, props.filter);
                filterState.half = event.target.value;
                props.setFilter(filterState);
            }}
            style={{ width: '187px' }}
        >
            <option key='N/A' value='-1'>N/A</option>
            <option key='Pre-All Star Game' value='0'>Pre-All Star Game</option>
            <option key='Post-All Star Game' value='1'>Post-All Star Game</option>
        </Form.Control>
    </>

    return (
        <>
            {
                props.modes.length > 1 &&
                <>
                    <div className='row'>
                        <b className='label'>Mode: </b>
                        <Form>
                            <div key={'custom-inline-radio'}>
                                {props.modes.map((mode, modeIndex) => {
                                    return <RadioButton
                                        checked={props.filter.mode === modeProps[mode].value}
                                        id={modeProps[mode].id}
                                        inline={modeProps[mode].inline}
                                        key={modeIndex}
                                        label={modeProps[mode].label}
                                        name={modeProps[mode].name}
                                        onChange={(event) => {
                                            let filterState = Object.assign({}, props.filter);
                                            filterState.mode = event.target.value;
                                            props.setFilter(filterState);
                                        }}
                                        value={modeProps[mode].value}
                                    />;
                                })}
                            </div>
                        </Form>
                    </div>
                    <br />
                </>
            }
            <div className='row'>
                {
                    props.filter.mode === 'single' &&
                    <React.Fragment>
                        <SeasonDropDown
                            label='Season'
                            maxSeason={props.maxSeason}
                            minSeason={props.minSeason ?? 2002}
                            selectedSeason={props.filter.season}
                            setSeason={(value) => {
                                let filterState = Object.assign({}, props.filter);
                                filterState.season = value;
                                props.setFilter(filterState);
                            }}
                        />
                        {props.includeMonthSelector && monthSelector}
                        {props.includePrePostAllStarSelector && prePostAllStartSelector}
                    </React.Fragment>
                }
                {
                    ['cumulative', 'separate'].includes(props.filter.mode) &&
                    <React.Fragment>
                        <SeasonDropDown
                            label='Start Season'
                            maxSeason={props.filter.endSeason ?? props.maxSeason}
                            minSeason={props.minSeason ?? 2002}
                            selectedSeason={props.filter.startSeason}
                            setSeason={(value) => {
                                let filterState = Object.assign({}, props.filter);
                                filterState.startSeason = value;
                                props.setFilter(filterState);
                            }}
                        />
                        <SeasonDropDown
                            label='End Season'
                            maxSeason={props.maxSeason}
                            minSeason={props.filter.startSeason ?? props.minSeason ?? 2002}
                            selectedSeason={props.filter.endSeason}
                            setSeason={(value) => {
                                let filterState = Object.assign({}, props.filter);
                                filterState.endSeason = value;
                                props.setFilter(filterState);
                            }}
                        />
                        {props.includeMonthSelector && monthSelector}
                        {props.includePrePostAllStarSelector && prePostAllStartSelector}
                    </React.Fragment>
                }
                {
                    props.filter.mode === 'date' &&
                    <React.Fragment>
                        <b className='label' style={{ paddingRight: '25px' }}>From: </b>
                        <input
                            max={props.filter.endDate ?? `${props.maxSeason}-12-31`}
                            onChange={(event) => {
                                if (event.target.value) {
                                    let filterState = Object.assign({}, props.filter);
                                    filterState.startDate = event.target.value;
                                    props.setFilter(filterState);
                                }
                            }}
                            type='date'
                            value={props.filter.startDate ?? `${props.maxSeason}-01-01`}
                        />
                        <b classname='label' style={{ padding: '0px 25px' }}>To: </b>
                        <input
                            min={props.filter.startDate ?? `${props.minSeason}-01-01`}
                            onChange={(event) => {
                                if (event.target.value) {
                                    let filterState = Object.assign({}, props.filter);
                                    filterState.endDate = event.target.value;
                                    props.setFilter(filterState);
                                }
                            }}
                            type='date'
                            value={props.filter.endDate ?? `${props.maxSeason}-12-31`}
                        />
                    </React.Fragment>
                }
                {
                    canScaleUp() &&
                    <Row style={{ marginLeft: '10px' }}>
                        <Form.Check
                            name='scaleUp'
                            label='Scale Up?'
                            type='checkbox'
                            onChange={(event) => {
                                let filterState = Object.assign({}, props.filter);
                                filterState.scaleUp = event.target.checked;
                                props.setFilter(filterState);
                            }}
                        />
                    </Row>
                }
                {
                    !canScaleUp() && isPartialScaledUpSeason() &&
                    <p style={{ color: 'rgb(220, 53, 69)', textAlign: 'center', margin: '0', marginLeft: '5px' }}>
                        Can not scale up partial season
                    </p>
                }
            </div>
        </>
    );
}

export default TimeFilter;

const modeProps = {
    cumulative: {
        label: 'Multiple Seasons - Cumulative',
        id: 'radio-cumulative',
        inline: true,
        name: 'mode',
        value: 'cumulative',
    },
    date: {
        label: 'Date Range',
        id: 'radio-date',
        inline: true,
        name: 'mode',
        value: 'date',
    },
    separate: {
        label: 'Multiple Seasons - Separate',
        id: 'radio-separate',
        inline: true,
        name: 'mode',
        value: 'separate',
    },
    single: {
        label: 'Single Season',
        id: 'radio-single',
        inline: true,
        name: 'mode',
        value: 'single',
    },
}
