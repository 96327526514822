import React from 'react';
import IntRangeDropdown from './IntRangeDropdown';

function SeasonDropDown(props) {
    let minSeason = props.minSeason;
    let maxSeason = props.maxSeason;
    let curYear = (new Date).getMonth() >= 3 ? (new Date).getFullYear() : (new Date).getFullYear() - 1;

    if ((props.minSeason ?? 0) < 1900) {
        minSeason = curYear;
    }
    if ((props.maxSeason ?? 0) < 1900) {
        maxSeason = curYear;
    }

    return (
        <IntRangeDropdown
            minVal={minSeason}
            maxVal={maxSeason}
            selectedVal={props.selectedSeason}
            sortOrder={'desc'}
            setSelectedVal={props.setSeason}
            label={props.label}
            className={'seasonDropdown'}
        />
    );
}

export default SeasonDropDown;