import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Badge } from 'react-bootstrap';
import { customDateString } from './CommonFunctions';
import MultiSelect from './MultiSelect';
import PlayerSearch from './PlayerSearch';

/**
 * 
 * @param {any} props
 * filter: object with index, operator, value, 
 * formInputs: general formInputs from database
 * setFilter: function to set current filter based on index
 */
function StatFilter(props) {



    // removes any non-numeric characters and inserts thousands separators (commas)
    function convertToCurrency(value) {
        value.lowerBound = value.lowerBound?.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        value.upperBound = value.upperBound?.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return value;
    }

    // adds current year to month-day date
    function convertToCurrentYearDate(date) {
        if (date)
            return new Date(`${(new Date()).getFullYear()}-${date}T00:00:00`);
        else
            return new Date();
    }

    // removes year from date
    function convertToMonthDayDate(date) {
        return date.substring(date.indexOf('-') + 1)
    }

    function updateStat(inputValue) {
        if (props.filter.formInputs.asCurrency)
            inputValue = convertToCurrency(inputValue);
        else if (props.filter.formInputs.inputType === 'DateNoYear')
            inputValue = convertToMonthDayDate(inputValue);
        //else if (props.filter.formInputs.inputType === 'MultiSelect')
        //    inputValue = inputValue.length > 0 ? inputValue.join(',') : [];

        if (inputValue === '')
            inputValue = null;
        if (inputValue?.lowerBound === '')
            inputValue.lowerBound = null;
        if (inputValue?.upperBound === '')
            inputValue.upperBound = null;

        props.setFilter(props.filter.index, 'value', inputValue);
    }

    let eles = [];

    eles.push(
        <Col xs={12} md={2} xl={2}
            key={1}
        >
            <b>{props.filter.formInputs.formLabel}:</b>
        </Col>
    );

    switch (props.filter.formInputs.inputType) {
        case 'Date':
            eles.push(
                <Col xs={12} md={5} lg={3}
                    key={2}
                >
                    <label>From:</label>
                    <input
                        placeholder='MIN'
                        style={{ width: '125px' }}
                        type='date'
                        value={props.filter.value.lowerBound}
                        max={props.filter.value.upperBound}
                        onChange={(e) => updateStat(Object.assign(props.filter.value || {}, { lowerBound: e.target.value }))}
                    />
                </Col>,
                <Col xs={12} md={5} lg={3}
                    key={3}
                >
                    <label>To:</label>
                    <input
                        placeholder='MAX'
                        style={{ width: '125px' }}
                        type='date'
                        value={props.filter.value.upperBound}
                        min={props.filter.value.lowerBound}
                        onChange={(e) => updateStat(Object.assign(props.filter.value || {}, { upperBound: e.target.value }))}
                    />
                </Col>
            );
            break;
        case 'DateNoYear':
            eles.push(
                <Col xs={12} lg={5} xl={4}
                    key={2}
                >
                    <input
                        value={props.filter && props.filter.value
                            ? customDateString(convertToCurrentYearDate(props.filter.value))
                            : customDateString(new Date())
                        }
                        type='date'
                        onChange={(e) => updateStat(e.target.value)}
                    />
                </Col>
            )
            break;
        case 'DateRange':
            eles.push(
                <Col xs={12} md={5} lg={3}
                    key={2}
                >
                    <label>From:</label>
                    <input
                        placeholder='MIN'
                        style={{ width: '125px' }}
                        type='date'
                        value={props.filter?.value?.lowerBound}
                        onChange={(e) => updateStat(Object.assign(props.filter.value || {}, { lowerBound: e.target.value }))}
                    />
                </Col>,
                <Col xs={12} md={5} lg={3}
                    key={3}
                >
                    <label>To:</label>
                    <input
                        placeholder='MAX'
                        style={{ width: '125px' }}
                        type='date'
                        value={props.filter?.value?.upperBound}
                        onChange={(e) => updateStat(Object.assign(props.filter.value || {}, { upperBound: e.target.value }))}
                    />
                </Col>
            );
            break;
        case 'MultiSelect':
            eles.push(
                <Col xs={12} md={10}
                    key={2}
                >
                    <MultiSelect
                        list={props.filter.formInputs.selectOptions}
                        label={'value'}
                        selectedItems={props.filter?.value?.split(',') || []}
                        setSelectedItems={(selectedValues) => updateStat(selectedValues.length > 0 ? selectedValues.join(',') : null)}
                    />
                </Col>
            );
            break;
        case 'Number':
            eles.push(
                <Col xs={12} md={5} lg={3}
                    key={2}
                >
                    <label>From:</label>
                    {
                        props.filter.formInputs.asCurrency &&
                        <React.Fragment>$</React.Fragment>
                    }
                    <input
                        placeholder='MIN'
                        step='1'
                        style={{ width: '125px' }}
                        type={!props.filter.formInputs.asCurrency ? 'number' : 'text'}
                        value={props.filter?.value?.lowerBound}
                        onChange={(e) => updateStat(Object.assign(props.filter.value || {}, { lowerBound: e.target.value }))}
                    />
                </Col>,
                <Col xs={12} md={5} lg={3}
                    key={3}
                >
                    <label>To:</label>
                    {
                        props.filter.formInputs.asCurrency &&
                        <React.Fragment>$</React.Fragment>
                    }
                    <input
                        placeholder='MAX'
                        step='1'
                        style={{ width: '125px' }}
                        type={!props.filter.formInputs.asCurrency ? 'number' : 'text'}
                        value={props.filter?.value?.upperBound}
                        onChange={(e) => updateStat(Object.assign(props.filter.value || {}, { upperBound: e.target.value }))}
                    />
                </Col>
            );
            break;
        case 'PersonSelect':
            eles.push(
                <Col xs={12} md={5} lg={3}>
                    <PlayerSearch
                        key={0}
                        players={props.people}
                        onSelect={(playerId, platformSeason, posFlag) => {
                            let newVal = props.filter.value ?? new Set();
                            newVal.add(playerId);
                            updateStat(newVal);
                        }}
                        clearAfterSelection={props.filter.clearAfterSelection}
                    />
                </Col>,
                <Col xs={12} md={5} lg={7} style={{ color: '#006600' }}>
                    {
                        props.people.filter(person => props.filter.value?.has(person.id))
                            .map((person, personIndex) => {
                                return <Badge key={personIndex} variant='secondary'>
                                    {person.name}
                                    <span
                                        onClick={() => {
                                            let newVal = props.filter.value ?? new Set();
                                            newVal.delete(person.id);
                                            updateStat(newVal)
                                        }}
                                        type='button'
                                    >X</span>
                                </Badge>
                            })
                    }
                    {
                        (!props.filter.value || props.filter.value.size < 1) &&
                        <Badge key={-1} variant='secondary'>All</Badge>
                    }
                </Col>
            )
            break;
        case 'Select':
            eles.push(
                <Col xs={12} lg={5} xl={4}
                    key={2}
                >
                    <Form.Control
                        as='select'
                        onChange={(e) => updateStat(e.target.value)}
                    >
                        {
                            props.filter.formInputs.selectOptions.map((option, curIndex) =>
                                <option
                                    selected={props.filter?.value === option.value ? 'selected' : false}
                                    key={curIndex}
                                    value={option.value}
                                >
                                    {option.label}
                                </option>
                            )
                        }
                    </Form.Control>
                </Col>
            );
            break;
        default:
            eles.push(<Col xs={12} lg={5} xl={4}
                key={2}
            >Invalid Input Type</Col>);
            break;
    }

    return (<Row>{eles}</Row>);
}

export default StatFilter;

export function addFilterAsParameter(parameterObj, filter) {
    if (!filter.value
        || (filter.formInputs.inputType === 'MultiSelect'
            && filter.value === filter.formInputs.selectOptions.map(option => option.value).join(','))
    )
        return parameterObj;

    let paramVal = null;

    switch (filter.formInputs.inputType) {
        case 'MultiSelect':
            paramVal = filter.value.split(',').map(val => Number(val));
            break;
        case 'PersonSelect':
            paramVal = Array.from(filter.value);
            break;
        default:
            paramVal = convertNumberBoundsToNumber(filter.value)
            break;
    }

    let filterAsParamObj = {}
    filterAsParamObj[filter.formInputs.field] = paramVal;
    return Object.assign(parameterObj, filterAsParamObj);
}

export function addFilterForQueryTool(filterStrings, filter) {
    filterStrings = filterStrings.slice() ?? [];
    let curFilters = [];

    switch (filter.formInputs.inputType) {
        case 'Date':
        case 'DateRange':
        case 'Number':
            if (filter.value?.lowerBound)
                curFilters.push(`${filter.statId}>=${filter.value.lowerBound} in Platform Year`);
            if (filter.value?.upperBound)
                curFilters.push(`${filter.statId}<=${filter.value.upperBound} in Platform Year`);
            break;
        case 'DateNoYear':
        case 'Select':
            if (filter.value)
                curFilters.push(`${filter.statId}=${filter.value} in Platform Year`);
            break;
        case 'MultiSelect':
            if (filter.value && filter.value !== filter.formInputs.selectOptions.map(option => option.value).join(','))
                curFilters.push(`${filter.statId}=${filter.value} in Platform Year`);
            break;
        case 'PersonSelect':
            // produces a set
            break;
        default:

            break;
    }
    if (filter.isw)
        curFilters = curFilters.map(curFilter => `${curFilter} ${filter.isw}`)
    if (filter.igw)
        curFilters = curFilters.map(curFilter => `${curFilter} ${filter.igw}`)

    return filterStrings.concat(curFilters);
}

function convertNumberBoundsToNumber(filterVal) {
    let retVal = Object.assign({}, filterVal);
    if (retVal.lowerBound)
        retVal.lowerBound = Number(retVal.lowerBound.replaceAll(',', ''));
    if (retVal.upperBound)
        retVal.upperBound = Number(retVal.upperBound.replaceAll(',', ''));
    return retVal;
}

export function convertFilterToNote(filter) {
    switch (filter.formInputs.inputType) {
        case 'Number':
            if (filter.value?.lowerBound && filter.value?.upperBound)
                return `${filter.formInputs.formLabel}: ` +
                    `Between ${filter.value.lowerBound} and ${filter.value.upperBound}`;
            else if (filter.value?.lowerBound)
                return `${filter.formInputs.formLabel}: ` +
                    `At least ${filter.value.lowerBound}`;
            else if (filter.value?.upperBound)
                return `${filter.formInputs.formLabel}: ` +
                    `At most ${filter.value.upperBound}`;
            break;
        case 'MultiSelect':
            let selectOptions = {};
            filter.formInputs.selectOptions.forEach(option => {
                selectOptions[option.value] = option.label;
            })
            return `${filter.formInputs.formLabel}: ` +
                filter.value.split(',')
                    .map(optionSelected => selectOptions[optionSelected])
                    .join(', ');
            break;
        default:
            break;
    }
    return null;
}

export function createFilter(filterIndex, column) {
    let filter = {};
    filter.index = filterIndex;
    filter.statId = column.id;

    switch (column.formInputs.inputType) {
        case 'Date':
        case 'DateRange':
            filter.value = {
                lowerBound: new Date(),
                upperBound: new Date(),
            }
            break;
        case 'MultiSelect':
            filter.value = column.formInputs.selectOptions.map(option => option.value).join(',');
            break;
        case 'Number':
            filter.value = {
                lowerBound: null,
                upperBound: null,
            };
        case 'PersonSelect':
            filter.value = new Set();
        default:
            filter.value = null;
            break;
    }
    filter.formInputs = column.formInputs;

    return filter;
}