import React, { useState, useEffect } from 'react';
import { Form, Badge } from 'react-bootstrap';

export function PositionSelector(props) {
    const [positions, setPositions] = useState(['0']);

    useEffect(() => {
        props.setPositions(positions);
    }, [positions])

    function addPosition(position) {
        if (!positions.includes(position)) {
            let curPositions = positions.slice();
            if (position !== '0') {
                curPositions.push(position);
                if (curPositions.length > 1 && curPositions.includes('0')) {
                    curPositions.splice(curPositions.indexOf('0'), 1);
                }
                setPositions(curPositions);
            }
            else {
                setPositions(['0']);
            }
        }
    };

    function remPosition(position) {
        let curPositions = positions.slice();
        if (curPositions.includes(position)) {
            curPositions.splice(curPositions.indexOf(position), 1);
        }
        setPositions(curPositions);
    }

    let selectedPositions = [];
    for (let i = 0; i < positions.length; i++) {
        if (positions[i] !== '0' && positions[i] !== '7,8,9') {
            selectedPositions.push(
                <Badge key={positions[i]} variant='secondary'>{positionAbbr[positions[i]]}<span type='button' onClick={() => remPosition(positions[i])}>X</span></Badge>
            )
        }
        else if (positions[i] == '7,8,9') {
            selectedPositions.push(
                <Badge key={positions[i]} variant='secondary'>{'OF'}<span type='button' onClick={() => remPosition(positions[i])}>X</span></Badge>
            )
        }
        else {
            selectedPositions.push(
                <Badge key={positions[i]} variant='secondary'>{positionAbbr[positions[i]]}</Badge>
            )

        }
    };

    return (
        <React.Fragment>
            <Form.Control style={{ width: '100px' }} as='select' onChange={(event) => addPosition(event.target.value)}>
                <option key='0' value='0'>All</option>
                <option key='2' value='2'>C</option>
                <option key='3' value='3'>1B</option>
                <option key='4' value='4'>2B</option>
                <option key='5' value='5'>3B</option>
                <option key='6' value='6'>SS</option>
                <option key='7' value='7'>LF</option>
                <option key='8' value='8'>CF</option>
                <option key='9' value='9'>RF</option>
                <option key='10' value='7,8,9'>OF</option>
                <option key='11' value='11'>DH</option>
                <option key='14' value='14'>SP</option>
                <option key='15' value='15'>RP</option>
            </Form.Control>
            <div>
                {
                    selectedPositions.length > 0 &&
                    selectedPositions
                }
            </div>
        </React.Fragment>
    )
}

let positionAbbr = [
    'All',
    'P',
    'C',
    '1B',
    '2B',
    '3B',
    'SS',
    'LF',
    'CF',
    'RF',
    'OF',
    'DH',
    'PH',
    'PR',
    'SP',
    'RP',
]