function CloseButton(props) {
    return (
        <div
            type='button'
            className='deleteButton'
            style={{ float: 'right' }}
            onDragOver={(ev) => {
                ev.preventDefault();
                ev.dataTransfer.dropEffect = 'none';
                ev.stopPropagation();
            }}
            onClick={(ev) => {
                let parentId = ev.target.parentNode.id;
                let prevLogOpId = parentId.replace(/((filter)|(block))-[0-9]+$/, `logOp-${parentId.substring(parentId.lastIndexOf('-') + 1)}`)
                    
                let prevLogOp = document.getElementById(prevLogOpId);
                if (prevLogOp) {
                    prevLogOp.remove();
                }
                else {
                    let nextLogOp = document.getElementById(parentId).nextSibling;
                    if (nextLogOp) nextLogOp.remove();
                }
                document.getElementById(parentId).remove();
            }}
        >
            X
        </div>
    )
}

export default CloseButton;