import React, { useEffect, useState } from 'react';
import { render } from 'react-dom';
import { Form, Badge, Row, Col } from 'react-bootstrap';
import { advancedFiltersFunctions, dragAndDropFunctions } from '../DragAndDrop/Functions';
import AutoComplete from '../Common/AutoComplete';
import { customDateString } from '../Common/CommonFunctions';
import OperatorDropdown from '../Common/OperatorDropdown';
import MultiSelect from '../Common/MultiSelect';

function StatSelectionForm(props) {
    const [igwFilters, setIgwFilters] = useState(null);
    const [iswFilters, setIswFilters] = useState(null);

    useEffect(() => {
        (async () => {
            setIgwFilters(
                props.section !== 'Filter' && props.curStat?.igwFilter
                    ? await advancedFiltersFunctions.convertFilterStringToHtml(props.curStat.igwFilter, 'IGW', props.curStat.index)
                    : ''
            )
            setIswFilters(
                props.section !== 'Filter' && props.curStat?.iswFilter
                    ? await advancedFiltersFunctions.convertFilterStringToHtml(props.curStat.iswFilter, 'ISW', props.curStat.index)
                    : ''
            )
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setIgwFilters(
                props.section !== 'Filter' && props.curStat?.igwFilter
                    ? await advancedFiltersFunctions.convertFilterStringToHtml(props.curStat.igwFilter, 'IGW', props.curStat.index)
                    : ''
            )
            setIswFilters(
                props.section !== 'Filter' && props.curStat?.iswFilter
                    ? await advancedFiltersFunctions.convertFilterStringToHtml(props.curStat.iswFilter, 'ISW', props.curStat.index)
                    : ''
            )
        })();
    }, [props.curStat]);

    function movePokeTrainer(e, direction) {
        let pokeTrainer = document.getElementById('pokeTrainer');
        if (pokeTrainer) pokeTrainer.remove();
        let location = e.currentTarget.getBoundingClientRect();
        let ele = document.createDocumentFragment();
        render(
            <PokeTrainer
                left={location.left}
                top={location.top}
                direction={direction}
            />,
            ele
        )
        document.getElementsByTagName('Body')[0].prepend(ele);
    }

    function PokeTrainer(props) {
        function removePokeTrainer() {
            clearInterval(moveInterval);
            let pokeTrainer = document.getElementById('pokeTrainer');
            if (pokeTrainer) pokeTrainer.remove();
        }

        function movePokeTrainer() {
            let pokeTrainer = document.getElementById('pokeTrainer');
            pokeTrainer.style.top = props.direction === 'up'
                ? parseFloat(pokeTrainer.style.top) - 5 + 'px'
                : parseFloat(pokeTrainer.style.top) + 5 + 'px';

            switch (pokeTrainer.className) {
                case 'front':
                    pokeTrainer.className = 'right';
                    pokeTrainer.src = './images/PokemonTrainerRightSideTransparent.png';
                    break;
                case 'right':
                    pokeTrainer.className = 'back';
                    pokeTrainer.src = './images/PokemonTrainerBackTransparent.png';
                    break;
                case 'back':
                    pokeTrainer.className = 'left';
                    pokeTrainer.src = './images/PokemonTrainerLeftSideTransparent.png';
                    break;
                case 'left':
                    pokeTrainer.className = 'front';
                    pokeTrainer.src = './images/PokemonTrainerFrontTransparent.png';
                    break;
                default:
                    break;
            }
        }

        let moveInterval = setInterval(movePokeTrainer, 150, );
        setTimeout(removePokeTrainer, 5000);

        return (
            <img
                alt=''
                id='pokeTrainer'
                className='front'
                src='./images/PokemonTrainerFrontTransparent.png'
                style={{ position: 'fixed', left: `${props.left}px`, top: `${props.top}px`, zIndex: '999' }}
            />
        )
    }

    let displayTimePeriod = !props.timePeriodNotSelectable
        || (props.section === 'Criteria' && ['throughX', 'dateRange'].includes(props.tabName));

    let timePeriods = props.curStat?.stat && props.getTimePeriodsFromFormInputs
        ? props.getTimePeriodsFromFormInputs(props.curStat?.column?.formInputs ?? {})
        : [];
    let addOn = ''
    switch (props.curStat?.column?.statPosType) {
        case 'b':
            addOn = 'Batting';
            break;
        case 'f':
            addOn = 'Fielding';
            break;
        case 'p':
            addOn = 'Pitching';
            break;
        default:
            addOn = '';
            break;
    }

    let styling = props.section !== 'Filter' && props.index % 2 === 1
        ? { backgroundColor: '#e6e6e6' }
        : {};

    let sectionId = props.section === 'Stat' ? 'DisStat' : props.section === 'Criteria'
        ? 'NumCrit'
        : '';

    let hasFilters = props.section !== 'Filter' && (
        document.getElementById(sectionId)?.getElementsByClassName(`ISW dropZone-${props.index}`)[0]?.getElementsByTagName('span').length > 0 ||
        document.getElementById(sectionId)?.getElementsByClassName(`IGW dropZone-${props.index}`)[0]?.getElementsByTagName('span').length > 0
    )

    return (
        <div key={props.curStat.index + 1} className='stat-filter'>
            <Row key={props.index + 2 + ''} style={styling}>
                <Col xs={1} className='d-lg-none'>{props.section !== 'Filter' ? 'Stat: ' : 'Split: '}</Col>
                <Col xs={9} lg={3} xl={2}>
                    <AutoComplete
                        value={props.curStat?.column?.title ?? ''}
                        items={props.selectableStatsByGroup}
                        groups={props.colGroups}
                        onSelect={
                            (colKey) => {
                                props.setStat(props.curStat.index, 'stat', colKey)
//                                if (formInputs.inputType === 'DateRange') {
//                                    props.setStat(curStat.index, 'operator', '=')
//                                    props.setStat(curStat.index, 'timePeriod', '')
//                                }
                            }
                        }
                        dropUp={true}
                        sortItemsAlphabetical={true}
                        addOn={addOn}
                        placeholder={props.placeholder}
                    />
                </Col>
                {
                    ['Player Criteria', 'Criteria', 'Filter'].includes(props.section) &&
                    <NumCritInputs
                        setStat={props.setStat}
                        statFormInputs={props.curStat?.column?.formInputs}
                        curStat={props.curStat}
                    />
                }
                {
                    props.curStat?.stat && displayTimePeriod &&
                    <React.Fragment>
                        <Col xs={3} className='d-lg-none'>Time Period: </Col>
                        <Col xs={9} lg={3} xl={2}>
                            {
                                !props.timePeriodNotSelectable && timePeriods.length > 0 && // formInputs.inputType === 'DateRange' &&
                                    <Form.Control as='select' onChange={(event) => props.setStat(props.curStat.index, 'timePeriod', event.target.value)}>
                                    {
                                        timePeriods.map((timePeriod, mapIndex) => {
                                            return (
                                                <option
                                                    selected={props.curStat?.timePeriod === timePeriod ? 'selected' : false}
                                                    key={mapIndex}
                                                    value={timePeriod}
                                                >
                                                    {timePeriod}
                                                </option>
                                            )
                                        })
                                    }
                                </Form.Control>
                            }
                            {
                                props.tabName === 'throughX' &&
                                <React.Fragment>Career Through X</React.Fragment>
                            }
                            {
                                props.tabName === 'dateRange' &&
                                <React.Fragment>In Date Range</React.Fragment>
                            }
                        </Col>
                    </React.Fragment>
                }
                {
                    props.section !== 'Filter' &&
                    <Col xs={1}>
                        <Badge
                            style={{ cursor: 'pointer' }}
                            tabIndex='0'
                            variant='danger'
                                onClick={() => props.removeStat(props.index)}
                        >
                            X
                    </Badge>
                    </Col>
                }
                {
                    props.section === 'Stat' &&
                    <Col xs={1}>
                        {
                            props.index > 0 &&
                            <Badge
                                tabIndex='0'
                                variant='light'
                                type='button'
                                onClick={(e) => {
                                    props.reorderStat(props.index, 'up');
                                    if (e.ctrlKey && e.shiftKey) {
                                        movePokeTrainer(e, 'up');
                                    }
                                }}
                            >
                                <i className='arrow-up' />
                            </Badge>
                        }
                        {
                            props.index < props.stats.length - 1 &&
                            <Badge
                                tabIndex='0'
                                variant='light'
                                type='button'
                                onClick={(e) => {
                                    props.reorderStat(props.index, 'down');
                                    if (e.ctrlKey && e.shiftKey) {
                                        movePokeTrainer(e, 'down');
                                    }
                                }}
                            >
                                <i className='arrow-down' />
                            </Badge>
                        }
                    </Col>
                }
            </Row>
            {
                props.curStat?.stat &&
                <Row
                    style={styling}
                    hidden={props.hideFilters && !hasFilters}
                >
                    {
                        ((props.section === 'Criteria' && props.curStat?.column?.formInputs?.numCritISWFilterable) ||
                            (props.section === 'Stat' && props.curStat?.column?.formInputs?.disStatISWFilterable)) &&
                        <React.Fragment>
                            <Col style={{ paddingLeft: '60px' }} xs={3} lg={1}>
                                Where
                                </Col>
                            <Col xs={9} lg={4}>
                                <div
                                    id={`${sectionId}-ISW-dropZone-${props.index}`}
                                    className={`ISW dropZone dropZone-${props.index}`}
                                    granularity={props.curStat?.column?.formInputs?.granularity}
                                    allowableFilters={props.curStat?.column?.formInputs?.uniqueFilters}
                                    onDragOver={(ev) => {
                                        ev.preventDefault();
                                        ev.dataTransfer.dropEffect = 'move';
                                    }}
                                    onDrop={(ev) => dragAndDropFunctions.dropElement(ev)}
                                >
                                    {iswFilters}
                                </div>
                            </Col>
                        </React.Fragment>
                    }
                    {
                        ((props.section === 'Criteria' && props.curStat?.column?.formInputs?.numCritIGWFilterable) ||
                            (props.section === 'Stat' && props.curStat?.column?.formInputs?.disStatIGWFilterable)) &&
                        <React.Fragment>
                            <Col style={{ paddingLeft: '60px' }} xs={3} lg={1}>
                                In Games Where
                                </Col>
                            <Col xs={9} lg={4}>
                                <div
                                    id={`${sectionId}-IGW-dropZone-${props.index}`}
                                    className={`IGW dropZone dropZone-${props.index}`}
                                    granularity={props.curStat?.column?.formInputs?.granularity}
                                    allowableFilters={props.curStat?.column?.formInputs?.uniqueFilters}
                                    onDragOver={(ev) => {
                                        ev.preventDefault();
                                        ev.dataTransfer.dropEffect = 'move';
                                    }}
                                    onDrop={(ev) => dragAndDropFunctions.dropElement(ev)}
                                >
                                    {igwFilters}
                                </div>
                            </Col>
                        </React.Fragment>
                    }
                </Row>
            }
        </div>
    )
}

export default StatSelectionForm;


function ConditionalPrefix(props) {
    if (props.statFormInputs.asCurrency) {
        return '$';
    } else {
        return (
            <React.Fragment>&nbsp;&nbsp;</React.Fragment>
        )
    }
}

function HandleCurrency(event, props) {                             // event handler "wrapper" for currency input
    let value = event.target.value
    value = value.replace(/\D/g, '')                                // remove any non-numeric characters
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')             // insert thousands separators
    event.target.value = value
    props.setStat(props.curStat.index, 'value', event.target.value) // intended event handler
}

function NumCritInputs(props) {
    let operatorHtml = []
    if (props.curStat && props.curStat.stat && props.statFormInputs.inputType !== 'DateRange' && ['Number', 'Date'].includes(props.statFormInputs.inputType)) {
        operatorHtml.push(
            <OperatorDropdown
                curOperator={props.curStat?.operator}
                curStatIndex={props.curStat.index}
                setOperator={props.setStat}
            />
        )
    } else {
        operatorHtml.push(
            <Col xs={3} lg={2} />
        )
    }

    return (
        <React.Fragment>
            {operatorHtml}
            {
                props.curStat && props.curStat.stat && props.statFormInputs.inputType === 'Number' && props.statFormInputs.asCurrency === false &&
                <React.Fragment>
                    <Col xs={2} className='d-lg-none'>Value: </Col>
                    <ConditionalPrefix statFormInputs={props.statFormInputs} />
                    <Col xs={4} lg={2}>
                        <Form.Control
                            as='input'
                            type='number'
                            step='1'
                            value={props.curStat ? props.curStat.value : 0}
                            onChange={(event) => props.setStat(props.curStat.index, 'value', event.target.value)}
                            />
                    </Col>
                </React.Fragment>
            }
            {
                props.curStat && props.curStat.stat && props.statFormInputs.asCurrency === true &&
                <React.Fragment>
                    <Col xs={2} className='d-lg-none'>Value: </Col>
                    <ConditionalPrefix statFormInputs={props.statFormInputs} />
                    <Col xs={4} lg={2}>
                        <Form.Control
                            as='input'
                            type='text'
                            step='1'
                            value={props.curStat ? props.curStat.value : 0}
                            onChange={(event) => HandleCurrency(event, props)}
                        />
                    </Col>
                </React.Fragment>
            }
            {
                props.curStat && props.curStat.stat && props.statFormInputs.inputType === 'DateRange' &&
                <React.Fragment>
                    <Col xs={2} className='d-lg-none'>Value: </Col>
                    <ConditionalPrefix statFormInputs={props.statFormInputs} />
                    <Col xs={4} lg={2}>
                        <span style={{display: 'inline-block', width: '50px'}}>From:</span>
                        <input
                            value={props.curStat.value.start}
                            type='date'
                            onChange={(e) => {
                                if (e.target.value) {
                                    props.setStat(props.curStat.index, 'value', { start: e.target.value, end: e.target.value })
                                }
                            }}
                        />
                        <br />
                        <span style={{ display: 'inline-block', width: '50px' }}>To:</span>
                        <input
                            value={props.curStat.value.end}
                            type='date'
                            onChange={(e) => {
                                if (e.target.value) {
                                    props.setStat(props.curStat.index, 'value', { start: props.curStat.value.start, end: e.target.value })
                                }
                            }}
                        />
                    </Col>
                </React.Fragment>
            }
            {
                props.curStat && props.curStat.stat && props.statFormInputs.inputType === 'Date' && props.curStat.stat && props.statFormInputs.inputType !== 'DateRange' &&
                <React.Fragment>
                    <Col xs={2} className='d-lg-none'>Value: </Col>
                    <ConditionalPrefix statFormInputs={props.statFormInputs} />
                    <Col xs={4} lg={2}>
                        <input
                            value={props.curStat && props.curStat.value
                                ? customDateString(new Date(props.curStat.value + 'T00:00:00'))
                                : customDateString(new Date())
                            }
                            type='date'
                            onChange={(e) => {
                                if (e.target.value) {
                                    props.setStat(props.curStat.index, 'value', e.target.value)
                                }
                            }}
                        />
                    </Col>
                </React.Fragment>
            }
            {
                props.curStat && props.curStat.stat && props.statFormInputs.inputType === 'DateNoYear' &&
                <React.Fragment>
                    <Col xs={2} className='d-lg-none'>Value: </Col>
                    <ConditionalPrefix statFormInputs={props.statFormInputs} />
                    <Col xs={4} lg={2}>
                        <input
                            value={props.curStat && props.curStat.value
                                ? customDateString(new Date(`${(new Date()).getFullYear()}-${props.curStat.value}T00:00:00`))
                                : customDateString(new Date())
                            }
                            type='date'
                            onChange={(e) => {
                                if (e.target.value) {
                                    let date = e.target.value;

                                    props.setStat(props.curStat.index, 'value', date.substring(date.indexOf('-') + 1));
                                }
                            }}
                        />
                    </Col>
                </React.Fragment>
            }
            {
                props.curStat && props.curStat.stat && props.statFormInputs.inputType === 'Select' &&
                <React.Fragment>
                    <Col xs={2} className='d-lg-none'>Value: </Col>
                    <ConditionalPrefix statFormInputs={props.statFormInputs} />
                    <Col xs={4} lg={2}>
                        <Form.Control
                            as='select'
                            onChange={(event) => props.setStat(props.curStat.index, 'value', event.target.value)}
                        >
                            {
                                props.statFormInputs.selectOptions.map((curSelOp, curIndex) =>
                                    <option
                                        selected={props.curStat?.value === curSelOp.value ? 'selected' : false}
                                        key={curIndex}
                                        value={curSelOp.value}
                                    >
                                        {curSelOp.label}
                                    </option>
                                )
                            }
                        </Form.Control>
                    </Col>
                </React.Fragment>
            }
            {
                props.curStat && props.curStat.stat && props.statFormInputs.inputType === 'MultiSelect' &&
                <React.Fragment>
                    <Col xs={2} className='d-lg-none'>Value: </Col>
                    <ConditionalPrefix statFormInputs={props.statFormInputs} />
                    <Col xs={4} lg={2}>
                        <MultiSelect
                            list={props.statFormInputs.selectOptions}
                            label={'value'}
                            selectedItems={props.curStat?.value?.split(',') || []}
                            setSelectedItems={(selectedValues) => {
                                props.setStat(props.curStat.index, 'value', selectedValues.length > 0 ? selectedValues.join(',') : null)
                            }}
                        />
                    </Col>
                </React.Fragment>
            }
        </React.Fragment>
    )
}