import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import ReportModal from './ReportModal';

function SaveLoadButtons(props) {
    const [modalType, setModalType] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (['Save', 'Load'].includes(modalType)) {
            setShowModal(true);
        }
    }, [modalType])

    function hideModal() {
        setShowModal(false);
        setModalType('');
    }

    return (
        <Row>
            {props.header}
            <div style={{ margin: '0px 0px 0px auto' }}>
                <Row>Save and Load Selected, Custom Measures</Row>
                <Row className='justify-content-md-center'>
                    <img width="80" height="40" src={'./images/ResetButton.png'} type='button' onClick={props.resetFunction} />
                    <img width="80" height="40" src={'./images/LoadButton.png'} type='button' onClick={() => setModalType('Load')} />
                    <img width="80" height="40" src={'./images/SaveButton.png'} type='button' onClick={() => setModalType('Save')} />
                </Row>
            </div>
            <ReportModal
                showModal={showModal}
                reportType={modalType}
                hideModal={hideModal}
                page={props.page}
                getReportQuery={props.getReportFunction}
                loadReport={(report) => props.loadReportFunction(report)}
            />
        </Row>
    )
}

export default SaveLoadButtons;