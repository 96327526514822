import { useState } from 'react';

function LogOp(props) {
    const [operator, setOperator] = useState(props.operator);

    function toggleLogOp() {
        if (operator === 'AND') {
            setOperator('OR');
        }
        else {
            setOperator('AND');
        }
    }

    return (
        <span className={`logOp ${props.id}`} id={props.id} onClick={toggleLogOp}>{operator}</span>
    )
}

export default LogOp;