import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import PlayingTimeFilter from './PlayingTimeFilter';

function PlayingTimeFilterWrapper(props) {
    const [filters, setFilters] = useState([]);
    let val = ['IP', 'Inn'].includes(props.filterCategory) ? Number(props.filterValue) : Number(props.filterValue).toFixed(0);

    useEffect(() => {
        if (props.qualificationFilter) {
            setFilters([{ id: 1, filterOperator: props.filterOperator, filterValue: val, filterCategory: props.filterCategory }]);
        } else {
            setFilters([{ id: 1, filterOperator: '>', filterValue: '0', filterCategory: props.filterCategory }]);
        }
    }, [props.qualificationFilter, props.filterOperator, val, props.filterCategory]);

    useEffect(() => {
        const mappedFilters = filters.map(filter => ({
            operator: filter.filterOperator,
            value: filter.filterValue,
            stat: filter.filterCategory
        }));
        props.setFilterObj(mappedFilters);
        props.setFormOptions(prevOptions => ({
            ...prevOptions,
            filterObj: mappedFilters
        }));
    }, [filters]);

    const addFilter = () => {
        const newFilter = {
            id: filters.length + 1,
            filterOperator: '>',
            filterValue: '0',
            filterCategory: props.filterCategory
        };
        setFilters([...filters, newFilter]);
    };

    const removeFilter = () => {
        if (filters.length > 1) {
            const updatedFilters = filters.slice(0, -1);
            setFilters(updatedFilters);
        }
    };

    const handleFilterChange = (id, field, value) => {
        const updatedFilters = filters.map(filter => {
            if (filter.id === id) {
                return { ...filter, [field]: value };
            }
            return filter;
        });
        setFilters(updatedFilters);
        const mappedFilters = updatedFilters.map(filter => ({
            operator: filter.filterOperator,
            value: filter.filterValue,
            stat: filter.filterCategory
        }));
        props.setFilterObj(mappedFilters);
    };

    return (
        <div>
            {filters.map(filter => (
                <PlayingTimeFilter
                    key={filter.id}
                    filterOperator={filter.filterOperator}
                    filterValue={filter.filterValue}
                    setFilterCategory={value => handleFilterChange(filter.id, 'filterCategory', value)}
                    setFilterOperator={value => handleFilterChange(filter.id, 'filterOperator', value)}
                    setFilterValue={value => handleFilterChange(filter.id, 'filterValue', value)}
                    filterCategories={props.filterCategories}
                />
            ))}
            <Button style={{ margin: '8.5px 5px 5px 15px' }} variant='success'
                onClick={addFilter}
            >
                Add Filter
            </Button>
            {
                filters.length > 1 &&
                <Button style={{ margin: '8.5px 5px 5px 5px' }} variant='success'
                    onClick={removeFilter}
                >
                    Remove Filter
                </Button>
            }
        </div>
    );
}

export default PlayingTimeFilterWrapper;
