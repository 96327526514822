import React, { useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import {
    GetPlayers,
    GetReportTransactions,
    GetTransactions,
    GetRosterStatusSequence,
    GetInjuries
} from '../../Services/ApiSvc';
import DatePicker from '../Common/DatePicker';
import { columnManager } from '../Common/ColumnManager';
import ExcelExporter from '../Common/ExcelExporter';
import PDFExporter from '../Common/PDFExporter';
import StatFilter, { addFilterAsParameter, convertFilterToNote, createFilter } from '../Common/StatFilter';
import TabulatorTable from '../Common/TabulatorTable';

function TransactionsReport() {
    const [allPlayers, setAllPlayers] = useState([]);
    const [columns, setColumns] = useState(null);
    const [data, setData] = useState(null);
    const [exceptionMsg, setExceptionMsg] = useState(null);
    const [excelNotes, setExcelNotes] = useState(null);
    const [filters, setFilters] = useState([]);

    let today = new Date();
    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 30);
    const [startDate, setStartDate] = useState(yesterday);
    const [endDate, setEndDate] = useState(today);

    const [activeSeasons, setActiveSeasons] = useState([]);

    const [transactionsPlayer, setTransactionsPlayer] = useState(null);
    const [showPlayerTransactionsTable, setShowPlayerTransactionsTable] = useState(false);
    const [playerTransactionsData, setPlayerTransactionsData] = useState(null);
    const [playerTransactionCols, setPlayerTransactionCols] = useState([]);

    const [allTransactions, setAllTransactions] = useState([]);

    const [prevShouldDisableTransactionType, setPrevShouldDisableTransactionType] = useState(null);

    const [injuries, setInjuries] = useState([]);
    const [injuryCols, setInjuryCols] = useState([]);
    const [showPlayerInjuriesTable, setShowPlayerInjuriesTable] = useState(false);

    const fetchInjuryData = async (playerId) => {        
        const groupCols = await columnManager.getAllGroupsBySection('PlayerPage');
        const injuryColumns = groupCols.find(group => group.id === 25).columns.slice();
        
        const allInjuries = await GetInjuries(playerId);       
    
        const nestedInjuries = allInjuries.reduce((acc, cur) => {
            if (!acc[cur.TickId])
                acc[cur.TickId] = Object.assign({}, cur);
            else if (!acc[cur.TickId]['_children'])
                acc[cur.TickId]['_children'] = [cur];
            else
                acc[cur.TickId]['_children'].push(cur);
    
            return acc;
        }, {});
        
        setInjuryCols(injuryColumns);
        setInjuries(Object.values(nestedInjuries));
        setShowPlayerInjuriesTable(true);
    }

    useEffect(() => {
        (async function Initialize() {
            let allPlayersFromAPI = await GetPlayers();
            let colGroups = await columnManager.getAllGroupsBySection('TransactionsReport') || [];
            let playerTransactionGroupCols = await columnManager.getAllGroupsBySection('PlayerPage');
            let transFilters = [];
            let dataColMap = {};
            let transColumns = [];

            const transRepFilters = colGroups.find(g => g.label === "TransactionsReportFilters");
            if (transRepFilters) {
                transFilters = transRepFilters.columns?.map((col, index) => {
                    const filter = Object.assign({}, createFilter(index, col));

                    if (filter.formInputs.inputType === "PersonSelect") {
                        filter.clearAfterSelection = true;
                    }

                    return filter;
                });
            }

            const transReport = colGroups.find(g => g.label === "TransactionsReport");
            if (transReport) {
                transColumns = transReport.columns;
            }

            let playerTransColumns = playerTransactionGroupCols.find(group => group.label === 'Transactions').columns;

            transColumns.forEach(col => {
                col.field = dataColMap[col.formInputs.defaultTimePeriod + ' ' + col.field]?.toString() ?? col.field;
                if (col.title === 'Player') {
                    col.title = 'Player (Click cell to show transactions)';
                    col.cellClick = function (e, cell) {
                        setTransactionsPlayer({
                            playerId: cell.getRow().getData().PlayerId,
                            playerName: cell.getRow().getData().Player,
                        });
                    }
                    col.formatterParams = {
                        clickStyling: true,
                    }
                }
            });

            setAllPlayers(allPlayersFromAPI);
            setColumns(transColumns);
            setFilters(transFilters);
            setPlayerTransactionCols(playerTransColumns);
        })()
    }, []);

    useEffect(() => {
        //Because we don't have proper transaction codes before a certain date, we have to conditionally disable the transaction type filter based on startDate/endDate
        //Filters should only reset when switching from states where transaction type filter filter is disabled/enabled
        const isTransactionTypeDisabled = (date) => date < new Date('12-23-2016');
        const shouldDisableTransactionType = isTransactionTypeDisabled(startDate) || isTransactionTypeDisabled(endDate);

        const updateFilters = async () => {
            let colGroups = await columnManager.getAllGroupsBySection('TransactionsReport') || [];
            let transFilters = [];

            const transRepFilters = colGroups.find(g => g.label === "TransactionsReportFilters");
            if (transRepFilters) {
                transRepFilters.columns?.forEach((column, filterIndex) => {
                    if (column.field === 'TransactionType' && shouldDisableTransactionType) {
                        return;
                    }

                    let filter = Object.assign({}, createFilter(filterIndex, column));

                    if (filter.formInputs.inputType === "PersonSelect") {
                        filter.clearAfterSelection = true;
                    }

                    transFilters.push(filter);
                });
            }   

            setFilters(transFilters);
        };


        if (prevShouldDisableTransactionType !== shouldDisableTransactionType) {
            updateFilters();
            setPrevShouldDisableTransactionType(shouldDisableTransactionType);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (transactionsPlayer !== null && transactionsPlayer.playerId > 0) {

            const transactionsTask = GetTransactions(transactionsPlayer.playerId);
            const rosterStatusSequencesTask = GetRosterStatusSequence(transactionsPlayer.playerId);

            Promise.all([transactionsTask, rosterStatusSequencesTask])
                .then((values) => {
                    let rosterStatusSequences = values[1];

                    setAllTransactions(values[0]);
                    setShowPlayerInjuriesTable(false);

                    /**
                    * If a player is retired/unknown for an entire season or if they have a status of
                    * Not on 40-man for at least the three most recent years we don't want to display those years.
                    * Effectively not treating them as active players.
                    */
                    function GetActiveSeasons() {
                        let allSeasons = {};
                        rosterStatusSequences.forEach(rosterStatusSequence => {
                            if (!allSeasons[rosterStatusSequence.season]) {
                                allSeasons[rosterStatusSequence.season] = {
                                    inactive: false,
                                    isRetired: false,
                                    season: rosterStatusSequence.season,
                                };
                            }
                        });

                        Object.values(allSeasons).forEach(season => {
                            let rosterStatusSequencesBySeason = rosterStatusSequences
                                .filter(rosterStatusSequence => rosterStatusSequence.season === season.season);

                            if (rosterStatusSequencesBySeason.every(sequence => {
                                return ['Retired', 'Unknown'].includes(sequence.rosterStatusSpecific);
                            }))
                                season.isRetired = true;

                            if (rosterStatusSequencesBySeason.length < 1
                                || rosterStatusSequencesBySeason.every(sequence => {
                                    return ['Not on 40-man', 'Unknown'].includes(sequence.rosterStatusGeneral);
                                }))
                                season.inactive = true;
                        });

                        let seasonsInfo = Object.values(allSeasons)
                            .sort((a, b) => b.season - a.season);

                        let inactiveSeasons = seasonsInfo
                            .filter(seasonInfo => seasonInfo.isRetired)
                            .map(seasonInfo => seasonInfo.season);

                        if (inactiveSeasons.length < 1
                            || seasonsInfo.slice(0, 2).every(seasonInfo => seasonInfo.inactive)) {
                            inactiveSeasons = seasonsInfo
                                .slice(0, seasonsInfo.findIndex(seasonInfo => !seasonInfo.inactive))
                                .map(seasonInfo => seasonInfo.season)
                                ?? [];
                        }

                        return seasonsInfo
                            .filter(seasonInfo => !inactiveSeasons.includes(seasonInfo.season))
                            .map(seasonInfo => seasonInfo.season);
                    }
                    let activeSeasons = GetActiveSeasons();
                    setActiveSeasons(activeSeasons);
                    fetchInjuryData(transactionsPlayer.playerId);
                });
        }
    }, [transactionsPlayer])

    useEffect(() => {
        if (transactionsPlayer !== null && transactionsPlayer.playerId > 0) {
            setPlayerTransactionsData({});
            let dataObj = {
                transactionData: [],
            }

            let transactions = allTransactions.slice();

            let transactionDataBySeason = {};
            activeSeasons.forEach(activeSeason => {
                transactionDataBySeason[activeSeason] = {
                    Season: activeSeason,
                    _children: [],
                }
            })
            transactions.forEach(curTransaction => {
                if (!transactionDataBySeason[curTransaction.Season])
                    transactionDataBySeason[curTransaction.Season] = {
                        Season: curTransaction.Season,
                        _children: [],
                    };

                transactionDataBySeason[curTransaction.Season]._children.push(curTransaction);
            });

            dataObj.transactionData = Object.values(transactionDataBySeason)
                .map(transactionSeason => {
                    if (transactionSeason._children.length < 1)
                        delete transactionSeason._children
                    return transactionSeason;
                })
                .sort((a, b) => { return b.Season - a.Season });

            setPlayerTransactionsData(dataObj);
            setShowPlayerTransactionsTable(true);
        }
    }, [allTransactions]);

    async function getData() {
        setData({ loading: true, rows: null });
        setExceptionMsg(null);
        setShowPlayerTransactionsTable(false);
        let filterObj = filters.reduce(addFilterAsParameter, { startDate: startDate, endDate: endDate });
        let res = await GetReportTransactions(filterObj);
        updateNotes(filters);
        if (res.exceptionMsg) {
            setExceptionMsg(res.exceptionMsg);
            setData({ loading: false, rows: null });
        } else {
            setData({ loading: false, rows: res });
        }
    }

    function setFilter(filterIndex, type, value) {
        let newFilters = filters.slice();
        let filterToUpdate = newFilters.find(filter => filter.index === filterIndex)
        filterToUpdate[type] = value;
        setFilters(newFilters);
    }

    function formatDateForNotes(dateTime) {
        let month = dateTime.getMonth() + 1;
        let day = dateTime.getDate();
        let year = dateTime.getFullYear();

        return month + '/' + day + '/' + year;
    }

    function updateNotes(filters) {
        let notes = {
            formNotes: [],
        };
        notes.formNotes.push(`Transactions From: ${formatDateForNotes(startDate)} To: ${formatDateForNotes(endDate)}`);

        if (filters) {
            filters.forEach(filter => {
                if (filter.value) {
                    let filterNote = convertFilterToNote(filter);
                    if (filterNote)
                        notes.formNotes.push(filterNote);
                }
            });
        }

        setExcelNotes(notes);
    }

    function renderInjuriesTable() {
        if(!injuries || !injuries.length || !showPlayerInjuriesTable) return <></>;
        return (
            <div className='pdfChunk'>
                <Row>
                    <h3>Injury Details</h3>
                    <ExcelExporter
                        reportName={'Injuries'}
                        data={injuries}
                        columns={injuryCols}
                        style={{
                            height: '50px',
                            width: '50px',
                            border: '1px solid grey',
                            borderRadius: '5px',
                            margin: '10px'
                        }}
                    />
                </Row>
                <div style={{
                    marginBottom: '20px',
                    width: injuries
                        ? (injuryCols.reduce((acc, cur) => acc + ((cur.width) > 20
                            ? (cur.width)
                            : 20), 0)) + 4
                        : '100%'
                }}>
                    <TabulatorTable
                        cols={injuryCols}
                        data={injuries}
                        paginationSize={25}
                        initSort={[{ column: 'DateInjured', dir: 'desc' }]}
                        dataTreeElementColumn='DateInjured'
                    />
                </div>
            </div>
        );
    }

    return (
        <div className='body transactions-report pdfSection'>
            <div className='pdfChunk'>
                <h2>Transactions Viewer</h2>
                <br />
                <Row>
                    <b className='label' style={{ paddingRight: '25px' }}>Transaction Date From: </b>
                    <DatePicker
                        maxDate={endDate}
                        setDate={(date) => setStartDate(date)}
                        startDate={startDate}
                    />
                    <b classname='label' style={{ padding: '0px 25px' }}>Transaction Date To: </b>
                    <DatePicker
                        endDate={endDate}
                        maxDate={today}
                        minDate={startDate}
                        setDate={(date) => setEndDate(date)}
                    />
                </Row>
                <br />
                <h5>Advanced Filters</h5>
                <Row>
                    <Button variant='primary'
                        onClick={() => {
                            setFilters(filters.map(filter => {
                                if (filter.formInputs.formLabel === 'Players') {
                                    filter.value = new Set();
                                }
                                return filter;
                            }));
                        }}
                    >
                        All Players
                    </Button>
                    <Button variant='primary'
                        onClick={() => {
                            setStartDate(new Date(2002, 1, 1));
                            setEndDate(new Date());
                        }}
                    >
                        All Dates
                    </Button>
                </Row>
                <br />
                <div className='advanced-filter'>
                    {
                        filters.map((filter, filterIndex) =>
                            <StatFilter
                                filter={filter}
                                key={filterIndex}
                                people={allPlayers}
                                setFilter={setFilter}
                            />
                        )
                    }
                </div>
                <br />
                <Row className='pdfIgnore'>
                    <Button variant='success'
                        onClick={getData}
                    >
                        Submit
                    </Button>
                    {
                        data?.rows &&
                        <>
                            <div style={{ margin: '0px 0px 0px auto' }}>
                                <ExcelExporter
                                    columns={columns}
                                    data={data.rows}
                                    notes={excelNotes}
                                    reportName={'TransactionsReport'}
                                />
                                <PDFExporter fileName='TransactionsReport' />
                            </div>
                        </>
                    }
                </Row>
            </div>
            {
                data?.loading &&
                <div>
                    <br />
                    <br />
                    <i className='fa fa-spinner fa-spin loading-icon'></i>
                    <br />
                </div>
            }
            {
                exceptionMsg &&
                <React.Fragment>
                    <p style={{ color: 'rgb(220, 53, 69)', textAlign: 'center' }}>{exceptionMsg}</p>
                    <br />
                    <br />
                </React.Fragment>
            }
            {
                data?.rows && columns && !exceptionMsg &&
                <div
                    className='pdfChunk tableChunk'
                    style={{
                        width: (columns.reduce((acc, cur) => acc + ((cur.width) > 20 ? (cur.width) : 20), 0)) + 4
                    }}
                >
                    <TabulatorTable
                        cols={columns}
                        data={data.rows}
                        initSort={[{
                            column: columns?.find(col => col.title === 'Date').field,
                            dir: 'asc'
                        }]}
                        paginationSize={100}
                    />
                    <br />
                </div>
            }
            {
                showPlayerTransactionsTable && playerTransactionsData.transactionData !== undefined && playerTransactionsData.transactionData.length > 0 &&
                <div className='pdfChunk'>
                    <Row>
                        <h3>Transactions ({transactionsPlayer.playerName})</h3>
                        <ExcelExporter
                            reportName={'Transactions'}
                            data={playerTransactionsData.transactionData}
                            columns={playerTransactionCols}
                            style={{
                                height: '50px',
                                width: '50px',
                                border: '1px solid grey',
                                borderRadius: '5px',
                                margin: '10px'
                            }}
                        />
                    </Row>
                    <div
                        style={{
                            width: playerTransactionsData.transactionData
                                ? (playerTransactionCols.reduce((acc, cur) => acc + ((cur.width) > 20
                                    ? (cur.width)
                                    : 20), 0)) + 4
                                : '100%'
                        }}>
                        <TabulatorTable
                            cols={playerTransactionCols}
                            data={playerTransactionsData.transactionData}
                            dataTreeElementColumn='Season'
                            initSort={[{ column: 'Season', dir: 'desc' }]}
                        />
                    </div>
                    <br />
                </div>
            }
            { renderInjuriesTable() }
        </div>
    )
}

export default TransactionsReport;