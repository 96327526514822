import React from 'react';
import { Form } from 'react-bootstrap';

function IntRangeDropdown(props) {
    let options = [];

    if (props.sortOrder === 'desc') {
        for (let i = props.maxVal; i >= props.minVal; i--) {
            if (i === Number(props.selectedVal ?? props.defaultVal)) {
                options.push(
                    <option key={i} value={i} selected>{i}</option>
                )
            }
            else {
                options.push(
                    <option key={i} value={i}>{i}</option>
                )
            }
        }
    } else {
        for (let i = props.minVal; i <= props.maxVal; i++) {
            if (i === Number(props.selectedVal ?? props.defaultVal)) {
                options.push(
                    <option key={i} value={i} selected>{i}</option>
                )
            }
            else {
                options.push(
                    <option key={i} value={i}>{i}</option>
                )
            }
        }
    }

    return (
        <React.Fragment>
            {
                props.label &&
                <b className='label'>{props.label}:</b>
            }
            <Form.Control
                style={props.label == null ? { marginLeft: '10px', marginRight: '10px' } : null}
                className={props.className ? `${props.className} int-range-dropdown` : 'int-range-dropdown'}
                as='select'
                onChange={(event) => props.setSelectedVal(event.target.value)}>
                {options}
            </Form.Control>
        </React.Fragment>
    );
}

export default IntRangeDropdown;