import React from 'react';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import CompReportWrapper from './Components/CompReportWrapper';
import PlayerPage from './Components/PlayerPage/PlayerPage';
import Leaderboards from './Components/Leaderboards/Leaderboards';
import RankReport from './Components/RankReport/RankReport';
import QueryTool from './Components/QueryTool/QueryTool';
import LargestContracts from './Components/LargestContractsReport/LargestContracts';
import Awards from './Components/AwardsReport/Awards';
import RecentSignings from './Components/RecentSignings/RecentSignings';
import SplitsReport from './Components/Splits/SplitsReport';
import Blank from './Components/Blank';
import PrivateRoute from './Components/Common/PrivateRoute';
import { LoginPage } from './Components/LoginPage/LoginPage';
import ArbitrationReport from './Components/Arbitration/ArbitrationReport';
import PitcherSelector from './Components/ReliefPitcher/PitcherSelector';
import TransactionsReport from './Components/Transactions/TransactionsReport';

function Router() {
    return (
        <BrowserRouter>
            <div style={{ height: '100%' }}>
                <Route path='/:tab' component={Header} />
                <Route exact path='/'>
                    <Redirect to='/home' />
                </Route>
                <PrivateRoute path='/arbitration' component={ArbitrationReport} />
                <PrivateRoute path='/awardsreport' component={Awards} />
                <PrivateRoute path='/compreport' component={CompReportWrapper} />
                <PrivateRoute path='/home' component={Home} />
                <PrivateRoute path='/largestcontractsreport' component={LargestContracts} />
                <PrivateRoute path='/leaderboards' component={Leaderboards} />
                <PrivateRoute path='/player' component={PlayerPage} />
                <PrivateRoute path='/query' component={QueryTool} />
                <PrivateRoute path='/rankreport' component={RankReport} />
                <PrivateRoute path='/recentsignings' component={RecentSignings} />
                <PrivateRoute path='/reliefpitcher' component={PitcherSelector} />
                <PrivateRoute path='/splitsreport' component={SplitsReport} />
                <PrivateRoute path='/transactions' component={TransactionsReport} />
                <PrivateRoute path='/postseasonreport'>
                    <Leaderboards
                        isPostseason={true}
                    />
                </PrivateRoute>
                {/*
                <PrivateRoute path='/cbt' component={Blank} />
                <PrivateRoute path='/lrp' component={Blank} />
                */}
                <Route path='/login' component={LoginPage} />
            </div>
        </BrowserRouter>
    )
}

export default Router;