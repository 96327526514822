import React from 'react';
import { Form } from 'react-bootstrap';

function PlayingTimeFilter(props) {
    return (
        <div className='row filter' style={{ paddingBottom: '8.5px' }}>
            <div className='col-md-5'>
                <Form.Control as='select' onChange={(event) => props.setFilterCategory(event.target.value)}>
                    {props.filterCategories}
                </Form.Control>
            </div>
            <div className='col-md-2'>
                <Form.Control as='select' onChange={(event) => props.setFilterOperator(event.target.value)}>
                    <option selected={props.filterOperator === '>' ? 'selected' : false} key={1} value={'>'}>{'>'}</option>
                    <option selected={props.filterOperator === '>=' ? 'selected' : false} key={2} value={'>='}>{'>='}</option>
                    <option selected={props.filterOperator === '=' ? 'selected' : false} key={3} value={'='}>{'='}</option>
                    <option selected={props.filterOperator === '<=' ? 'selected' : false} key={4} value={'<='}>{'<='}</option>
                    <option selected={props.filterOperator === '<' ? 'selected' : false} key={5} value={'<'}>{'<'}</option>
                    <option selected={props.filterOperator === '!=' ? 'selected' : false} key={6} value={'!='}>{'!='}</option>
                </Form.Control>
            </div>
            <div className='col-md-5'>
                <Form.Control as='input' type='number' step='0.01' value={props.filterValue} onChange={(event) => props.setFilterValue(event.target.value)} />
            </div>
        </div>
    );
}

export default PlayingTimeFilter;
