import CloseButton from './CloseButton';
import { dragAndDropFunctions } from './Functions';

function DropBlock(props) {
    return (
        <div
            className={`dropZone ${props.id} ${props.className}`}
            id={props.id}
            onDragOver={(ev) => {
                ev.preventDefault();
                ev.dataTransfer.dropEffect = 'move';
            }}
            onDrop={dragAndDropFunctions.dropElement}
            draggable={true}
            onDragStart={(ev) => dragAndDropFunctions.startDrag(ev, props.className)}
            onDragEnd={(ev) => dragAndDropFunctions.endDrag(ev)}
        >
            {props.children}
            <CloseButton />
        </div>
    )
}

export default DropBlock;