import React from 'react';

function PlayerSplits(props) {
    let seasons = props.seasons;


    return(
        <React.Fragment>
            <h4>Splits</h4>
        </React.Fragment>
    );
}

export default PlayerSplits;